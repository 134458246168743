import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useCancelRequest() {
  const { pathname } = useLocation();
  // 中断请求
  useEffect(() => {
    return () => {
      global.cancelRequest &&
        global.cancelRequest(
          JSON.stringify({
            status_code: 306,
            message: '请求被客户端中断',
          })
        );
    };
  }, [pathname]);
}

export default useCancelRequest;
