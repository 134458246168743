import {
  getGoodsList,
  getMerchantBill,
  getMerchantDetail,
  getMerchantList,
  goodsScheduleDetail,
  postMerchantUpdate,
} from '@/api';
import { CardItem, ExportData, HeaderTitle, Operation, Thumbnails } from '@/components';
import { calcPrice, parseJSON, toDateTime } from '@/utils/utils';
import { st_bill, st_goods, st_shop } from '@/utils/statetype';
import { Button, Card, Input, message, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

const cardObj = {
  1: '身份证',
};
const { statusObj: goodsStatusObj } = st_goods;
const { typeObj, statusObj, levelObj } = st_shop;
const { typeObj: billTypeObj, kindObj } = st_bill;
const pageSize = 8;
function ShopListDetail({ querys, history }) {
  const { i: ID } = querys;
  const [datas, setDatas] = useState({});
  const [refuse, setRefuse] = useState('');
  const [goods, setGoods] = useState({});
  const [open, setOpen] = useState(false);
  const [merList, setMerList] = useState([]);
  const [bill, setBill] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [pageNos, setPageNos] = useState(1);
  const infoData = parseJSON(datas.info) || {};
  const Category1 = datas.Category1 || {};
  const Category2 = datas.Category2 || {};
  const RecommendMerchant = datas.RecommendMerchant || {};

  const handleGetData = () => {
    getMerchantDetail({
      query: {
        id: Number(ID),
      },
    }).then(res => {
      setDatas(res);
      setOpen(res.openRobot === 1);
    });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const handleSubmit = status => {
    if (status === 3 && !refuse) {
      message.warning('拒绝时请输入审核意见');
      return;
    }
    postMerchantUpdate({
      body: {
        id: Number(ID),
        ...(status === 3
          ? {
              refuseReason: refuse,
            }
          : {}),
        status,
      },
    }).then(() => {
      message.success('操作成功');
      handleGetData();
    });
  };

  const renderInfo = [
    {
      label: '店铺编号',
      render: datas.id,
    },
    {
      label: '店铺名称',
      render: datas.title,
    },
    {
      label: '店铺LOGO',
      render: <Thumbnails images={[datas.shopIcon]} />,
    },
    {
      label: '店铺保证金',
      render: datas.earnestPay === 1 ? `${calcPrice(datas.earnestMoney)}(已缴纳)` : '未缴纳',
    },
    {
      label: '店铺余额',
      render: calcPrice(datas.balance),
    },
    {
      label: '总收益',
      render: calcPrice(datas.baseAllIncome),
    },
    {
      label: '店铺分类',
      render: `${Category1.name ?? ''}${Category2.name ? `>${Category2.name}` : ''}`,
    },
    {
      label: '机器人出价',
      render: (
        <Switch
          checked={open}
          disabled={!global.isOperable}
          checkedChildren="开启"
          unCheckedChildren="关闭"
          onChange={e => {
            postMerchantUpdate({
              body: {
                id: datas.id,
                openRobot: e ? 1 : 0,
              },
            }).then(() => {
              handleGetData();
              message.success(e ? '开启成功' : '关闭成功');
            });
          }}
        />
      ),
    },
    {
      label: '上级店铺编号',
      render: datas.recommendMerchantId,
    },
    {
      label: '上级店铺名称',
      render: RecommendMerchant.title,
    },
  ];

  const renderFirm = [
    {
      label: '企业名称',
      render: datas.firmName,
    },
    {
      label: '企业类型',
      render: typeObj[datas.type],
    },
    {
      label: '企业身份',
      render: levelObj[datas.shopLevel],
    },
    {
      label: '社会信用代码',
      render: datas.creditCode,
    },
    {
      label: '法人姓名',
      render: datas.name,
    },
    {
      label: '身份证号码',
      render: datas.cardId,
    },
    {
      label: '联系方式',
      render: datas.contact,
    },
    {
      label: '联系地址',
      render: datas.address,
    },
    {
      label: '营业执照',
      render: <Thumbnails images={[infoData.business]} />,
    },
    {
      label: '执照到期时间',
      render: datas.islong === 1 ? '长期' : infoData.businessAt,
    },
    {
      label: '证件类型',
      render: cardObj[infoData.cardType],
    },
    {
      label: '证件正面照',
      render: <Thumbnails images={[infoData.certFront]} />,
    },
    {
      label: '证件反面照',
      render: <Thumbnails images={[infoData.certReverse]} />,
    },
  ];

  const renderStatus = [
    {
      label: '店铺状态',
      render: statusObj[datas.status],
    },
    {
      label: '审核意见',
      render:
        datas.status === 1 ? (
          <Input.TextArea
            placeholder="请输入审核意见（拒绝理由）"
            onChange={e => setRefuse(e.target.value)}
          />
        ) : (
          datas.refuseReason
        ),
    },
    {
      render: datas.status === 1 && (
        <>
          <Button type="primary" style={{ marginRight: '16px' }} onClick={() => handleSubmit(2)}>
            同意
          </Button>
          <Button type="primary" danger onClick={() => handleSubmit(3)}>
            拒绝
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    getGoodsList({
      query: {
        merchantId: ID,
        pageNo,
        pageSize,
      },
    }).then(res => {
      setGoods(res);
    });
  }, [pageNo]);

  const renderGoods = {
    dataSource: goods.data,
    loading: !goods.data,
    pagination: {
      pageSize,
      size: 'small',
      total: goods.count,
      showTotal: e => `共${e}条`,
      onChange: e => setPageNo(e),
    },
    columns: [
      {
        title: '拍品编号',
        dataIndex: 'id',
      },
      {
        title: '拍品名称',
        dataIndex: 'name',
      },
      {
        title: '封面图',
        dataIndex: 'thumb',
        render: e => <Thumbnails images={[e]} center />,
      },
      {
        title: '拍品状态',
        dataIndex: 'status',
        render: e => goodsStatusObj[e] || '-',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
      {
        title: '操作',
        render: e => (
          <Operation
            listData={{
              select: () => history.push(`/goods/list/detail?i=${e.id}`),
            }}
          />
        ),
      },
    ],
  };

  useEffect(() => {
    getMerchantList({
      query: {
        recommendMerchantId: ID,
        pageNo: 1,
        pageSize: 500,
      },
    }).then(res => {
      setMerList(res.data);
    });
  }, []);

  const renderRecommend = {
    dataSource: merList,
    columns: [
      {
        title: '店铺编号',
        dataIndex: 'id',
      },
      {
        title: '店铺名称',
        dataIndex: 'title',
      },
      {
        title: '店铺LOGO',
        dataIndex: 'shopIcon',
        render: e => <Thumbnails images={[e]} center />,
      },
      {
        title: '店铺类型',
        dataIndex: 'type',
        render: e => typeObj[e] || '-',
      },
      {
        title: '店铺级别',
        dataIndex: 'shopLevel',
        render: e => levelObj[e] || '-',
      },
      {
        title: '注册时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  useEffect(() => {
    getMerchantBill({
      query: {
        merchantId: ID,
        pageNo: pageNos,
        pageSize,
      },
    }).then(res => {
      setBill(res);
    });
  }, [pageNos]);

  const sourceFn = {
    goodsSchedule: async ({ sourceId }) => {
      const res = await goodsScheduleDetail({ query: { goodsScheduleId: sourceId } });
      history.push(`/goods/list/detail?i=${res.goodsId}`);
    },
    userInvite: ({ sourceId }) => {
      history.push(`/user/list/detail?i=${sourceId}`);
    },
    auctionHouse: ({ sourceId }) => {
      history.push(`/goods/beat/detail?i=${sourceId}`);
    },
    userBill: () => {
      history.push(`/user/list/detail?i=${datas.userId}`);
    },
    userOrderDetail: ({ sourceId }) => {
      history.push(`/order/list/detail?i=${sourceId}`);
    },
    userDeliveryDetail: () => {
      history.push(`/user/list/detail?i=${datas.userId}`);
    },
    merchantGoods: ({ sourceId }) => {
      history.push(`/goods/list/detail?i=${sourceId}`);
    },
  };

  const renderBill = {
    dataSource: bill.data,
    loading: !bill.data,
    pagination: {
      pageSize,
      size: 'small',
      total: bill.count,
      showTotal: e => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ExportData
            title="商铺账单明细"
            btnProp={{ size: 'small' }}
            API={getMerchantBill}
            query={{ merchantId: ID }}
            extend={{
              type: billTypeObj,
              kind: kindObj,
              amount: 'amount',
              createdAt: 'time',
            }}
            columns={{
              id: '明细编号',
              number: '收益单号',
              'Merchant.id': '店铺编号',
              'Merchant.title': '店铺名称',
              type: '收益类型',
              kind: '收益分类',
              amount: '总收益',
              sourceId: '来源编号',
              source: '收益来源',
              describe: '备注信息',
              createdAt: '创建时间',
            }}
          />
          <span style={{ marginLeft: '24px' }}>共{e}条</span>
        </div>
      ),
      onChange: e => setPageNos(e),
    },
    columns: [
      {
        title: '明细编号',
        dataIndex: 'id',
      },
      {
        title: '收益单号',
        dataIndex: 'number',
      },
      {
        title: '收益类型',
        dataIndex: 'type',
        render: e => billTypeObj[e],
      },
      {
        title: '收益分类',
        dataIndex: 'kind',
        render: e => kindObj[e],
      },
      {
        title: '总收益',
        dataIndex: 'amount',
        render: e => calcPrice(e),
      },
      {
        title: '来源编号',
        dataIndex: 'sourceId',
      },
      {
        title: '收益来源',
        dataIndex: 'source',
        render: (e, env) =>
          !!sourceFn[e] ? (
            <Button type="link" style={{ padding: '0' }} onClick={() => sourceFn[e](env)}>
              {e}
            </Button>
          ) : (
            e
          ),
      },
      {
        title: '备注信息',
        dataIndex: 'describe',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  return (
    <>
      <HeaderTitle />
      <Card>
        <CardItem title={'店铺信息'} datas={renderInfo} />
        <CardItem title={'企业信息'} datas={renderFirm} />
        <CardItem title={'审核信息'} datas={renderStatus} />
        <CardItem title={'拍品列表'} datas={renderGoods} />
        <CardItem title={'收益明细'} datas={renderBill} />
        <CardItem title={'发展下级'} datas={renderRecommend} />
      </Card>
    </>
  );
}

export default ShopListDetail;
