import { useEffect, useRef } from 'react';

/**
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
function useUpdateEffect(effect, dependencies = []) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect()
    }
  }, dependencies);
}

export default useUpdateEffect;
