import { getGoodsDetail, getOfferRecord, goodsScheduleUpdate, postGoodsUpdate } from '@/api';
import { CardItem, ExportData, HeaderTitle, Operation, Thumbnails } from '@/components';
import { calcPrice, parseJSON, toDateTime, toSpliceStr } from '@/utils/utils';
import { Button, Card, DatePicker, Image, Input, message, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { st_goods, st_user } from '@/utils/statetype';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { statusObj, typeObj, kindObj } = st_goods;
const { statusObj: statusObjs } = st_user;
const pageSize = 8;
function GoodsListDetail({ querys }) {
  const { i: ID } = querys;
  let times = useRef(null);
  const [datas, setDatas] = useState({});
  const [refuse, setRefuse] = useState('');
  const [record, setRecord] = useState({});
  const [preview, setPreview] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const info = parseJSON(datas.info) || {};
  const property = parseJSON(datas.property);
  const { Merchant = {}, GoodsSchedules = [] } = datas;

  const handleGetData = () => {
    getGoodsDetail({
      query: {
        id: Number(ID),
      },
    }).then(res => {
      setDatas(res);
    });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const handleSubmit = status => {
    if (status === -1 && !refuse) {
      message.warning('拒绝时请输入审核意见');
      return;
    }
    postGoodsUpdate({
      body: {
        id: Number(ID),
        ...(status === -10
          ? {
              refuseReason: refuse,
            }
          : {}),
        status,
      },
    }).then(() => {
      message.success('操作成功');
      handleGetData();
    });
  };

  const renderInfo = [
    {
      label: '拍品编号',
      render: datas.id,
    },
    {
      label: '拍品名称',
      render: datas.name,
    },
    {
      label: '封面图',
      render: <Thumbnails images={[datas.thumb]} />,
    },
    {
      label: '服务标签',
      render: (datas.Labels || []).map(i => i.name).join('|'),
    },
    {
      label: '拍品分类',
      render: toSpliceStr(property, '>'),
    },
    {
      label: '拍品类型',
      render: typeObj[datas.type],
    },
    {
      label: '拍品类别',
      render: kindObj[datas.kind],
    },
    {
      label: '拍品描述',
      render: info.describe,
    },
    {
      label: '详情图',
      render: <Thumbnails images={(datas.image ?? '').split(',')} />,
    },
    {
      label: '鉴定证书',
      render: <Thumbnails images={(datas.credNote ?? '').split(',')} />,
    },
    {
      label: '拍品视频',
      render: datas.videoThumb ? (
        <Image
          width={64}
          height={64}
          src={datas.videoThumb}
          style={{ objectFit: 'cover' }}
          preview={{
            visible: false,
            onVisibleChange: e => {
              if (e && !datas.video) {
                message.warning('没有视频资源！');
                return;
              }
              setPreview(e);
            },
          }}
        />
      ) : (
        '-'
      ),
    },
    {
      label: '转拍次数',
      render: datas.resellCount,
    },
    {
      label: '创建时间',
      render: toDateTime(datas.createdAt),
    },
  ];

  const renderStatus = [
    {
      label: '拍品状态',
      render: statusObj[datas.status],
    },
    {
      label: '审核意见',
      render:
        datas.status === 0 ? (
          <Input.TextArea
            placeholder="请输入审核意见（拒绝理由）"
            onChange={e => setRefuse(e.target.value)}
          />
        ) : (
          datas.refuseReason
        ),
    },
    {
      render: datas.status === 0 && (
        <>
          <Button type="primary" style={{ marginRight: '16px' }} onClick={() => handleSubmit(20)}>
            同意
          </Button>
          <Button type="primary" danger onClick={() => handleSubmit(-10)}>
            拒绝
          </Button>
        </>
      ),
    },
  ];

  const renderShop = [
    {
      label: '店铺名称',
      render: Merchant.title,
    },
    {
      label: '店铺LOGO',
      render: <Thumbnails images={[Merchant.shopIcon]} />,
    },
    {
      label: '联系方式',
      render: Merchant.contact,
    },
    {
      label: '店铺地址',
      render: Merchant.address,
    },
  ];

  useEffect(() => {
    getOfferRecord({
      query: {
        goodsId: ID,
        pageNo,
        pageSize,
      },
    }).then(res => {
      setRecord(res);
    });
  }, [pageNo]);

  const renderRecord = {
    dataSource: record.data,
    loading: !record.data,
    pagination: {
      pageSize,
      size: 'small',
      total: record.count,
      showTotal: e => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ExportData
            title="拍品竞拍记录"
            btnProp={{ size: 'small' }}
            API={getOfferRecord}
            query={{ goodsId: ID }}
            extend={{
              'User.isRobot': {
                1: '机器人',
                0: '真实用户',
              },
              'Goods.kind': kindObj,
              'GoodsSchedule.type': typeObj,
              offer: 'amount',
              status: statusObjs,
              createdAt: 'time',
            }}
            columns={{
              id: '记录编号',
              'User.id': '用户编号',
              'User.avatarUrl': '用户头像',
              'User.nickname': '用户名称',
              'User.isRobot': '用户类型',
              'Goods.id': '拍品编号',
              'Goods.name': '拍品名称',
              'Goods.kind': '拍品类别',
              'GoodsSchedule.id': '拍品排期编号',
              'GoodsSchedule.type': '拍品排期类型',
              offer: '出价价格',
              status: '出价状态',
              createdAt: '出价时间',
            }}
          />
          <span style={{ marginLeft: '24px' }}>共{e}条</span>
        </div>
      ),
      onChange: e => setPageNo(e),
    },
    columns: [
      {
        title: '记录编号',
        dataIndex: 'id',
      },
      {
        title: '用户编号',
        dataIndex: 'User',
        render: (e = {}) => e?.id,
      },
      {
        title: '用户头像',
        dataIndex: 'User',
        render: (e = {}) => <Thumbnails images={[e?.avatarUrl]} center />,
      },
      {
        title: '用户名称',
        dataIndex: 'User',
        render: (e = {}) => e?.nickname,
      },
      {
        title: '用户类型',
        dataIndex: 'User',
        render: e => (e?.isRobot === 1 ? '机器人' : '真实用户'),
      },
      {
        title: '拍品编号',
        dataIndex: 'Goods',
        render: (e = {}) => e.id,
      },
      {
        title: '拍品名称',
        dataIndex: 'Goods',
        render: (e = {}) => e.name,
      },
      {
        title: '拍品类别',
        dataIndex: 'Goods',
        render: (e = {}) => kindObj[e.kind],
      },
      {
        title: '拍品排期编号',
        dataIndex: 'GoodsSchedule',
        render: (e = {}) => e.id,
      },
      {
        title: '拍品排期类型',
        dataIndex: 'GoodsSchedule',
        render: (e = {}) => typeObj[e.type],
      },
      {
        title: '出价价格',
        dataIndex: 'offer',
        render: e => calcPrice(e),
      },
      {
        title: '出价状态',
        dataIndex: 'status',
        render: e => statusObjs[e],
      },
      {
        title: '出价时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  const renderSchedules = {
    dataSource: GoodsSchedules,
    columns: [
      {
        title: '编号',
        dataIndex: 'id',
      },
      {
        title: '类型',
        dataIndex: 'type',
        render: e => typeObj[e],
      },
      {
        title: '转拍人编号',
        dataIndex: 'resellUserId',
      },
      {
        title: '转拍订单编号',
        dataIndex: 'resellOrderId',
      },
      {
        title: '成交订单编号',
        dataIndex: 'orderId',
      },
      {
        title: '加价幅度',
        dataIndex: 'addPriceRange',
        render: e => calcPrice(e),
      },
      {
        title: '保证金比例',
        dataIndex: 'bondPro',
      },
      {
        title: '起始价',
        dataIndex: 'startPrice',
        render: e => calcPrice(e),
      },
      {
        title: '当前价',
        dataIndex: 'currentPrice',
        render: e => calcPrice(e),
      },
      {
        title: '认购价',
        dataIndex: 'subscriptionPrice',
        render: e => calcPrice(e),
      },
      {
        title: '最终价',
        dataIndex: 'finalPrice',
        render: e => calcPrice(e),
      },
      {
        title: '标品数量',
        dataIndex: 'num',
      },
      {
        title: '标品单位',
        dataIndex: 'unit',
      },
      {
        title: '竞拍次数',
        dataIndex: 'offerNums',
      },
      {
        title: '竞拍人数',
        dataIndex: 'userOfferNums',
      },
      {
        title: '开始时间',
        dataIndex: 'startTime',
        render: e => toDateTime(e),
      },
      {
        title: '结束时间',
        dataIndex: 'endTime',
        render: e => toDateTime(e),
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
      {
        title: '操作',
        render: record => (
          <Operation
            disabled={{
              update: ![20, 100].includes(record.status),
            }}
            listData={{
              update: () => {
                Modal.confirm({
                  icon: null,
                  title: '更改排期时间',
                  content: (
                    <DatePicker.RangePicker
                      showTime
                      locale={locale}
                      onChange={e => (times.current = e)}
                    />
                  ),
                  onOk: () => handleUpdateTime(record.id),
                });
              },
            }}
          />
        ),
      },
    ],
  };

  const handleUpdateTime = id => {
    if (!times || !times.current) {
      return message.warning('排期时间未输入');
    }
    const [s, e] = times.current;
    goodsScheduleUpdate({
      body: {
        id,
        startTime: toDateTime(s),
        endTime: toDateTime(e),
      },
    }).then(() => {
      message.success('修改成功');
      handleGetData();
    });
  };

  const renderLogs = {
    dataSource: datas.GoodsLogs,
    columns: [
      {
        title: '编号',
        dataIndex: 'id',
      },
      {
        title: '备注信息',
        dataIndex: 'remark',
      },
      {
        title: '最近修改',
        dataIndex: 'updatedAt',
        render: e => toDateTime(e),
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  return (
    <>
      <HeaderTitle />
      <Card>
        <CardItem title={'基本信息'} datas={renderInfo} />
        <CardItem title={'所属店铺'} datas={renderShop} />
        <CardItem title={'审核信息'} datas={renderStatus} />
        {!!GoodsSchedules.length && <CardItem title={'拍品排期'} datas={renderSchedules} />}
        <CardItem title={'竞拍记录'} datas={renderRecord} />
        <CardItem title={'操作日志'} datas={renderLogs} />
      </Card>
      <Modal
        width={960}
        footer={null}
        destroyOnClose
        closable={false}
        visible={preview}
        bodyStyle={{ padding: '0', backgroundColor: '#000', display: 'flex' }}
        onCancel={() => setPreview(false)}
      >
        <video
          muted
          controls
          autoPlay
          width={960}
          height={660}
          autobuffer="true"
          style={{ width: '100%', height: '100%', maxHeight: '660px', background: '#000' }}
          src={datas.video}
        />
      </Modal>
    </>
  );
}

export default GoodsListDetail;
