import { Card, Row, Space } from 'antd';
import React from 'react';

const spanSty = { color: '#999', whiteSpace: 'nowrap' };
const h2Sty = {
  marginTop: '-8px',
  fontSize: '28px',
};
function OverItem({ width = '33.3%', datas = [], onClick }) {
  return (
    <Card style={{ padding: '8px', marginBottom: '4px' }}>
      {datas.map((i, k) => (
        <Card.Grid
          key={k}
          hoverable={false}
          style={{ width, cursor: onClick ? 'pointer' : null }}
          onClick={() => onClick && onClick(k)}
        >
          <Row justify="center" align="bottom">
            <Space>
              <span style={spanSty}>{i[0] ?? ''}</span>
              <h2 style={h2Sty}>{i[1] || 0}</h2>
            </Space>
          </Row>
        </Card.Grid>
      ))}
    </Card>
  );
}

export default OverItem;
