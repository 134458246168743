import { HeaderTitle, Thumbnails } from '@/components';
import { calcPrice, toDateTime } from '@/utils/utils';
import { relationUserBill } from '@/api';
import { useTable } from '@/hooks';
import { Table } from 'antd';
import React from 'react';

function FinanceProfitDetail({ querys }) {
  const { i } = querys;

  const columns = {
    NOINDEX_id: {
      title: '商品编号',
      render: e => {
        const GoodsSchedule = e.GoodsSchedule || {};
        return GoodsSchedule.goodsId || '-';
      },
    },
    NOINDEX_ids: {
      title: '商品排期编号',
      render: e => {
        const GoodsSchedule = e.GoodsSchedule || {};
        return GoodsSchedule.id || '-';
      },
    },
    NOINDEX_rId: {
      title: '关联用户编号',
      render: e => {
        const RelativeUser = e.RelativeUser || {};
        return RelativeUser.id || '-';
      },
    },
    NOINDEX_rName: {
      title: '关联用户昵称',
      render: e => {
        const RelativeUser = e.RelativeUser || {};
        return RelativeUser.nickname || '-';
      },
    },
    NOINDEX_rImg: {
      title: '关联用户头像',
      render: e => {
        const RelativeUser = e.RelativeUser || {};
        return <Thumbnails images={[RelativeUser.avatarUrl]} center />
      },
    },
    describe: '备注信息',
    amount: {
      title: '竞拍差价',
      render: e => calcPrice(e),
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
  };

  const { tableProps } = useTable({
    columns,
    API: relationUserBill,
    params: {
      query: {
        userId: i,
      },
    },
  });

  return (
    <>
      <HeaderTitle />
      <Table {...tableProps} />
    </>
  );
}

export default FinanceProfitDetail;
