import React, { useState, useEffect } from 'react';
import { useUpdateEffect } from '@/hooks';
import { IconFont } from '@/components';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import styles from './styles.module.scss';

function ImageLoad({ src, size, icon, shape, buffer, children, className }) {
  const [load, setLoad] = useState(buffer);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (src && !load) {
      setLoad(true);
      setError(false);
    } else if (!src) {
      setError(true);
    }
  }, [src]);

  useUpdateEffect(() => {
    if (!buffer && load) {
      setLoad(false);
      setError(true);
    }
  }, [buffer])

  const renderLoad = () => {
    return (
      <img
        alt=""
        src={String(src)}
        onError={() => {
          if (!buffer) {
            setLoad(false);
            setError(true);
          }
        }}
        className={styles.loadingImg}
        onLoad={() => {
          setLoad(false);
          setError(false);
        }}
      />
    );
  };

  return (
    <>
      {(error || load) && (
        <div className={className || styles.container}>
          <Avatar
            size={size}
            shape={shape}
            icon={<IconFont type={load ? 'loading' : icon} />}
          />
        </div>
      )}
      <div className={error || load ? styles.none : null}>
        {children}
      </div>
      {load && renderLoad()}
    </>
  );
}

ImageLoad.defaultProps = {
  src: '',
  className: null,
};

ImageLoad.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
};

export default ImageLoad;
