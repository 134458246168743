import { FormList, HeaderTitle, Operation, Thumbnails } from '@/components';
import { Button, Col, Input, InputNumber, message, Modal, Row, Table } from 'antd';
import { getUserList, postRobotCreate, postRobotOffer } from '@/api';
import { st_user } from '@/utils/statetype';
import { calcPrice, toDateTime } from '@/utils/utils';
import React, { useEffect, useState } from 'react';
import { useTable, useUpdateEffect } from '@/hooks';
import UserEdit from '../UserList/components/UserEdit';

const { levelObj } = st_user;
function UserRobot() {
  const [gId, setGId] = useState(null);
  const [uId, setUId] = useState(null);
  const [nums, setNums] = useState(0);
  const [datas, setDatas] = useState({});
  const [query, setQuery] = useState({});
  const [visible, setVisible] = useState(false);

  const columns = {
    id: '机器人编号',
    nickname: '机器人昵称',
    avatarUrl: {
      title: '机器人头像',
      render: e => <Thumbnails images={[e]} center />,
    },
    phone: '手机号',
    balance: {
      title: '余额',
      render: e => calcPrice(e),
    },
    frozenAmount: {
      title: '冻结数额',
      render: e => calcPrice(e),
    },
    subscriptionAmount: '订阅数量',
    level: {
      title: '机器人身份',
      render: e => levelObj[e],
    },
    uniqueCode: '邀请码',
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          listData={{
            offer: () => setUId(e.id),
            update: () => setDatas(e),
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getUserList,
    isInit: false,
    params: {
      query: {
        ...query,
        isRobot: 1,
      },
    },
  });

  const formData = [
    {
      id: 'nickname',
      label: '机器人昵称',
    },
    {
      id: 'createdAt',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { createdAt, ...props } = value;
    const [start, end] = createdAt || [];
    const q = {
      ...props,
      ...(createdAt && {
        createStartTime: toDateTime(start),
        createEndTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList
        formData={formData}
        changeQuery={changeQuery}
        extraRender={
          <Button type="primary" disabled={!global.isOperable} onClick={() => setVisible(true)}>
            添加
          </Button>
        }
      />
      <Table {...tableProps} />
      <Modal
        visible={visible}
        title="添加机器人"
        onCancel={() => {
          setNums(0);
          setVisible(false);
        }}
        onOk={() => {
          if (!nums) {
            return message.warning('请输入需要添加的机器人数量');
          }
          postRobotCreate({
            body: {
              nums,
            },
          }).then(() => {
            message.success('添加成功');
            flagFn();
            setNums(0);
            setVisible(false);
          });
        }}
      >
        <Row align="middle" justify="center">
          <Col> 机器人数量：</Col>
          <InputNumber
            style={{ width: '260px', margin: '0 20px 0 10px' }}
            placeholder="添加的机器人数量(一次性最多五十)"
            min={1}
            max={50}
            precision={0}
            onChange={e => setNums(e)}
          />
        </Row>
      </Modal>
      <Modal
        visible={!!uId}
        title="机器人出价"
        onCancel={() => {
          setGId(null);
          setUId(false);
        }}
        onOk={() => {
          if (!gId) {
            return message.warning('请输入拍品编号');
          }
          postRobotOffer({
            body: {
              goodsScheduleId: Number(gId),
              userId: Number(uId),
            },
          }).then(() => {
            message.success('出价成功');
            flagFn();
            setGId(null);
            setUId(false);
          });
        }}
      >
        <Row align="middle" justify="center">
          <Col>拍品排期编号：</Col>
          <Input
            style={{ width: '220px', margin: '0 20px 0 10px' }}
            placeholder="请输入拍品排期编号"
            onChange={e => setGId(e.target.value)}
          />
        </Row>
      </Modal>
      <UserEdit
        datas={datas}
        onChange={res => {
          setDatas({});
          if (res) flagFn();
        }}
      />
    </>
  );
}

export default UserRobot;
