import { getUserLevel } from '@/api';
import React, { useState, useEffect } from 'react';
import { HeaderTitle } from '@/components';
import { Table } from 'antd';

function UserLevel() {
  const [load, setLoad] = useState(true);
  const [lists, setLists] = useState([]);
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: '身份',
      dataIndex: 'name',
      align: 'center',
    },
  ];

  useEffect(() => {
    getUserLevel().then(res => {
      setLists(res);
      setLoad(false);
    });
  }, []);

  return (
    <>
      <HeaderTitle />
      <Table
        bordered
        size="small"
        loading={load}
        rowKey={row => row.id}
        columns={columns}
        dataSource={lists}
        pagination={false}
      />
    </>
  );
}

export default UserLevel;
