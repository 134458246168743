import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import styles from './index.module.scss';

function Inputbox({
  type,
  icon,
  field,
  suffix,
  regular,
  maxLength,
  placeholder,
  value: defalutValue,
  onChange,
}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!value && defalutValue) {
      setValue(defalutValue);
    }
  }, [defalutValue]);

  const handleChange = event => {
    let resVal = event.target.value;
    if (regular) {
      resVal = resVal.replace(regular, '');
    }
    setValue(resVal);
    if (!onChange) return;
    if (field) onChange(field, resVal);
    else onChange(resVal);
  };

  const renderParam = () => {
    return {
      value,
      maxLength,
      placeholder,
      onChange: handleChange,
    };
  };

  return (
    <div className={styles.inputInfo}>
      {icon && <img src={icon} alt="" />}
      <div className={styles.inputBox}>
        {type === 'password' ? (
          <Input.Password {...renderParam()} />
        ) : (
          <Input {...renderParam()} suffix={suffix} />
        )}
      </div>
    </div>
  );
}

export default Inputbox;
