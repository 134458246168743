import React, { useState, useEffect } from 'react';
import { getItems, delItems, setItems } from '@/utils/storage';
import { useUpdateEffect } from '@/hooks';
import SearchItem from './SearchItem';
import FormItem from './FormItem';

const SEARCH = 'form-search';

function FormList({ formData, keepData, searchList, extraRender, changeQuery }) {
  const [call, setCall] = useState(false);
  const [initValue, setInitValue] = useState({});
  const [searchInit, setSearchInit] = useState({});
  const [searchFlag, setSearchFlag] = useState(true);
  const [searchValue, setSearchValue] = useState({
    search: {},
    option: {},
  });

  const handleList = ['$upper', '$lower', '$num', '$array'];

  const handleData = (label, value) => {
    switch (label) {
      case '$upper':
        return value.toString().toUpperCase();
      case '$lower':
        return value.toString().toLowerCase();
      case '$num':
        return Number(value);
      case '$array':
        return [value];
      default: {
        const result = {};
        const list = Array.isArray(value) ? value : [value];
        const field = label.split(',').filter(i => i);
        field.forEach((i, k) => {
          const fieldValue = list[k] || '';
          const flag = String(new Date(fieldValue)) !== 'Invalid Date';
          result[i] = flag ? new Date(fieldValue) : null;
        });
        return result;
      }
    }
  };

  const handleParam = datas => {
    const result = {};
    formData.forEach(i => {
      if (datas[i.id]) {
        if (typeof i.handle === 'object') {
          Object.keys(i.handle).forEach((item, key) => {
            if (handleList.some(j => j === i.handle[item])) {
              const val = Array.isArray(datas[i.id]) ? datas[i.id][key] : datas[i.id];
              result[item] = handleData(i.handle[item], val);
            }
          });
        } else if (handleList.some(j => j === i.handle)) {
          result[i.id] = handleData(i.handle, datas[i.id]);
        } else result[i.id] = datas[i.id];
      }
    });
    return result;
  };

  const getSearchInit = () => {
    const { listItem } = searchList || {};
    const result = {};
    listItem.forEach(i => {
      if (i.id && i.initValue) {
        result[i.id] = i.initValue;
      }
    });
    return result;
  };

  useEffect(() => {
    if (!formData) return;
    const { pathname } = window.location;
    const { uri, search, option } = getItems(SEARCH);
    const flag = pathname.toLocaleLowerCase() === uri;
    if (keepData && flag) {
      if (search) {
        setInitValue(search);
        setSearchValue({
          search: search,
          option: {},
        });
      }
      if (searchList) {
        const result = option || getSearchInit();
        setSearchInit(result);
      }
    } else {
      if (searchList) {
        setSearchInit(getSearchInit());
      } else setCall(true);
    }
  }, []);

  useUpdateEffect(() => {
    if (searchList && !Object.keys(searchInit).length && searchFlag) {
      const { pathname } = window.location;
      const { uri, option } = getItems(SEARCH);
      const flag = pathname.toLocaleLowerCase() === uri && keepData;
      if (flag && option) {
        setSearchInit(option);
      } else {
        setSearchInit(getSearchInit());
      }
      setSearchFlag(false);
    }
  }, [searchList]);

  // 触发回调
  useUpdateEffect(() => {
    if (call) {
      setCall(false);
      const { search = {}, option = {} } = searchValue;
      const result = handleParam(search);
      changeQuery(result, option);
      if (keepData) {
        const { pathname } = window.location;
        const uri = pathname.toLocaleLowerCase();
        setItems(SEARCH, { uri, search, option });
      }
    }
  }, [call]);

  const delNullData = data => {
    let result = {};
    Object.keys(data).forEach(i => {
      if (!data[i] && typeof data[i] !== 'number') return;
      if (Array.isArray(data[i]) && !data[i].join('')) return;
      result[i] = data[i];
    });
    return result;
  };

  const resetData = () => {
    setInitValue({});
    setSearchValue({
      search: {},
      option: {},
    });
    if (searchList) {
      setSearchInit(getSearchInit());
    } else {
      setCall(true);
    }
    delItems(SEARCH);
  };

  return (
    <div
      style={{
        padding: '6px 4px',
        boxSizing: 'border-box',
        marginBottom: '8px',
      }}
    >
      {formData && (
        <FormItem
          formData={formData}
          initValue={initValue}
          extraRender={extraRender}
          resetData={resetData}
          searchData={value => {
            const result = delNullData(value);
            setSearchValue(his => ({
              ...his,
              search: result,
            }));
            setCall(true);
          }}
        />
      )}
      {searchList && (
        <SearchItem
          {...searchList}
          initValue={searchInit}
          changeQuery={value => {
            setSearchValue(his => ({
              ...his,
              option: value,
            }));
            setCall(true);
          }}
        />
      )}
    </div>
  );
}

export default FormList;
