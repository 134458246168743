import { getSystemGetall, updateSystem } from '@/api';
import { FormModal, HeaderTitle, Operation } from '@/components';
import { notEmptyObj, toDateTime } from '@/utils/utils';
import { Input, message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import EditionEdit from './components/EditionEdit';

function SystemConfig() {
  const [flag, setFlag] = useState(false);
  const [lists, setLists] = useState([]);
  const [datas, setDatas] = useState({});

  const columns = [
    {
      width: 50,
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },
    {
      width: 100,
      title: '名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      width: 150,
      title: '数值',
      dataIndex: 'val',
      align: 'center',
      ellipsis: true,
    },
    {
      width: 150,
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      ellipsis: true,
      render: e => e || '-',
    },
    {
      width: 100,
      title: '最近修改',
      dataIndex: 'updatedAt',
      align: 'center',
      render: e => toDateTime(e),
    },
    {
      width: 100,
      title: '创建时间',
      dataIndex: 'createdAt',
      align: 'center',
      render: e => toDateTime(e),
    },
    {
      width: 50,
      title: '操作',
      align: 'center',
      render: e => (
        <Operation
          listData={{
            update: () => setDatas(e),
          }}
        />
      ),
    },
  ];

  const formData = [
    {
      name: 'val',
      label: '配置数值',
      rules: [{ required: true, message: '请输入配置数值' }],
      render: <Input placeholder="请输入配置数值" />,
    },
  ];

  const handleSubmit = params => {
    if (!params) {
      setDatas({});
      return;
    }
    updateSystem({
      body: {
        name: datas.name,
        ...params,
      },
    }).then(() => {
      message.success('修改成功');
      setDatas({});
      setFlag(e => !e);
    });
  };

  useEffect(() => {
    getSystemGetall().then(res => {
      setLists(res);
      global.systemConfig = res;
    });
  }, [flag]);

  return (
    <>
      <HeaderTitle />
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={lists}
        pagination={false}
        rowKey={row => row.id}
      />
      <FormModal
        title="系统配置"
        visible={!!notEmptyObj(datas) && datas.name !== 'appVersion'}
        formData={formData}
        initialValues={datas}
        onChange={handleSubmit}
      />
      <EditionEdit
        data={datas}
        visible={!!notEmptyObj(datas) && datas.name === 'appVersion'}
        onChange={res => {
          if (res) setFlag(e => !e);
          setDatas({});
        }}
      />
    </>
  );
}

export default SystemConfig;
