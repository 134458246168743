// 配置文件

// 测试环境
const testConfig = {
  API_URL: 'https://api-test.qlpaimai.com',
  VISIT_URL: 'https://testadmin.qlpaimai.com',
};

// 正式环境
const proConfig = {
  API_URL: 'https://api-prod.qlpaimai.com',
  VISIT_URL: 'https://admin.qlpaimai.com',
};

const matchProEnv = () => {
  const { origin } = window.location;
  const { VISIT_URL } = proConfig;
  return origin === VISIT_URL ? proConfig : testConfig;
};

export default process.env.NODE_ENV === 'production' ? matchProEnv() : testConfig;
// auction QINGrui0818**