import { ExamineModal, FormList, HeaderTitle, Operation, OverItem } from '@/components';
import { auctionHouseList, auctionHouseOverview, auctionHouseUpdate } from '@/api';
import { toDateTime } from '@/utils/utils';
import React, { useEffect, useState } from 'react';
import { st_auction } from '@/utils/statetype';
import { Table } from 'antd';
import { useTable } from '@/hooks';

const { statusObj } = st_auction;
function GoodsBeat({ history }) {
  const [id, setId] = useState(null);
  const [count, setCount] = useState([]);
  const [query, setQuery] = useState({});
  const [visible, setVisible] = useState(false);

  const columns = {
    id: '拍间编号',
    userId: '用户编号',
    name: {
      width: 200,
      title: '拍间名称',
    },
    number: '拍间序数',
    status: {
      title: '拍间状态',
      render: e => statusObj[e] || '-',
    },
    Goods: {
      title: '拍品数量',
      render: e => (e || []).length,
    },
    auctionDuration: {
      title: '拍间期限',
      render: e => `${e}小时`,
    },
    startTime: {
      title: '开拍时间',
      render: e => toDateTime(e),
    },
    endTime: {
      title: '结束时间',
      render: e => toDateTime(e),
    },
    refuseReason: '备注信息',
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            examine: e.status !== 0,
          }}
          listData={{
            select: () => history.push(`/goods/beat/detail?i=${e.id}`),
            examine: () => {
              setId(e.id);
              setVisible(true);
            },
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: auctionHouseList,
    isInit: false,
    params: {
      query,
    },
  });

  useEffect(() => {
    auctionHouseOverview().then(res => {
      const countData = [
        ['拍间总数量', res.allCount],
        ['竞拍中数量', res.auctionstartCount],
        ['已结束数量', res.auctionEndCount],
        ['待审核数量', res.auditCount],
      ];
      setCount(countData);
    });
  }, []);

  const formData = [
    {
      id: 'id',
      label: '拍间编号',
    },
    {
      id: 'name',
      label: '拍间名称',
    },
    {
      id: 'number',
      label: '拍间序数',
    },
    {
      id: 'status',
      label: '拍间状态',
      type: 'select',
      selects: statusObj,
    },
    {
      id: 'times',
      label: '拍卖时间',
      type: 'rangeTime',
    },
    {
      id: 'createTime',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { times, createTime, ...props } = value;
    const [start, end] = times || [];
    const [starts, ends] = createTime || [];
    const q = {
      ...props,
      ...(times && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
      ...(createTime && {
        createdStartTime: toDateTime(starts),
        createdEndTime: toDateTime(ends),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <OverItem datas={count} width={'25%'} />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} />
      <ExamineModal
        visible={visible}
        title="拍间审核"
        data={{ id, s_obj: { 10: '同意', '-10': '拒绝' } }}
        API={auctionHouseUpdate}
        remark="refuseReason"
        onChange={res => {
          if (res) flagFn();
          setId(null);
          setVisible(false);
        }}
      />
    </>
  );
}

export default GoodsBeat;
