import { FormList, HeaderTitle, Operation, OverItem, Thumbnails } from '@/components';
import { getMerchantList, getMerchantOver, postMerchantUpdate } from '@/api';
import { calcPrice, isSupertube, notEmptyObj, toDateTime } from '@/utils/utils';
import { Col, message, Modal, Row, Select, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { st_shop } from '@/utils/statetype';
import { useTable } from '@/hooks';

const { typeObj, statusObj, levelObj } = st_shop;
function ShopList({ history }) {
  const [query, setQuery] = useState({});
  const [count, setCount] = useState([]);
  const [datas, setDatas] = useState({});
  const [level, setLevel] = useState(null);

  const columns = {
    id: '店铺编号',
    title: '店铺名称',
    shopIcon: {
      title: '店铺LOGO',
      render: e => <Thumbnails images={[e]} center />,
    },
    type: {
      title: '店铺类型',
      render: e => typeObj[e] || '-',
    },
    shopLevel: {
      title: '店铺身份',
      render: e => levelObj[e] || '-',
    },
    NOINDEX_category: {
      title: '店铺分类',
      render: e =>
        `${e.Category1 ? e.Category1.name ?? '' : ''}${
          e.Category2 ? `>${e.Category2.name ?? ''}` : ''
        }`,
    },
    recommendMerchantId: '上级店铺编号',
    balance: {
      title: '店铺余额',
      render: e => calcPrice(e),
    },
    firmName: '企业名称',
    creditCode: '社会信用代码',
    name: '法人姓名',
    address: '详细地址',
    status: {
      title: '店铺状态',
      render: e => statusObj[e],
    },
    createdAt: {
      title: '开通时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            identity: e.shopLevel !== 11 || e.status !== 2,
          }}
          hideMenu={{
            identity: !isSupertube(),
          }}
          listData={{
            select: () => history.push(`/shop/list/detail?i=${e.id}`),
            identity: () => setDatas(e),
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getMerchantList,
    isInit: false,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'id',
      label: '店铺编号',
    },
    {
      id: 'title',
      label: '店铺名称',
    },
    {
      id: 'contact',
      label: '手机号码',
    },
    {
      id: 'status',
      label: '店铺状态',
      type: 'select',
      selects: statusObj,
    },
    {
      id: 'applyTime',
      label: '开通时间',
      type: 'rangeTime',
    },
  ];

  useEffect(() => {
    getMerchantOver().then(res => {
      const countData = [['总店铺', res.all], ...(res.category2 || []).map(i => [i.name, i.count])];
      setCount(countData);
    });
  }, []);

  const changeQuery = (value = {}) => {
    const { applyTime, ...props } = value;
    const [start, end] = applyTime || [];
    const q = {
      ...props,
      ...(applyTime && {
        applyStartTime: toDateTime(start),
        applyEndTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <OverItem datas={count} width={'20%'} />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} />
      <Modal
        visible={notEmptyObj(datas)}
        title="店铺升级"
        onCancel={() => {
          setDatas({});
          setLevel(null);
        }}
        onOk={() => {
          if (!level) {
            return message.warning('请设置新的店铺身份');
          }
          postMerchantUpdate({
            body: {
              id: datas.id,
              shopLevel: level,
            },
          }).then(() => {
            message.success('操作成功');
            flagFn();
            setDatas({});
            setLevel(null);
          });
        }}
      >
        <Row align="middle" justify="center">
          <Col>店铺身份：</Col>
          <Select
            placeholder="请设置店铺身份"
            onChange={e => setLevel(Number(e))}
            style={{ width: '220px', margin: '0 20px 0 10px' }}
          >
            {Object.keys(levelObj).map(i => (
              <Select.Option key={i} value={i} disabled={datas.shopLevel >= Number(i)}>
                {levelObj[i]}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </Modal>
    </>
  );
}

export default ShopList;
