import { FormModal, HeaderTitle, Operation } from '@/components';
import { createAdmin, deleteAdmin, getAllRoles, getAllUsers, updateAdmin } from '@/api';
import { notEmptyObj, toDateTime } from '@/utils/utils';
import { Input, message, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTable } from '@/hooks';

function SystemAccount() {
  const [init, setInit] = useState({});
  const [datas, setDatas] = useState([]);
  const [visible, setVisible] = useState(false);

  const columns = {
    id: '账户编号',
    username: '账户名称',
    roles: {
      title: '角色权限',
      render: e => ((e || []).length ? Object.keys(e[0]) : '-'),
    },
    updatedAt: {
      title: '最近修改',
      render: e => toDateTime(e),
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            update: e.id === 1,
            delete: e.id === 1,
          }}
          listData={{
            update: () => {
              setInit(e);
              setVisible(true);
            },
            delete: () => {
              deleteAdmin({
                body: {
                  id: e.id,
                },
              }).then(() => {
                message.success('删除成功');
                flagFn(true);
              });
            },
          }}
        />
      ),
    },
  };

  useEffect(() => {
    getAllRoles().then(res => {
      const resArr = res.map(i => {
        const [title] = Object.entries(i)[0] || [];
        return {
          label: title,
          value: title,
        };
      });
      setDatas(resArr);
    });
  }, []);

  const { tableProps, flagFn } = useTable({
    columns,
    API: getAllUsers,
  });

  const handleSubmit = async params => {
    if (!params || !notEmptyObj(params)) {
      setInit({});
      setVisible(false);
      return;
    }
    try {
      if (init.id) {
        const { roles, password } = params;
        const body = { id: init.id };
        if (roles) body['roles'] = [roles];
        if (password) body['password'] = password;
        await updateAdmin({
          body,
        });
      } else {
        const { roles } = params;
        await createAdmin({
          body: {
            ...params,
            roles: [roles],
          },
        });
      }
      message.success('操作成功');
      setInit({});
      setVisible(false);
      flagFn();
    } catch (error) {
      setInit({});
      message.warning(error.message);
      setVisible(false);
    }
  };

  const formData = [
    {
      name: 'username',
      label: '账户名称',
      rules: [{ required: true, message: '请输入账户名称' }],
      render: <Input disabled={init.id} placeholder="请输入账户名称" />,
    },
    {
      name: 'password',
      label: '账户密码',
      rules: [{ required: true, message: '请输入账户密码' }],
      render: <Input.Password placeholder="请输入账户密码" />,
    },
    {
      name: 'roles',
      label: '角色名称',
      rules: [{ required: true, message: '请选择角色名称' }],
      render: <Select options={datas} placeholder="请选择角色名称" />,
    },
  ];

  const formDatas = [
    {
      name: 'password',
      label: '新账户密码',
      render: <Input.Password placeholder="请输入账户密码" />,
    },
    {
      name: 'roles',
      label: '新角色名称',
      render: <Select options={datas} placeholder="请选择角色名称" />,
    },
  ];

  return (
    <>
      <HeaderTitle title={'新建'} disabled={!global.isOperable} onClick={() => setVisible(true)} />
      <Table {...tableProps} />
      <FormModal
        visible={visible}
        coverTitle={init.username || '新建账户'}
        formData={init.id ? formDatas : formData}
        onChange={handleSubmit}
      />
    </>
  );
}

export default SystemAccount;
