import { getLogsList } from '@/api';
import { FormList, HeaderTitle } from '@/components';
import { useTable } from '@/hooks';
import { st_logs } from '@/utils/statetype';
import { toDateTime } from '@/utils/utils';
import { Table, Tooltip } from 'antd';
import React, { useState } from 'react';

const { eventObj, actionObj, infosObj } = st_logs;
function InfosLogs() {
  const [query, setQuery] = useState({});

  const columns = {
    id: '日志编号',
    event: {
      title: '操作模块',
      render: e => eventObj[e.toLocaleLowerCase()] || '-',
    },
    action: {
      title: '操作类型',
      render: e => actionObj[e.toLocaleLowerCase()] || '-',
    },
    operatorId: '操作者编号',
    operator: '操作者',
    newVal: {
      width: 200,
      title: '操作信息',
      ellipsis: {
        showTitle: false,
      },
      render: e => {
        let result = e;
        Object.keys(infosObj).forEach(i => {
          result = result.replace(i, infosObj[i]);
        });
        return (
          <Tooltip placement="topLeft" title={result}>
            <span>{result || '-'}</span>
          </Tooltip>
        );
      },
    },
    createdAt: {
      title: '操作时间',
      render: e => toDateTime(e),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getLogsList,
    isInit: false,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'event',
      label: '模块名称',
      type: 'select',
      selects: eventObj,
    },
    {
      id: 'action',
      label: '操作名称',
      type: 'select',
      selects: actionObj,
    },
    {
      id: 'createdTime',
      label: '操作时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { createdTime, ...props } = value;
    const [start, end] = createdTime || [];
    const q = {
      ...props,
      ...(createdTime && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} />
    </>
  );
}

export default InfosLogs;
