import React from 'react';
import { DatePicker, InputNumber, Cascader, Select, Button, Input, Form, Row, Col } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;
const defaultField = {
  label: 'label',
  value: 'value',
  children: 'children',
};

function FormItem({ initValue = {}, formData = [], extraRender, searchData, resetData }) {
  const [form] = Form.useForm();

  return (
    <Row gutter={[8, 8]}>
      <Form
        form={form}
        layout="inline"
        preserve={false}
        onFinish={searchData}
        initialValues={initValue}
        style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
      >
        {formData
          .filter(i => !i.hideItem)
          .map(v => {
            const {
              label,
              render,
              type = 'string',
              selects = [], // 选项集
              options = {}, // 单项配置
            } = v;
            const {
              style, // 内部样式
              format = 'YYYY-MM-DD HH:mm',
              showTime = { format: 'HH:mm' },
              fieldNames = defaultField, // 字段替换
              ...config
            } = options;
            const defaultRender = {
              number: (
                <InputNumber
                  allowClear
                  placeholder={`请输入${label}`}
                  style={{ minWidth: '190px', maxWidth: '190px', ...style }}
                  {...config}
                />
              ),
              string: (
                <Input
                  allowClear
                  placeholder={`请输入${label}`}
                  style={{ minWidth: '190px', maxWidth: '190px', ...style }}
                  {...config}
                />
              ),
              select: (
                <Select
                  allowClear
                  placeholder={`请选择${label}`}
                  style={{ minWidth: '190px', maxWidth: '190px', ...style }}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  {...config}
                >
                  {Array.isArray(selects)
                    ? selects.map(i => (
                        <Option key={i[fieldNames.value]}>{i[fieldNames.label]}</Option>
                      ))
                    : Object.keys(selects).map(value => (
                        <Option key={value}>{selects[value]}</Option>
                      ))}
                </Select>
              ),
              rangeTime: (
                <RangePicker
                  allowClear
                  locale={locale}
                  format={format}
                  showTime={showTime}
                  style={{ width: '280px', ...style }}
                  placeholder={['开始时间', '结束时间']}
                  {...config}
                />
              ),
              dateTime: (
                <DatePicker
                  allowClear
                  locale={locale}
                  format={format}
                  placeholder={`请选择${label}`}
                  style={{ minWidth: '190px', maxWidth: '190px', ...style }}
                  {...config}
                />
              ),
              cascader: (
                <Cascader
                  allowClear
                  options={selects}
                  fieldNames={fieldNames}
                  placeholder={`请选择${label}`}
                  style={{ minWidth: '190px', ...style }}
                  {...config}
                />
              ),
            };
            const returnObj = {
              ...v,
              render: render || defaultRender[type],
            };
            return returnObj;
          })
          .map(v => {
            const { id, label, initialValue, render } = v;
            return (
              <Col key={id} className={styles.colForm}>
                <Form.Item
                  name={id}
                  label={label}
                  initialValue={initialValue}
                  className={styles.formItem}
                >
                  {render}
                </Form.Item>
              </Col>
            );
          })}
        {Array.isArray(formData) && formData.filter(i => !i.hideItem).length > 0 && (
          <Col className={styles.colBtn}>
            <Button size="small" type="primary" htmlType="submit" className={styles.submit}>
              查询
            </Button>
            <Button
              size="small"
              onClick={() => {
                form.resetFields();
                resetData();
              }}
            >
              重置
            </Button>
          </Col>
        )}
        {extraRender && <Col className={styles.extraBtn}>{extraRender}</Col>}
      </Form>
    </Row>
  );
}

export default FormItem;
