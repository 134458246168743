import { HeaderTitle, Thumbnails } from '@/components';
import { toDateTime } from '@/utils/utils';
import { relationUsers } from '@/api';
import { useTable } from '@/hooks';
import { Table } from 'antd';
import React from 'react';

function FinanceProfitMember({ querys }) {
  const { i } = querys;

  const columns = {
    id: '用户编号',
    nickname: '用户名称',
    phone: '联系方式',
    avatarUrl: {
      title: '用户头像',
      render: e => <Thumbnails images={[e]} center />,
    },
    recommendUserId: '上级编号',
    NOINDEX_id: {
      title: '关联用户编号',
      render: e => {
        const RelationUser = e.RelationUser || {};
        return RelationUser.id || '-';
      },
    },
    NOINDEX_time: {
      title: '关联时间',
      render: e => {
        const RelationUser = e.RelationUser || {};
        return toDateTime(RelationUser.createdAt);
      },
    },
  };

  const { tableProps } = useTable({
    columns,
    API: relationUsers,
    params: {
      query: {
        userId: i,
      },
    },
  });

  return (
    <>
      <HeaderTitle />
      <Table {...tableProps} />
    </>
  );
}

export default FinanceProfitMember;
