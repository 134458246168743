import Home from '@/pages/Home';
import Login from '@/pages/Login';
import ConfigLabel from '@/pages/ConfigLabel';
import ConfigBanner from '@/pages/ConfigBanner';
import GoodsClassify from '@/pages/GoodsClassify';
import GoodsList from '@/pages/GoodsList';
import GoodsListDetail from '@/pages/GoodsListDetail';
import OrderList from '@/pages/OrderList';
import OrderListDetail from '@/pages/OrderListDetail';
import ShopList from '@/pages/ShopList';
import ShopListDetail from '@/pages/ShopListDetail';
import UserList from '@/pages/UserList';
import SystemLink from '@/pages/SystemLink';
import UserRobot from '@/pages/UserRobot';
import UserListDetail from '@/pages/UserListDetail';
import SystemConfig from '@/pages/SystemConfig';
import UserLevel from '@/pages/UserLevel';
import InfosLogs from '@/pages/InfosLogs';
import InfosList from '@/pages/InfosList';
import GoodsBeat from '@/pages/GoodsBeat';
import GoodsBeatDetail from '@/pages/GoodsBeatDetail';
import FinanceProfit from '@/pages/FinanceProfit';
import InfosTemplate from '@/pages/InfosTemplate';
import ShopServiceFee from '@/pages/ShopServiceFee';
import SettlementDetail from '@/pages/SettlementDetail';
import SystemPower from '@/pages/SystemPower';
import SystemAccount from '@/pages/SystemAccount';
import FinanceBill from '@/pages/FinanceBill';
import FinanceTranData from '@/pages/FinanceTranData';
import FinanceStatData from '@/pages/FinanceStatData';
import FinancePlatform from '@/pages/FinancePlatform';
import FinanceWithdraw from '@/pages/FinanceWithdraw';
import FinanceRecharge from '@/pages/FinanceRecharge';
import FinanceSettlement from '@/pages/FinanceSettlement';
import FinanceProfitDetail from '@/pages/FinanceProfitDetail';
import FinanceProfitMember from '@/pages/FinanceProfitMember';

export default [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    title: '登录',
    component: Login,
  },
  {
    path: '/home',
    title: '概况',
    icon: 'dashboard',
    layout: true,
    component: Home,
  },
  {
    path: '/shop',
    title: '店铺管理',
    icon: 'home',
    layout: true,
    routes: [
      {
        path: '/shop/list',
        title: '店铺列表',
        component: ShopList,
        routes: [
          {
            path: '/shop/list/detail',
            title: '店铺详情',
            component: ShopListDetail,
          },
        ],
      },
      {
        path: '/shop/servicefee',
        title: '授权服务费',
        component: ShopServiceFee,
      },
    ],
  },
  {
    path: '/goods',
    title: '拍品管理',
    icon: 'gift',
    layout: true,
    routes: [
      {
        path: '/goods/classify',
        title: '分类管理',
        component: GoodsClassify,
      },
      {
        path: '/goods/list',
        title: '拍品列表',
        component: GoodsList,
        routes: [
          {
            path: '/goods/list/detail',
            title: '拍品详情',
            component: GoodsListDetail,
          },
        ],
      },
      {
        path: '/goods/beat',
        title: '拍间列表',
        component: GoodsBeat,
        routes: [
          {
            path: '/goods/beat/detail',
            title: '拍间详情',
            component: GoodsBeatDetail,
          },
        ],
      },
    ],
  },
  {
    path: '/order',
    title: '订单管理',
    icon: 'container',
    layout: true,
    routes: [
      {
        path: '/order/list',
        title: '订单列表',
        component: OrderList,
        routes: [
          {
            path: '/order/list/detail',
            title: '订单详情',
            component: OrderListDetail,
          },
        ],
      },
    ],
  },
  {
    path: '/finance',
    title: '财务管理',
    icon: 'dollar',
    layout: true,
    routes: [
      {
        path: '/finance/withdraw',
        title: '提现审核',
        component: FinanceWithdraw,
      },
      {
        path: '/finance/recharge',
        title: '充值列表',
        component: FinanceRecharge,
      },
      {
        path: '/finance/bill',
        title: '认购账单',
        component: FinanceBill,
      },
      {
        path: '/finance/settlement',
        title: '竞拍结算',
        component: FinanceSettlement,
        routes: [
          {
            path: '/finance/settlement/detail',
            title: '竞拍详情',
            component: SettlementDetail,
          },
        ],
      },
      {
        path: '/finance/platform',
        title: '平台账单',
        component: FinancePlatform,
      },
      {
        path: '/finance/tranData',
        title: '交易数据中心',
        component: FinanceTranData,
      },
      {
        path: '/finance/statData',
        title: '统计数据中心',
        component: FinanceStatData,
      },
      {
        path: '/finance/profit',
        title: '关联会员统计',
        component: FinanceProfit,
        routes: [
          {
            path: '/finance/profit/detail',
            title: '统计记录',
            component: FinanceProfitDetail,
          },
          {
            path: '/finance/profit/member',
            title: '关联会员',
            component: FinanceProfitMember,
          },
        ],
      },
    ],
  },
  {
    path: '/user',
    title: '用户管理',
    icon: 'user',
    layout: true,
    routes: [
      {
        path: '/user/list',
        title: '用户列表',
        component: UserList,
        routes: [
          {
            path: '/user/list/detail',
            title: '用户详情',
            component: UserListDetail,
          },
        ],
      },
      {
        path: '/user/level',
        title: '用户身份',
        component: UserLevel,
      },
      {
        path: '/user/robot',
        title: '机器人列表',
        component: UserRobot,
      },
    ],
  },
  {
    path: '/infos',
    title: '消息管理',
    icon: 'sound',
    layout: true,
    routes: [
      {
        path: '/infos/list',
        title: '消息列表',
        component: InfosList,
      },
      {
        path: '/infos/logs',
        title: '操作日志',
        component: InfosLogs,
      },
      {
        path: '/infos/template',
        title: '消息模板',
        component: InfosTemplate,
      },
    ],
  },
  {
    path: '/config',
    title: '配置管理',
    icon: 'tool',
    layout: true,
    routes: [
      {
        path: '/config/label',
        title: '服务标签',
        component: ConfigLabel,
      },
      {
        path: '/config/banner',
        title: '轮播管理',
        component: ConfigBanner,
      },
    ],
  },
  {
    path: '/system',
    title: '系统管理',
    icon: 'setting',
    layout: true,
    routes: [
      {
        path: '/system/config',
        title: '系统配置',
        component: SystemConfig,
      },
      {
        path: '/system/power',
        title: '角色权限',
        component: SystemPower,
      },
      {
        path: '/system/account',
        title: '后台账户',
        component: SystemAccount,
      },
      {
        path: '/system/link',
        title: '链接对照',
        component: SystemLink,
      },
    ],
  },
];
