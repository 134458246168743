import Request from '@/utils/request';

// 获取上传OSS凭证
export const getOSSToken = Request('get', '/m/uploadToken');
// 获取省市区
export const getManageRegions = Request('get', '/m/area/list');
// 登录
export const postLogin = Request('post', '/m/login');
// 订单列表
export const getOrderStore = Request('get', '/m/order/list');
// 订单详情
export const getOrderDetail = Request('get', '/m/order/detail');
// 订单总览
export const getOrderOevr = Request('get', '/m/order/overview');
// 订单编辑
export const postOrderUpdate = Request('post', '/m/order/update');
// 店铺列表
export const getMerchantList = Request('get', '/m/merchant/list');
// 店铺详情
export const getMerchantDetail = Request('get', '/m/merchant/detail');
// 店铺审核
export const postMerchantUpdate = Request('post', '/m/merchant/update');
// 店铺总览
export const getMerchantOver = Request('get', '/m/merchant/overview');
// 商家账单记录
export const getMerchantBill = Request('get', '/m/merchant/bill');
// 拍品列表
export const getGoodsList = Request('get', '/m/goods/list');
// 拍品审核
export const postGoodsUpdate = Request('post', '/m/goods/update');
// 拍品详情
export const getGoodsDetail = Request('get', '/m/goods/detail');
// 拍品总览
export const getGoodsOver = Request('get', '/m/goods/overview');
// 用户列表
export const getUserList = Request('get', '/m/user/list');
// 用户总览
export const getUserOverview = Request('get', '/m/user/overview');
// 修改用户
export const postUserUpdate = Request('post', '/m/user/update');
// 用户收货地址
export const getAddressList = Request('get', '/m/user/address/list');
// 用户身份
export const getUserLevel = Request('get', '/m/user/level/list');
// 用户详情
export const getUserDetail = Request('get', '/m/user/detail');
// 删除用户 --- 暂停使用
// export const postUserDelete = Request('post', '/m/user/delete');
// 分类列表
export const getCategory = Request('get', '/m/category/list');
// 分类详情
export const getCategoryDetail = Request('get', '/m/category/detail');
// 分类添加
export const postCategoryCreate = Request('post', '/m/category/create');
// 分类修改
export const postCategoryUpdate = Request('post', '/m/category/update');
// 分类删除
export const postCategoryDelete = Request('post', '/m/category/delete');
// 标签列表
export const getLabelList = Request('get', '/m/label/list');
// 标签详情
export const getLabelDetail = Request('get', '/m/label/detail');
// 标签添加
export const postLabelCreate = Request('post', '/m/label/create');
// 标签修改
export const postLabelUpdate = Request('post', '/m/label/update');
// 标签删除
export const postLabelDelete = Request('post', '/m/label/delete');
// banner 列表
export const getBannerList = Request('get', '/m/banner/list');
// banner详情
export const getBannerDetail = Request('get', '/m/banner/detail');
// banner 添加
export const postBannerCreate = Request('post', '/m/banner/create');
// banner 修改
export const postBannerUpdate = Request('post', '/m/banner/update');
// banner 删除
export const postBannerDelete = Request('post', '/m/banner/delete');
// 用户提现列表
export const getCashList = Request('get', '/m/extrctCash/list');
// 用户提现审核
export const postCashUpdate = Request('post', '/m/extrctCash/update');
// 用户竞拍记录
export const getOfferRecord = Request('get', '/m/user/offer/list');
// 用户收益明细
export const getUserBill = Request('get', '/m/user/bill/list');
// 创建机器人
export const postRobotCreate = Request('post', '/m/user/robot/create');
// 机器人出价
export const postRobotOffer = Request('post', '/m/user/robot/offer');
// 系统配置
export const getSystemGetall = Request('get', '/m/system/list');
// 修改系统配置
export const updateSystem = Request('post', '/m/system/update');
// 数据中心/现金统计
export const statisticsCash = Request('get', '/m/stat/cash');
// 用户操作日志
export const getLogsList = Request('get', '/m/log/list');
// 消息总览
export const getSubcribeOver = Request('get', '/m/subcribe/overview');
// 拍品统计
export const auctionHouseOverview = Request('get', '/m/auctionHouse/overview');
// 拍间列表
export const auctionHouseList = Request('get', '/m/auctionHouse/list');
// 拍间详情
export const auctionHouseDetail = Request('get', '/m/auctionHouse/detail');
// 拍间编辑
export const auctionHouseUpdate = Request('post', '/m/auctionHouse/update');
// 关联会员收益列表
export const relationUserIncomeList = Request('get', '/m/relationUserIncome/list');
// 关联会员收益详情
export const relationUserIncomeDetail = Request('get', '/m/relationUserIncome/detail');
// 关联会员收益编辑
export const relationUserIncomeUpdate = Request('post', '/m/relationUserIncome/update');
// 关联会员机构列表
export const relationUserList = Request('get', '/m/relationUser/organization/list');
// 关联会员机构记录
export const relationUserBill = Request('get', '/m/relationUser/organization/bill');
// 机构关联会员
export const relationUsers = Request('get', '/m/relationUser/organization/users');
// 消息通知模板
export const noticeList = Request('get', '/m/notice/list');
// 修改消息通知模板
export const noticeUpdate = Request('post', '/m/notice/update');
// 拍品排期详情
export const goodsScheduleDetail = Request('get', '/m/goodsSchedule/detail');
// 拍品排期列表
export const goodsScheduleList = Request('get', '/m/goodsSchedule/list');
// 拍品排期结算明细
export const goodsScheduleSettleDetail = Request('get', '/m/goodsSchedule/settleDetail');
// 获取所有账户
export const getAllUsers = Request('get', '/m/rbac/getAdmin');
// 创建账户
export const createAdmin = Request('post', '/m/rbac/createAdmin');
// 删除账户
export const deleteAdmin = Request('post', '/m/rbac/deleteAdmin');
// 获取所有角色
export const getAllRoles = Request('get', '/m/rbac/getAllRoles');
// 获取所有权限
export const getPermissions = Request('get', '/m/rbac/getPermissions');
// 获取角色权限
export const getPermissionsForUser = Request('get', '/m/rbac/getPermissionsForUser');
// 添加角色权限
export const addPermissionForUser = Request('post', '/m/rbac/addPermissionForUser');
// 删除角色权限
export const deletePermissionForUser = Request('post', '/m/rbac/deletePermissionForUser');
// 创建角色
export const createRole = Request('post', '/m/rbac/createRole');
// 编辑角色
// export const updateRole = Request('post', '/m/rbac/updateRole');
// 删除角色
export const deleteRole = Request('post', '/m/rbac/deleteRole');
// 修改账户
export const updateAdmin = Request('post', '/m/rbac/updateAdmin');
// 拍品排期编辑
export const goodsScheduleUpdate = Request('post', '/m/goodsSchedule/update');
// 用户认购账单明细
export const listUserSubscriptionBill = Request('get', '/m/user/subscriptionBill/list');
// 用户认购账单明细
export const listUserSubscriptBill = Request('get', '/m/user/subscriptionBill/list');
// 用户认购额添加
export const addUesrSubscriptionBill = Request('post', '/m/user/subscriptionBill/add');
// 数据统计
export const getStatData = Request('get', '/m/stat/data');
