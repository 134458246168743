import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Progress, Button, Modal, Alert } from 'antd';
import { transByte } from '@/utils/utils';
import UploadPart from './UploadPart';
import styles from './item.module.scss';

const calFileSize = file => {
  const { size } = file;
  if (!size) return '';
  return transByte(size);
};

function UploadVideoItem({ file, onChange, defaultLocation, defaultStatus, removeItem }) {
  const { name } = file;
  const isSuccess = defaultStatus === 'SUCCESS';

  const [fileStat, setFileStat] = useState({
    name,
    status: defaultStatus,
    progress: isSuccess ? 100 : 0,
    msg: '',
    Location: defaultLocation,
  });
  const [visible, setVisible] = useState(false);
  const u = useMemo(() => new UploadPart(), []);
  const fileSizeLabel = useMemo(() => calFileSize(file), [file]);

  useEffect(() => {
    const { status } = fileStat;
    if (status === 'SUCCESS') {
      onChange(fileStat);
    }
  }, [fileStat]);

  useEffect(() => {
    if (isSuccess) {
      return;
    }
    u.on('progress', e => {
      setFileStat(prev => ({ ...prev, progress: e }));
    });
    u.on('success', e => {
      let { Location } = e;
      Location = Location.replace(/%2F/g, '/');
      setFileStat(prev => ({ ...prev, Location, status: 'SUCCESS' }));
    });
    u.on('error', e => {
      setFileStat(prev => ({ ...prev, msg: e.msg, status: 'ERROR' }));
    });
    u.on('abort', e => {
      setFileStat(prev => ({ ...prev, msg: e.msg, status: 'ABORT' }));
    });

    u.send({ file });
    return () => {
      u.removeListener('success');
      u.removeListener('error');
      u.removeListener('abort');
      u.removeListener('progress');

      u.abort();
    };
  }, []);

  const continueUpload = () => {
    u.continue();
    setFileStat(prev => ({ ...prev, status: 'UPLOADING', msg: '' }));
  };
  const _removeItem = () => {
    removeItem(name);
  };
  const handlePreview = () => {
    const { status } = fileStat;
    if (isSuccess || status === 'SUCCESS') {
      setVisible(true);
    }
  };
  const renderFileInfo = () => {
    return (
      <div className={styles.fileWrap_label}>
        <span className={styles.fileWrap_label_fileName} onClick={handlePreview}>
          {name}
        </span>
        <span>{fileSizeLabel}</span>
      </div>
    );
  };

  const renderPreview = () => {
    const { Location } = fileStat;
    return (
      <Modal
        width={0}
        footer={null}
        destroyOnClose
        closable={false}
        visible={visible}
        bodyStyle={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
        }}
      >
        <div
          className={styles.previews}
          onClick={e => {
            e.stopPropagation();
            setVisible(false);
          }}
        >
          <video
            controls
            src={Location}
            style={{
              width: '880px',
              height: '550px',
              background: '#000',
            }}
          />
        </div>
      </Modal>
    );
  };

  const renderView = () => {
    const { progress, status } = fileStat;

    switch (status) {
      case 'UPLOADING':
        return (
          <div className={styles.wrap}>
            <div className={styles.fileWrap}>
              {renderFileInfo()}
              <Progress strokeWidth={5} percent={progress} status="active" />
            </div>

            <Button type="danger" size="small" onClick={_removeItem}>
              删除
            </Button>
          </div>
        );

      case 'ABORT':
        return (
          <div className={styles.wrap}>
            <div className={styles.fileWrap}>
              {renderFileInfo()}
              <Progress strokeWidth={5} percent={progress} status="exception" />
            </div>

            <Button type="danger" size="small" onClick={_removeItem}>
              删除
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={continueUpload}
              style={{ margin: '0 0 0 10px' }}
            >
              重试
            </Button>
          </div>
        );
      case 'ERROR':
        return (
          <div className={styles.wrap}>
            <div className={styles.fileWrap}>
              {renderFileInfo()}
              <Progress strokeWidth={5} percent={progress} status="exception" />
            </div>
            <Button type="danger" size="small" onClick={_removeItem}>
              删除
            </Button>
          </div>
        );
      case 'SUCCESS':
        return (
          <div className={styles.wrap}>
            <div className={styles.fileWrap}>
              {renderFileInfo()}
              <Progress strokeWidth={5} percent={progress} status="success" />
            </div>
            <Button type="danger" size="small" onClick={_removeItem}>
              删除
            </Button>
          </div>
        );
      default:
        return null;
    }
  };
  const { msg } = fileStat;
  return (
    <div style={{ margin: '5px 0' }}>
      {renderView()}
      {renderPreview()}
      {msg && <Alert message={msg} type="error"></Alert>}
    </div>
  );
}

UploadVideoItem.defaultProps = {
  onChange: () => {},
  defaultLocation: '',
  defaultStatus: 'UPLOADING',
};

UploadVideoItem.propTypes = {
  file: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  defaultLocation: PropTypes.string,
  defaultStatus: PropTypes.string,
};

export default UploadVideoItem;
