import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Button, message } from 'antd';
import UploadVideoItem from './UploadVideoItem';
import _, { isArray } from 'lodash';
import styles from './index.module.scss';

function UploadVideo({ onChange, value, multiple, max = 1, accept, style, title }) {
  const [fileList, setFileList] = useState([]);
  // successFileList 存储成功上传的文件列表
  const fileListRef = useRef({ successFileList: [] });

  // 初始化,应对带初始值的情况
  useEffect(() => {
    if (value === undefined) {
      return;
    }
    if (multiple) {
      if (!isArray(value) || !value.length) {
        return;
      } else {
        const formatValue = value.reduce((acc, URL) => {
          if (typeof URL === 'string' && URL) {
            acc.push({
              Location: URL,
              status: 'SUCCESS',
              file: { name: URL },
              name: URL,
            });
          }
          return acc;
        }, []);
        setFileList(formatValue);
      }
    } else {
      if (!(typeof value === 'string' && value.trim())) {
        return;
      } else {
        setFileList([
          {
            Location: value,
            status: 'SUCCESS',
            file: { name: value },
            name: value,
          },
        ]);
      }
    }
  }, []);

  const handleUploadSuccess = o => {
    const prevSuccessFileList = fileListRef.current.successFileList;
    if (multiple) {
      fileListRef.current.successFileList = [...prevSuccessFileList, o];
    } else {
      fileListRef.current.successFileList = [o];
    }
    handleSuccessFileListChange();
  };

  const handleSuccessFileListChange = () => {
    const { successFileList } = fileListRef.current;
    if (!successFileList.length) {
      onChange();
    } else {
      if (multiple) {
        onChange(fileListRef.current.successFileList.map(item => item.Location));
      } else {
        onChange(successFileList[0].Location);
      }
    }
  };

  const onRemoveItem = fileName => {
    const filterFileList = fileList.filter(o => o.name !== fileName);
    const prevSuccessFileList = fileListRef.current.successFileList;
    fileListRef.current.successFileList = prevSuccessFileList.filter(o => o.name !== fileName);
    setFileList(filterFileList);
    handleSuccessFileListChange();
  };

  return (
    <div className={styles.container} style={style}>
      <div>
        <Upload
          accept={accept}
          multiple={multiple}
          showUploadList={false}
          customRequest={fileObj => {
            const { file } = fileObj;
            const { name } = file;
            const defaultFileObj = { file, name };
            if (fileList.length >= max) return message.warning('视频选择超过上限');
            if (multiple) {
              const findFile = fileList.find(o => o.name === name);
              if (!findFile) {
                setFileList(prev => [...prev, defaultFileObj]);
              }
            } else {
              setFileList([defaultFileObj]);
            }
          }}
        >
          <Button type="primary">{title}</Button>
        </Upload>
      </div>

      {fileList.map(({ file, status, name, Location }, index) => (
        <UploadVideoItem
          key={name}
          file={file}
          defaultStatus={status}
          defaultLocation={Location}
          removeItem={e => onRemoveItem(e, index)}
          onChange={e => handleUploadSuccess(e, index)}
        />
      ))}
    </div>
  );
}

UploadVideo.defaultProps = {
  title: '选择视频',
  multiple: false,
  accept: 'video/mp4,video/3gp,video/m3u8',
  style: {},
  value: undefined,
};

UploadVideo.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default UploadVideo;
