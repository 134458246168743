import { Button, Input, InputNumber, message } from 'antd';
import React, { useState } from 'react';
import IconFont from '../IconFont';
import styles from './index.module.scss';

function SmallEdit({ value, params, disabled, number, onSubmit }) {
	const [edit, setEdit] = useState(false)
	const [result, setResult] = useState('')

	return edit ? (
		<div className={styles.inputGroup}>
			<div className={styles.inputBox}>
				{number ? (
					<InputNumber
						min={0}
						size='small'
						precision={0}
						placeholder='请输入'
						defaultValue={value}
						className={styles.editInput}
						onChange={(val) => setResult(val)}
					/>
				) : (
					<Input
						size='small'
						placeholder='请输入'
						defaultValue={value}
						className={styles.editInput}
						onChange={(e) => {
							const val = e.target.value
							setResult(val)
						}}
					/>
				)}
				<Button.Group className={styles.btnGroup}>
					<Button
						size='small'
						type='primary'
						className={styles.editButton}
						onClick={() => {
							if (!result) {
								message.warning('暂未修改数据')
								return
							}
							onSubmit(result, params)
							setEdit(false)
							setResult('')
						}}
					>
						确认
					</Button>
					<Button
						size='small'
						className={styles.editButton}
						onClick={() => {
							setEdit(false)
							setResult('')
						}}
					>
						取消
					</Button>
				</Button.Group>
			</div>
		</div>
	) : (
		<div>
			{typeof value === 'number' || value ? value : '-'}
			{!disabled && (
				<IconFont
					type='edit'
					style={{
						cursor: 'pointer',
						marginLeft: '10px',
						color: '#1890ff'
					}}
					onClick={() => setEdit(true)}
				/>
			)}
		</div>
	)
}

export default SmallEdit;
