import OSS from 'ali-oss';
import { random } from 'lodash';
import config from '@/config';
import { OSSPREFIX } from '@/utils/constants';
import { overOSSToken } from '@/utils/utils';
import { getUploadToken } from '@/utils/helper';

const { OSS_FILE_PREFIX } = config;
const eventKey = ['progress', 'success', 'error', 'abort'];

const createFileKey = file => {
  if (file) {
    const { type = '', name = '' } = file || {};
    const folder = (type && type.split('/')[0]) || 'video';
    const nameSuffix = name
      .toString()
      .split('.')
      .reverse()[0];
    const prefix = String(Date.now()) + random(1000000, 9999999);
    return `${OSSPREFIX}/${OSS_FILE_PREFIX}${folder}s/${prefix}.${nameSuffix}`;
  }
  return '';
};

export default class UploadPart {
  constructor(props) {
    // OSS实例
    this.instance = null;
    // 监听事件
    this.event = {};
    // 状态
    this.status = 'PENDING';
    // 错误信息
    this.errorMsg = '';
    // 同时上传的分片数
    this.parallel = 3;
    // 每个分片大小(byte)
    this.partSize = 1024 * 1024;
    // 所有分片上传文件的检查点
    this.checkpoints = {};
    // CDN
    this.baseUrl = null;
  }

  init() {
    return new Promise((resolve, reject) => {
      getUploadToken()
        .then(() => {
          if (this.instance) {
            this.instance.cancel();
          }
          const { uploadToken } = global;
          const { baseUrl, ...OSSTOKEN } = uploadToken;
          this.baseUrl = baseUrl;
          this.instance = new OSS(OSSTOKEN);
          resolve();
        })
        .catch(() => {
          this.callEvent('error', { msg: '初始化上传异常' });
          reject();
        });
    });
  }

  fileSlice(file, chunkSize) {
    let totalSize = file.size; // 文件总大小
    let start = 0; // 每次上传的开始字节
    let end = start + chunkSize; // 每次上传的结尾字节
    let chunks = [];
    while (start < totalSize) {
      let blob = file.slice(start, end);
      chunks.push(blob);
      start = end;
      end = start + chunkSize;
    }
    return chunks;
  }

  abort() {
    if (!this.instance) return;
    this.checkpoints = {};
  }

  continue() {
    this.resumeMultipartUpload();
  }

  async onMultipartUploadProgress(progress, checkpoint) {
    if (overOSSToken()) {
      await this.init();
      await this.resumeMultipartUpload();
    } else {
      this.checkpoints[checkpoint.uploadId] = checkpoint;
      this.callEvent('progress', parseInt(progress * 100));
    }
  }

  async resumeMultipartUpload() {
    this.status = 'PENDING';
    if (!this.instance) {
      await this.init();
    }
    Object.values(this.checkpoints).forEach(checkpoint => {
      this.uploadPart(checkpoint);
    });
  }

  uploadPart(checkpoint) {
    this.onMultipartUpload(checkpoint)
      .then(res => {
        this.status = 'SUCCESS';
        this.callEvent('success', res);
      })
      .catch(err => {
        this.status = 'ABORT';
        this.callEvent('abort', { msg: err });
      });
  }

  async onMultipartUpload(checkpoint) {
    return new Promise((r, j) => {
      const options = checkpoint
        ? {
            checkpoint,
          }
        : {};
      this.instance
        .multipartUpload(checkpoint ? checkpoint.uploadId : this.Key, this.file, {
          parallel: this.parallel,
          partSize: this.partSize,
          progress: (progress, checkpoint) => this.onMultipartUploadProgress(progress, checkpoint),
          ...options,
        })
        .then(data => {
          const { name } = data || {};
          const Location = `${this.baseUrl}/${name}`;
          if (Location) r({ Location });
          else j('资源上传失败');
        })
        .catch(() => {
          j('网络异常,请检查您的网络连接后再重试');
        });
    });
  }

  on(eventName, callback) {
    if (typeof callback === 'function' && eventKey.includes(eventName)) {
      this.event[eventName] = callback;
    }
  }

  removeListener(eventName) {
    if (eventKey.includes(eventName)) {
      this.event[eventName] = null;
    }
  }

  callEvent(eventName, params) {
    const getEvent = this.event[eventName];
    if (typeof getEvent === 'function') {
      getEvent(params);
    }
  }

  async send(config) {
    await this.init();
    const { file, Key = createFileKey(file) } = config;
    this.Key = Key;
    this.file = file;
    this.uploadPart();
  }
}
