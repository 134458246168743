import { message } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';

function LabelArrow({ datas = [], addLabel }) {
  const curPath = location.pathname;
  const history = useHistory();

  const handleSkip = ({ path }, index) => {
    if (index === 0) return message.warning('一级菜单无法跳转');
    if (curPath === path) return message.warning('当前页面无法重复跳转');
    if (index + 2 === datas.length) {
      history.goBack();
    } else {
      message.warning('不支持跨层级跳转');
    }
  };

  return (
    <div className={styles.label_arrow_box}>
      {datas.map((i, k) => (
        <div
          key={k}
          style={curPath === i.path ? { background: '#188fff', color: '#fff' } : {}}
          onClick={() => handleSkip(i, k)}
        >
          <div />
          <div>{i.title}</div>
          <div />
        </div>
      ))}
      {addLabel && (
        <div style={{ background: '#188fff', color: '#fff' }}>
          <div />
          <div title={addLabel.tips} onClick={() => addLabel.onClick && addLabel.onClick()}>
            <span>{addLabel.label}</span>
          </div>
          <div />
        </div>
      )}
    </div>
  );
}

export default LabelArrow;
