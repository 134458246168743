import { getSubcribeOver } from '@/api';
import { HeaderTitle, Buffer } from '@/components';
import { Card, Col, Collapse, Row } from 'antd';
import React, { useEffect, useState } from 'react';

const { Panel } = Collapse;
function InfosList({ history }) {
  const [load, setLoad] = useState(true);
  const [datas, setDatas] = useState({});

  useEffect(() => {
    getSubcribeOver().then(res => {
      setDatas(res);
      setLoad(false);
    });
  }, []);

  if (load) return <Buffer />;

  const lists = [
    {
      title: '店铺审核通知',
      label: '名用户申请开通店铺，等待审核中！',
      value: datas.merchantAuditCount,
      btns: '去审核',
      urls: '/shop/list',
    },
    {
      title: '拍品审核通知',
      label: '件拍品正在等待审核中！',
      value: datas.auditGoodsCount,
      btns: '去审核',
      urls: '/goods/list',
    },
    {
      title: '拍间审核通知',
      label: '个拍间正在等待审核中！',
      value: datas.auditAuctionHouseCount,
      btns: '去审核',
      urls: '/goods/beat',
    },
    {
      title: '用户提现通知',
      label: '名用户申请提现，等待审核中！',
      value: datas.extractCashCount,
      btns: '立即查看',
      urls: '/finance/withdraw',
    },
    {
      title: '未发货提醒',
      label: '条订单等待发货！',
      value: datas.waitSendCount,
      btns: '立即查看',
      urls: '/order/list?s=30',
    },
  ];

  return (
    <>
      <HeaderTitle />
      <Collapse defaultActiveKey={lists.map((_, k) => k)} ghost>
        {lists.map((item, key) => (
          <Panel header={item.title} key={key}>
            <Card style={{ background: '#f5f5f5', padding: '30px', marginBottom: '0' }}>
              {item.value ? (
                <Row>
                  有<Col style={{ color: '#1890ff', margin: '0 2px' }}>{item.value}</Col>
                  {item.label}
                  <Col
                    style={{ color: '#1890ff', cursor: 'pointer' }}
                    onClick={() => history.push(item.urls)}
                  >
                    {item.btns}
                  </Col>
                </Row>
              ) : (
                <Col style={{ color: '#999' }}>没有任何消息通知</Col>
              )}
            </Card>
          </Panel>
        ))}
      </Collapse>
    </>
  );
}

export default InfosList;
