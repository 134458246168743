import { DatePicker, Input, InputNumber, message, Select } from 'antd';
import { postBannerCreate, postBannerUpdate } from '@/api';
import { FormModal, UploadImage } from '@/components';
import { ergodicObj, toDateTime } from '@/utils/utils';
import { st_banner } from '@/utils/statetype';
import React from 'react';

const { RangePicker } = DatePicker;
const { positionObj, typeObj } = st_banner;
function BannderEdit({ visible, data, onChange }) {
  const handleSubmit = async params => {
    if (!params) return onChange();
    const { times, ...props } = params;
    data.id
      ? await postBannerUpdate({
          body: {
            id: data.id,
            ...params,
            startTime: toDateTime(times[0]),
            endTime: toDateTime(times[1]),
          },
        })
      : await postBannerCreate({
          body: {
            ...props,
            startTime: toDateTime(times[0]),
            endTime: toDateTime(times[1]),
          },
        });
    message.success('操作成功');
    onChange(true);
  };

  const formData = [
    {
      name: 'image',
      label: '展示图片',
      rules: [{ required: true, message: '请选择展示图片' }],
      render: <UploadImage model="single" callBackString />,
    },
    {
      name: 'position',
      label: '展示位置',
      rules: [{ required: true, message: '请选择展示位置' }],
      render: <Select options={ergodicObj(positionObj)} placeholder="请选择展示位置" />,
    },
    {
      name: 'type',
      label: '展示类型',
      rules: [{ required: true, message: '请选择展示类型' }],
      render: <Select options={ergodicObj(typeObj)} placeholder="请选择展示类型" />,
    },
    {
      name: 'times',
      label: '展示时间',
      rules: [{ required: true, message: '请输入展示时间' }],
      render: <RangePicker showTime />,
    },
    {
      name: 'sort',
      label: '展示排序',
      rules: [{ required: true, message: '请输入展示排序' }],
      render: <InputNumber placeholder="请输入展示排序" min={0} max={1000} precision={0} />,
    },
    {
      name: 'goto',
      label: '跳转路径',
      rules: [{ required: true, message: '请输入跳转路径' }],
      render: <Input placeholder="请输入跳转路径" />,
    },
  ];

  return (
    <FormModal
      title="Banner"
      visible={visible}
      formData={formData}
      initialValues={data}
      onChange={handleSubmit}
    />
  );
}

export default BannderEdit;
