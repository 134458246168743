import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { message } from 'antd';

function CopyText({ text, link, def = '-', render }) {
  return !text && !render ? def : (
    <CopyToClipboard
      text={text}
      onCopy={() => message.success('复制成功')}
    >
      {render || (
        <span
          style={{
            cursor: 'pointer',
            userSelect: 'none',
            wordBreak: 'break-all',
            color: link ? '#1890FF' : 'rgba(0, 0, 0, 0.65)',
          }}
        >
          {text}
        </span>
      )}
    </CopyToClipboard>
  )
}

export default CopyText;
