import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import KeepAlive, { AliveScope, useAliveController } from 'react-activation';
import { getToken } from '@/utils/storage';
import routes from './routes';

function PageRoute() {
  const { roles = [] } = getToken();
  const { dropScope, clear, getCachingNodes } = useAliveController();

  const getParams = search => {
    const resPs = {};
    if (!search) return resPs;
    const local = (search ?? '').replace('?', '');
    local.split('&').forEach(i => {
      const key = (i ?? '').split('=')[0];
      const val = (i ?? '').split('=')[1];
      if (key && val) {
        resPs[key] = isNaN(val) ? val : Number(val);
      }
    });
    return resPs;
  };

  const handleRouter = (item, KA) => {
    const { path, redirect, component, keepAlive } = item;
    const REDIRECT = redirect ? () => <Redirect to={redirect} /> : component;
    if (!REDIRECT) return null;
    return (
      <Route
        exact
        key={path}
        path={path}
        render={prop => {
          const { search } = prop.location || {};
          const props = {
            ...prop,
            querys: getParams(search), // 参数
            onClearCache: () => KA && clear(), // 主动清除
          };
          if (!KA) {
            const ndArr = getCachingNodes();
            ndArr.forEach(({ name, when }) => when && path !== name && dropScope(name));
          }
          return (
            <KeepAlive when={!!keepAlive} name={path}>
              <REDIRECT {...props} />
            </KeepAlive>
          );
        }}
      />
    );
  };

  const RouterDom = (routerArr = [], layout, KA, index = 1) => {
    let resultChild = [];
    routerArr.forEach(i => {
      if (i.routes && i.routes.length) {
        const list = RouterDom(i.routes, i.layout || layout, i.keepAlive, index + 1);
        resultChild = resultChild.concat(list);
      }
      const curPath = i.redirect ? i.redirect : i.path;
      // 权限处理
      if (layout || i.layout) {
        if (index < 3 && !roles.includes(curPath)) return null;
        if (index >= 3 && !roles.includes(curPath.replace(/\/{1}[^\/]+$/, ''))) return null;
      }
      const ROUTERDOM = handleRouter(i, KA);
      if (ROUTERDOM) resultChild.push(ROUTERDOM);
    });
    return resultChild;
  };

  return (
    <AliveScope>
      <Switch>{RouterDom(routes)}</Switch>
    </AliveScope>
  );
}

export default PageRoute;
