import { getCategory, postCategoryUpdate, postCategoryDelete } from '@/api';
import { HeaderTitle, IconFont, Operation, Thumbnails } from '@/components';
import { isSupertube, recurTurnFlat, toDateTime } from '@/utils/utils';
import { useTable, useUpdateEffect } from '@/hooks';
import React, { useState } from 'react';
import { message, Table } from 'antd';
import ClassifyModal from './components/ClassifyModal';
import styles from './style.module.scss';

function GoodsClassify() {
  let lists = [];
  const [datas, setDatas] = useState({});
  const [visible, setVisible] = useState(false);

  const handleUpdateL3 = async (id, flag) => {
    await postCategoryUpdate({
      body: {
        id,
        sign1: flag ? 10 : 20, // true 新增子四级 flase 删除子四级
      },
    });
    flagFn();
  };

  const handleAdd = e => {
    const { item } = e;
    setDatas({
      id: e.id,
      level: e.level + 1,
      item: item && item.length > 0,
    });
    setVisible(true);
  };

  const columns = {
    level: {
      title: '分类级别',
      render: e => {
        if (Number(e) === 3) {
          return '属性名';
        }
        if (Number(e) === 4) {
          return '属性值';
        }
        return `${e}级`;
      },
    },
    id: '分类编号',
    name: '分类名称',
    NOINDEX_icon: {
      title: '分类图片',
      render: ({ iconUrl, level }) =>
        level === 2 ? <Thumbnails images={[iconUrl]} center /> : '-',
    },
    NOINDEX_sign0: {
      title: '选值方式',
      render: ({ level, sign1 }) => {
        if (level === 3) {
          return sign1 === 20 ? '自填' : '选择';
        }
        return '-';
      },
    },
    NOINDEX_sign1: {
      title: '分类筛选',
      render: ({ level, sign1 }) => {
        if (level === 4) {
          return `${sign1 === 30 ? '显示' : '隐藏'}`;
        }
        return '-';
      },
    },
    NOINDEX_sign2: {
      title: '条件筛选',
      render: ({ level, sign2 }) => {
        if (level === 3) {
          return `${sign2 === 10 ? '显示' : '隐藏'}`;
        } else if (level === 4) {
          return `${sign2 === 30 ? '显示' : '隐藏'}`;
        }
        return '-';
      },
    },
    item: {
      title: '子分类数量',
      render: e => (Array.isArray(e) ? e.length : 0),
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          hideMenu={{
            subset: e.level !== 1 || !isSupertube(),
            subsetKey: e.level !== 2 || !isSupertube(),
            subsetValue: e.level !== 3 || !isSupertube(),
            update: !isSupertube(),
            delete: !isSupertube(),
          }}
          listData={{
            subset: () => {
              handleAdd(e);
            },
            subsetKey: () => {
              handleAdd(e);
            },
            subsetValue: () => {
              handleAdd(e);
            },
            update: () => {
              const { item, ...prop } = e;
              setDatas({
                ...prop,
                item: item && item.length > 0,
              });
              setVisible(true);
            },
            delete: () => {
              const { parentId, level } = e;
              postCategoryDelete({
                body: {
                  id: e.id,
                },
              }).then(() => {
                message.success('删除成功');
                if (level === 4) {
                  const len = lists.filter(i => i.parentId === parentId).length;
                  if (len === 1) {
                    handleUpdateL3(parentId, false);
                    return;
                  }
                }
                flagFn();
              });
            },
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    coverKey: {
      data: 'data',
    },
    isPage: false,
    API: getCategory,
  });

  useUpdateEffect(() => {
    const dataSource = tableProps.dataSource || [];
    if (dataSource.length > 0) {
      lists = recurTurnFlat(dataSource, 'item');
    }
  }, [tableProps]);

  return (
    <>
      <HeaderTitle
        title={'添加1级分类'}
        disabled={!isSupertube() || !global.isOperable}
        onClick={() => {
          setDatas({ id: 0, level: 1 });
          setVisible(true);
        }}
      />
      <div className={styles.tipText}>
        <IconFont type="info-circle" />
        <span>请注意分类最多添加到四级</span>
      </div>
      <Table {...tableProps} expandable={{ childrenColumnName: 'Item' }} />
      <ClassifyModal
        data={datas}
        visible={visible}
        onChange={res => {
          if (datas.level === 4 && !datas.item && res === 'create') {
            handleUpdateL3(datas.id, true);
          } else if (res) {
            flagFn();
          }
          setDatas({});
          setVisible(false);
        }}
      />
    </>
  );
}

export default GoodsClassify;
