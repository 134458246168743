import {
  getAddressList,
  getOfferRecord,
  getUserBill,
  getUserDetail,
  getUserList,
  goodsScheduleDetail,
  listUserSubscriptionBill,
} from '@/api';
import { calcPrice, getRegionsName, toDateTime } from '@/utils/utils';
import { CardItem, ExportData, HeaderTitle, Thumbnails } from '@/components';
import { st_bill, st_goods, st_user } from '@/utils/statetype';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';

const { sexObj, levelObj, statusObj: statusObjs } = st_user;
const { typeObj: typeObjb } = st_bill;
const { statusObj, typeObj } = st_goods;
const pageSize = 8;
function UserListDetail({ querys, history }) {
  const { i } = querys;
  const [datas, setDatas] = useState({});
  const [userList, setUserList] = useState([]);
  const [record, setRecord] = useState({});
  const [bill, setBill] = useState({});
  const [subBill, setSubBill] = useState({});
  const [address, setAddress] = useState([]);
  const merchant = datas.Merchant || {};
  const stat = datas.goodsCategory2Stat || [];
  const statVal = stat.map(i => `【${i.categoryName}：${i.count}】`).join();

  useEffect(() => {
    getUserDetail({
      query: {
        id: i,
      },
    }).then(res => {
      setDatas(res);
    });
  }, []);

  const renderInfo = [
    {
      label: '用户头像',
      render: <Thumbnails images={[datas.avatarUrl]} />,
    },
    {
      label: '用户编号',
      render: datas.id,
    },
    {
      label: '用户昵称',
      render: datas.nickname,
    },
    {
      label: '手机号码',
      render: datas.phone,
    },
    {
      label: '用户性别',
      render: sexObj[datas.sex],
    },
    {
      label: '邀请码',
      render: datas.uniqueCode,
    },
    {
      label: '用户余额',
      render: calcPrice(datas.balance),
    },
    {
      label: '冻结金额',
      render: calcPrice(datas.frozenAmount),
    },
    {
      label: '个品认购额',
      render: calcPrice(datas.oneSubscriptionAmount),
    },
    {
      label: '标品认购额',
      render: calcPrice(datas.productSubscriptionAmount),
    },
    {
      label: '总认购额',
      render: calcPrice(datas.oneSubscriptionAmount + datas.productSubscriptionAmount),
    },
    {
      label: '用户身份',
      render: levelObj[datas.level],
    },
    {
      label: '推荐用户编号',
      render: datas.recommendUserId,
    },
    {
      label: '商户名称',
      render: merchant.title,
    },
    {
      label: '商户地址',
      render: merchant.address,
    },
    {
      label: '联系方式',
      render: merchant.contact,
    },
    {
      label: '注册时间',
      render: toDateTime(datas.createdAt),
    },
  ];

  const renderCount = [
    {
      label: '参拍次数',
      render: datas.participateOfferCount,
    },
    {
      label: '获拍次数',
      render: datas.obtainOfferCount,
    },
    {
      label: '认购拍品品类',
      render: datas.subscriptionCategoryNameStr,
    },
    {
      label: '获拍拍品品类',
      render: datas.auctionCategoryNameStr,
    },
    {
      label: '累计消费金额',
      render: calcPrice(datas.accumulatedConsumptionSum),
    },
    {
      label: '品类获拍次数',
      render: statVal,
    },
  ];

  useEffect(() => {
    getUserList({
      query: {
        recommendUserId: i,
        pageNo: 1,
        pageSize: 500,
      },
    }).then(res => {
      setUserList(res.data);
    });
  }, []);

  const renderRecommend = {
    dataSource: userList,
    columns: [
      {
        title: '用户编号',
        dataIndex: 'id',
      },
      {
        title: '用户昵称',
        dataIndex: 'nickname',
      },
      {
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        title: '注册时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  const handleGetRecord = (pageNo = 1) => {
    getOfferRecord({
      query: {
        userId: i,
        pageNo,
        pageSize,
      },
    }).then(res => {
      setRecord(res);
    });
  };

  const renderRecord = {
    dataSource: record.data,
    loading: !record.data,
    pagination: {
      pageSize,
      size: 'small',
      total: record.count,
      showTotal: e => `共${e}条`,
      onChange: e => handleGetRecord(e),
    },
    columns: [
      {
        title: '记录编号',
        dataIndex: 'id',
      },
      {
        title: '拍品编号',
        dataIndex: 'Goods',
        render: e => e.id,
      },
      {
        title: '拍品名称',
        dataIndex: 'Goods',
        render: e => e.name,
      },
      {
        title: '拍品数量',
        dataIndex: 'GoodsSchedule',
        render: e => e.num,
      },
      {
        title: '拍品类型',
        dataIndex: 'GoodsSchedule',
        render: e => typeObj[e.type],
      },
      {
        title: '拍品状态',
        dataIndex: 'GoodsSchedule',
        render: e => statusObj[e.status],
      },
      {
        title: '初始价格',
        dataIndex: 'GoodsSchedule',
        render: e => calcPrice(e.startPrice),
      },
      {
        title: '出价价格',
        dataIndex: 'offer',
        render: e => calcPrice(e),
      },
      {
        title: '出价状态',
        dataIndex: 'status',
        render: e => statusObjs[e],
      },
      {
        title: '出价时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  const handleGetBill = (pageNo = 1) => {
    getUserBill({
      query: {
        userId: i,
        pageNo,
        pageSize,
      },
    }).then(res => {
      setBill(res);
    });
  };

  const sourceFn = {
    goodsSchedule: async ({ sourceId }) => {
      const res = await goodsScheduleDetail({ query: { goodsScheduleId: sourceId } });
      history.push(`/goods/list/detail?i=${res.goodsId}`);
    },
    userInvite: ({ sourceId }) => {
      history.push(`/user/list/detail?i=${sourceId}`);
    },
    auctionHouse: ({ sourceId }) => {
      history.push(`/goods/beat/detail?i=${sourceId}`);
    },
    userBill: () => {
      history.push(`/user/list/detail?i=${datas.userId}`);
    },
    userOrderDetail: ({ sourceId }) => {
      history.push(`/order/list/detail?i=${sourceId}`);
    },
    userDeliveryDetail: () => {
      history.push(`/user/list/detail?i=${datas.userId}`);
    },
    merchantGoods: ({ sourceId }) => {
      history.push(`/goods/list/detail?i=${sourceId}`);
    },
  };

  const renderBill = {
    dataSource: bill.data,
    loading: !bill.data,
    pagination: {
      pageSize,
      size: 'small',
      total: bill.count,
      showTotal: e => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ExportData
            title="用户账单明细"
            btnProp={{ size: 'small' }}
            API={getUserBill}
            query={{ userId: i }}
            extend={{
              type: typeObjb,
              amount: 'amount',
              createdAt: 'time',
            }}
            columns={{
              id: '明细编号',
              number: '收益单号',
              'User.id': '用户编号',
              'User.nickname': '用户昵称',
              type: '收益类型',
              kind: '收益分类',
              amount: '总收益',
              sourceId: '来源编号',
              source: '收益来源',
              describe: '备注信息',
              createdAt: '创建时间',
            }}
          />
          <span style={{ marginLeft: '24px' }}>共{e}条</span>
        </div>
      ),
      onChange: e => handleGetBill(e),
    },
    columns: [
      {
        title: '明细编号',
        dataIndex: 'id',
      },
      {
        title: '收益单号',
        dataIndex: 'number',
      },
      {
        title: '收益类型',
        dataIndex: 'type',
        render: e => typeObjb[e],
      },
      {
        title: '收益分类',
        dataIndex: 'kind',
      },
      {
        title: '总收益',
        dataIndex: 'amount',
        render: e => calcPrice(e),
      },
      {
        title: '来源编号',
        dataIndex: 'sourceId',
      },
      {
        title: '收益来源',
        dataIndex: 'source',
        render: (e, env) =>
          !!sourceFn[e] ? (
            <Button type="link" style={{ padding: '0' }} onClick={() => sourceFn[e](env)}>
              {e}
            </Button>
          ) : (
            e
          ),
      },
      {
        title: '备注信息',
        dataIndex: 'describe',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  useEffect(() => {
    getAddressList({
      query: {
        userId: i,
        pageNo: 1,
        pageSize: 500,
      },
    }).then(res => {
      setAddress(res.data);
    });
  }, []);

  const renderAddress = {
    dataSource: address,
    columns: [
      {
        title: '收货人',
        dataIndex: 'consignee',
      },
      {
        title: '联系方式',
        dataIndex: 'phone',
      },
      {
        title: '省市区',
        render: e => `${getRegionsName([e.provinceId, e.cityId, e.areaId]).join('-')}`,
      },
      {
        title: '详细地址',
        dataIndex: 'address',
      },
      {
        title: '默认地址',
        dataIndex: 'isDefault',
        render: e => (e === 1 ? '是' : '否'),
      },
      {
        title: '最近修改',
        dataIndex: 'updatedAt',
        render: e => toDateTime(e),
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  const handleGetSubBill = (pageNo = 1) => {
    listUserSubscriptionBill({
      query: {
        userId: i,
        pageNo,
        pageSize,
      },
    }).then(res => {
      setSubBill(res);
    });
  };

  useEffect(() => {
    handleGetRecord();
    handleGetBill();
    handleGetSubBill();
  }, []);

  const renderSubBill = {
    dataSource: subBill.data,
    loading: !subBill.data,
    pagination: {
      pageSize,
      size: 'small',
      total: subBill.count,
      showTotal: e => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ExportData
            title="用户认购账单明细"
            btnProp={{ size: 'small' }}
            API={listUserSubscriptionBill}
            query={{ userId: i }}
            extend={{
              type: typeObjb,
              amount: 'amount',
              createdAt: 'time',
            }}
            columns={{
              id: '明细编号',
              number: '收益单号',
              'User.id': '用户编号',
              'User.nickname': '用户昵称',
              type: '收益类型',
              kind: '收益分类',
              amount: '总收益',
              sourceId: '来源编号',
              source: '收益来源',
              describe: '备注信息',
              createdAt: '创建时间',
            }}
          />
          <span style={{ marginLeft: '24px' }}>共{e}条</span>
        </div>
      ),
      onChange: e => handleGetSubBill(e),
    },
    columns: [
      {
        title: '明细编号',
        dataIndex: 'id',
      },
      {
        title: '收益单号',
        dataIndex: 'number',
      },
      {
        title: '收益类型',
        dataIndex: 'type',
        render: e => typeObjb[e],
      },
      {
        title: '收益分类',
        dataIndex: 'kind',
      },
      {
        title: '总收益',
        dataIndex: 'amount',
        render: e => calcPrice(e),
      },
      {
        title: '来源编号',
        dataIndex: 'sourceId',
      },
      {
        title: '收益来源',
        dataIndex: 'source',
        render: (e, env) =>
          !!sourceFn[e] ? (
            <Button type="link" style={{ padding: '0' }} onClick={() => sourceFn[e](env)}>
              {e}
            </Button>
          ) : (
            e
          ),
      },
      {
        title: '备注信息',
        dataIndex: 'describe',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  return (
    <>
      <HeaderTitle />
      <Card>
        <CardItem title={'用户信息'} datas={renderInfo} />
        <CardItem title={'统计信息'} datas={renderCount} />
        <CardItem title={'收益明细'} datas={renderBill} />
        <CardItem title={'竞拍记录'} datas={renderRecord} />
        <CardItem title={'认购账单明细'} datas={renderSubBill} />
        <CardItem title={'发展下级'} datas={renderRecommend} />
        <CardItem title={'收货地址'} datas={renderAddress} />
      </Card>
    </>
  );
}

export default UserListDetail;
