import { auctionHouseDetail, auctionHouseUpdate } from '@/api';
import { CardItem, HeaderTitle, Operation, Thumbnails } from '@/components';
import { calcPrice, notEmptyObj, parseJSON, toDateTime, toSpliceStr } from '@/utils/utils';
import { st_auction, st_goods, st_user } from '@/utils/statetype';
import { Card, DatePicker, message, Modal, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { statusObj, typeObj } = st_goods;
const { statusObj: statusObjs } = st_user;
const { statusObj: acStatusObj } = st_auction;
function GoodsBeatDetail({ querys }) {
  const { i: ID } = querys;
  let times = useRef(null);
  const [datas, setDatas] = useState({});
  const [offerList, setOfferList] = useState([]);
  const [merchant, setMerchant] = useState({});
  const [goodsAuction, setGoodsAuction] = useState({});

  const handleGetData = () => {
    auctionHouseDetail({
      query: {
        id: Number(ID),
      },
    }).then(res => {
      setDatas(res);
    });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const renderInfo = [
    {
      label: '拍间编号',
      render: datas.id,
    },
    {
      label: '提交用户编号',
      render: datas.userId,
    },
    {
      label: '拍间名称',
      render: datas.name,
    },
    {
      label: '拍间期限',
      render: `${datas.auctionDuration}小时`,
    },
    {
      label: '拍间状态',
      render: acStatusObj[datas.status] || '-',
    },
    {
      label: '备注信息',
      render: datas.refuseReason,
    },
    {
      label: '开拍时间',
      render: toDateTime(datas.startTime),
    },
    {
      label: '结束时间',
      render: toDateTime(datas.endTime),
    },
    {
      label: '创建时间',
      render: toDateTime(datas.createdAt),
    },
  ];

  const renderGoods = {
    dataSource: datas.Goods,
    columns: [
      {
        title: '拍品编号',
        dataIndex: 'id',
      },
      {
        title: '拍品排期编号',
        dataIndex: 'GoodsAuctionHouse',
        render: e => e.id || '-',
      },
      {
        title: '拍品图片',
        dataIndex: 'thumb',
        render: e => <Thumbnails images={[e]} center />,
      },
      {
        title: '拍品名称',
        dataIndex: 'name',
      },
      {
        title: '拍品类型',
        dataIndex: 'type',
        render: e => typeObj[e],
      },
      {
        title: '拍品状态',
        dataIndex: 'status',
        render: e => statusObj[e],
      },
      {
        title: '拍品标签',
        dataIndex: 'Labels',
        render: e => e.map(i => `【${i.name}】`).join(''),
      },
      {
        title: '所属分类',
        dataIndex: 'property',
        ellipsis: true,
        render: e => toSpliceStr(parseJSON(e) || {}, '>'),
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
      {
        title: '其它信息',
        flex: 'right',
        render: e => (
          <Operation
            disabled={{
              noOffer: true,
            }}
            hideMenu={{
              noOffer: !!e.GoodsOffers.length,
              offerInfo: !e.GoodsOffers.length,
              update: ![20, 100].includes(e.status),
            }}
            listData={{
              auctionInfo: () => {
                const { GoodsSchedules = [], GoodsAuctionHouse = {} } = e;
                const infos = GoodsSchedules.find(i => i.id === GoodsAuctionHouse.goodsScheduleId);
                setGoodsAuction(infos || {});
              },
              merchantInfo: () => setMerchant(e.Merchant),
              offerInfo: () => setOfferList(e.GoodsOffers || []),
              noOffer: () => {},
              update: () => {
                Modal.confirm({
                  icon: null,
                  title: '更改排期时间',
                  content: (
                    <DatePicker.RangePicker
                      showTime
                      locale={locale}
                      onChange={e => (times.current = e)}
                    />
                  ),
                  onOk: () => handleUpdateTime(ID),
                });
              },
            }}
          />
        ),
      },
    ],
  };

  const handleUpdateTime = id => {
    if (!times || !times.current) {
      return message.warning('时间未输入');
    }
    const [s, e] = times.current;
    auctionHouseUpdate({
      body: {
        id,
        startTime: toDateTime(s),
        endTime: toDateTime(e),
      },
    }).then(() => {
      message.success('修改成功');
      handleGetData();
    });
  };

  const offerColumns = [
    {
      width: 100,
      align: 'center',
      title: '用户编号',
      dataIndex: 'userId',
    },
    {
      width: 100,
      align: 'center',
      title: '拍品排期编号',
      dataIndex: 'goodsScheduleId',
    },
    {
      width: 100,
      align: 'center',
      title: '出价价格',
      dataIndex: 'offer',
      render: e => calcPrice(e),
    },
    {
      width: 100,
      align: 'center',
      title: '出价状态',
      dataIndex: 'status',
      render: e => statusObjs[e],
    },
    {
      width: 100,
      align: 'center',
      title: '出价时间',
      dataIndex: 'createdAt',
      render: e => toDateTime(e),
    },
  ];

  const renderGoodsAuction = [
    {
      label: '编号',
      render: goodsAuction.id,
    },
    {
      label: '类型',
      render: typeObj[goodsAuction.type],
    },
    {
      label: '加价幅度',
      render: goodsAuction.addPriceRange,
    },
    {
      label: '保证金比例',
      render: goodsAuction.bondPro,
    },
    {
      label: '起始价',
      render: calcPrice(goodsAuction.startPrice),
    },
    {
      label: '当前价',
      render: calcPrice(goodsAuction.currentPrice),
    },
    {
      label: '最终价',
      render: calcPrice(goodsAuction.finalPrice),
    },
    {
      label: '开始时间',
      render: toDateTime(goodsAuction.startTime),
    },
    {
      label: '结束时间',
      render: toDateTime(goodsAuction.endTime),
    },
    {
      label: '创建时间',
      render: toDateTime(goodsAuction.createdAt),
    },
  ];

  const renderMerchant = [
    {
      label: '店铺名称',
      render: merchant.title,
    },
    {
      label: '店铺LOGO',
      render: <Thumbnails images={[merchant.shopIcon]} />,
    },
    {
      label: '联系方式',
      render: merchant.contact,
    },
    {
      label: '店铺地址',
      render: merchant.address,
    },
  ];

  return (
    <>
      <HeaderTitle />
      <Card>
        <CardItem title={'基本信息'} datas={renderInfo} />
        <CardItem title={'拍品信息'} datas={renderGoods} />
      </Card>
      <Modal
        width={880}
        footer={null}
        title="出价信息"
        visible={!!offerList.length}
        onCancel={() => setOfferList([])}
      >
        <Table
          bordered
          size="small"
          pagination={false}
          rowKey={row => row.id}
          columns={offerColumns}
          dataSource={offerList}
        />
      </Modal>
      <Modal
        width={600}
        footer={null}
        closable={false}
        destroyOnClose
        visible={notEmptyObj(merchant)}
        onCancel={() => setMerchant({})}
      >
        <CardItem title={'商家信息'} datas={renderMerchant} />
      </Modal>
      <Modal
        width={600}
        footer={null}
        closable={false}
        destroyOnClose
        visible={notEmptyObj(goodsAuction)}
        onCancel={() => setGoodsAuction({})}
      >
        <CardItem title={'竞拍排期'} datas={renderGoodsAuction} />
      </Modal>
    </>
  );
}

export default GoodsBeatDetail;
