import { CopyText, HeaderTitle } from '@/components';
import { Table } from 'antd';
import React from 'react';

function SystemLink() {
  const columns = [
    {
      width: 100,
      title: '位置',
      align: 'center',
      dataIndex: 'title',
    },
    {
      width: 150,
      title: '链接（点击可复制）',
      align: 'center',
      dataIndex: 'link',
      render: e => {
        const url = `/${e}`;
        return <CopyText text={url} link />;
      },
    },
    {
      width: 100,
      title: '备注',
      align: 'center',
      dataIndex: 'extra',
      render: e => e || '-',
    },
  ];

  const data = [
    {
      title: '竞拍商品详情',
      link: 'productdetail?id=xxx',
      extra: '竞拍商品详情的id属性',
    },
    {
      title: '认购商品详情',
      link: 'subscribedetail?id=xxx',
      extra: '认购商品详情的id属性',
    },
    {
      title: '竞拍商品列表',
      link: 'productlist?id=xx',
      extra: '商品二级分类的id属性',
    },
    {
      title: '认购商品列表',
      link: 'subscribelist',
    },
    {
      title: '店铺中心',
      link: 'shopcenter?id=xxx',
      extra: '店铺的id属性',
    },
    {
      title: '大众拍间',
      link: 'auctionRoomPublic',
    },
  ];

  return (
    <>
      <HeaderTitle />
      <Table
        bordered
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{ width: '1000px' }}
        rowKey={({ link }) => link}
      />
    </>
  );
}

export default SystemLink;
