import { postUserUpdate } from '@/api';
import { FormModal, UploadImage } from '@/components';
import { st_user } from '@/utils/statetype';
import { notEmptyObj } from '@/utils/utils';
import { Input, message, Radio } from 'antd';
import React from 'react';

const { sexObj } = st_user;
function UserEdit({ datas, onChange }) {
  const sexOption = Object.keys(sexObj).map(i => ({
    value: Number(i),
    label: sexObj[i],
  }));

  const formData = [
    {
      name: 'avatarUrl',
      label: '用户头像',
      rules: [{ required: true, message: '请选择用户头像' }],
      render: <UploadImage model="single" callBackString />,
    },
    {
      name: 'nickname',
      label: '用户昵称',
      rules: [{ required: true, message: '请输入用户昵称' }],
      render: <Input placeholder="请输入用户昵称" />,
    },
    {
      name: 'phone',
      label: '手机号码',
      rules: [{ required: true, message: '请输入手机号码' }],
      render: <Input placeholder="请输入手机号码" />,
    },
    {
      name: 'sex',
      label: '用户性别',
      rules: [{ required: true, message: '请选择用户性别' }],
      render: <Radio.Group options={sexOption} />,
    },
  ];

  const handleSubmit = params => {
    if (!params) return onChange();
    postUserUpdate({
      body: {
        id: datas.id,
        ...params,
      },
    }).then(() => {
      message.success('修改成功');
      onChange(true);
    });
  };

  return (
    <FormModal
      title="用户信息"
      visible={!!notEmptyObj(datas)}
      formData={formData}
      initialValues={datas}
      onChange={handleSubmit}
    />
  );
}

export default UserEdit;
