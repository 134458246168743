import { Button, Col, Empty, Form, message, Modal } from 'antd';
import React, { Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import IconFont from '../IconFont';
import styles from './index.module.scss';

function FormModal({
  fmRef,
  title,
  visible,
  formData,
  width = 660,
  coverTitle = null,
  formCol = {},
  initialValues,
  onChange,
  onError,
  ...config
}) {
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (!visible && load) {
      setLoad(false);
    } else if (!!formRef.current) {
      form.resetFields();
    }
  }, [visible, formRef]);

  useImperativeHandle(fmRef, () => ({
    onErrorFn: err => {
      setLoad(false);
      if (typeof err === 'string') {
        message.warning(err);
      }
    },
  }));

  const renderButton = () => (
    <Col className={styles.colBtn}>
      <Button type="primary" loading={load} htmlType="submit">
        提交
      </Button>
      <Button onClick={() => onChange(null)}>取消</Button>
    </Col>
  );

  const flag = initialValues && Object.keys(initialValues).length ? '编辑' : '新增';
  // destroyOnClose 针对纯预览模式Modal需加，针对modal存在form表单无需加
  return (
    <Modal
      footer={null}
      width={width}
      visible={visible}
      maskClosable={false}
      style={{ top: '10vh' }}
      title={title ? flag + title : coverTitle}
      bodyStyle={{
        maxHeight: '78vh',
        overflowY: 'auto',
        padding: title ? '36px 24px' : '56px 24px 36px',
      }}
      onCancel={() => onChange(null)}
      {...config}
    >
      {Array.isArray(formData) && formData.length ? (
        <Form
          form={form}
          ref={formRef}
          preserve={false}
          scrollToFirstError={true}
          labelCol={{ span: formCol.label || 6 }}
          wrapperCol={{ span: formCol.wrapper || 14 }}
          className={styles.formModal}
          initialValues={initialValues}
          onFinish={event => {
            setLoad(true);
            const resEvent = {};
            Object.keys(event).forEach(i => {
              if (event[i] !== undefined) resEvent[i] = event[i];
            });
            onChange(resEvent);
          }}
          onFinishFailed={event => {
            onError && onError(event);
          }}
        >
          {formData
            .filter(i => i)
            .map(({ name, render, helps, extend, ...cfgs }) => {
              return !extend ? (
                <Form.Item
                  key={name}
                  name={name}
                  tooltip={
                    helps
                      ? {
                          title: helps,
                          icon: (
                            <IconFont
                              type="question-circle"
                              style={{
                                color: '#888',
                                cursor: 'pointer',
                              }}
                            />
                          ),
                        }
                      : null
                  }
                  {...cfgs}
                >
                  {render}
                </Form.Item>
              ) : (
                <Fragment key={name}>{render}</Fragment>
              );
            })}
          {renderButton()}
        </Form>
      ) : (
        <div className={styles.empty}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </Modal>
  );
}

export default FormModal;
