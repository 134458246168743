import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.scss';

ReactDOM.render(
  <BrowserRouter basename="/">
    <Route path={`/`} component={App}></Route>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
