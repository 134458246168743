export const st_order = {
  statusObj: {
    10: '待付款',
    20: '已支付',
    30: '待发货',
    40: '已发货',
    50: '用户认购转卖',
    100: '已完成',
    101: '已完成（转拍）',
    102: '认购已转拍成功',
    '-1': '已取消/已违约',
    '-100': '已关闭',
  },
  typeObj: {
    20: '竞拍订单',
    25: '标品竞拍',
    30: '转拍订单',
    40: '认购订单',
  },
  // typeObj: { // 备注使用
  //   10: '充值订单', // 财务管理
  //   20: '竞拍订单',
  //   25: '标品区竞拍',
  //   30: '用户竞拍',
  //   40: '认购订单',
  //   50: '授权服务费', // 财务管理
  // },
  payType: {
    wxApp: '微信APP',
    balance: '余额支付',
  },
};

export const st_goods = {
  statusObj: {
    '-100': '已删除',
    '-20': '已下架',
    '-10': '审核失败',
    0: '待审核',
    10: '待上架',
    20: '待开拍',
    100: '拍卖中/认购中/转拍中',
    110: '已拍得/认购转拍成功',
    120: '已流拍',
  },
  // typeObj: {
  //   1: '拍卖',
  //   2: '认购',
  //   3: '标品区',
  //   4: '认购转拍',
  //   5: '个人发售',
  // },
  typeObj: {
    10: '商家拍卖',
    20: '认购',
    30: '认购转拍',
  },
  kindObj: {
    // 10: '个品拍卖',
    // 20: '标品拍卖',
    10: '个品',
    20: '标品',
  },
};

export const st_shop = {
  statusObj: {
    1: '待审核',
    2: '已通过',
    3: '已拒绝',
  },
  typeObj: {
    1: '普通企业',
    2: '个体工商户',
  },
  levelObj: {
    11: '普通店铺',
    21: '孵化中心',
    31: '孵化基地',
  },
};

export const st_user = {
  levelObj: {
    11: '普通',
    21: '市场运营服务机构',
  },
  sexObj: {
    0: '保密',
    1: '男',
    2: '女',
  },
  statusObj: {
    1: '出价成功',
    '-1': '出局',
  },
};

export const st_bill = {
  typeObj: {
    '-1': '支出',
    0: '收益中',
    1: '收入',
  },
  kindObj: {
    recharge: '充值',
    testUser: '收入',
    subscriptionBuy: '认购商品购买',
    subscriptionFee: '商家认购手续费',
    extrctCashfail: '提现失败退回金额',
    noAuctionReward: '未拍到奖励',
    noAuctionShareReward: '未拍到分享奖励',
    merchantUpgradeBase: '邀请商铺升级孵化基地奖励',
    merchantUpgradeCenter: '邀请商铺升级孵化中心奖励',
    freezeBond: '竞拍商品冻结保证金',
    unfreezeBond: '竞拍商品解冻保证金',
    userUpgrade: '邀请用户升级市场运营服务机构奖励',
    userExtractCash: '用户余额提现',
    merchantExtractCash: '商家提现',
    orderFinalPAY: '订单尾款支付',
    auctionGetSubscription: '竞拍成功获得认购额',
    userPremiumIncome: '会员竞拍差价收入',
    merchantPremiumIncome: '商铺竞拍差价收入',
    merchantGoodsIncome: '商铺商品售卖收入',
    listingFee: '拍品挂牌费',
    manageFee: '中心管理费',
    earnestMoney: '授权服务费',
    systemPremiumIncome: '平台溢价收入',
    merchantBaseOwn: '拍品为基地自有，基地获得拍品差价的10%',
    serverFee: '服务费',
    baseRelationUser: '孵化基地关联会员收益',
    partnertRelationUser: '市场运营服务机构关联会员收益',
    merchantApplyAward: '下级申请店铺成功奖励',
    auctionGoods: '拍品竞拍成功收入',
  },
  sourceObj: {
    noLink: '不跳转',
    goodsSchedule: '拍品排期详情',
    userInvite: '下级列表',
    auctionHouse: '用户拍间列表',
    userBill: '用户账单列表',
    merchantBill: '店铺账单列表',
    merchantGoods: '商铺拍品列表',
    merchantDetail: '商铺申请详情',
    userOrderDetail: '用户订单详情',
    userDeliveryDetail: '用户物流详情',
    merchantDeliveryDetail: '商铺物流详情',
  },
};

export const st_banner = {
  typeObj: {
    goods: '拍品',
    merchat: '商铺',
    auctionHouse: '拍间',
    link: '外链',
    other: '其它',
  },
  positionObj: {
    home: '首页',
    auction: '拍卖行',
    auctionHouse: '拍间',
  },
};

export const st_label = {
  typeObj: {
    goods: '拍品',
    merchat: '商铺',
  },
};

export const st_cash = {
  statusObj: {
    0: '待审核',
    1: '已通过',
    2: '未通过',
    3: '打款成功',
  },
  typeObj: {
    1: '用户提现',
    2: '商家提现',
  },
  wayObj: {
    wx: '微信提现',
    bank: '银行卡提现',
    ali: '支付宝提现',
  },
};

export const st_logs = {
  eventObj: {
    merchant: '店铺模块',
    category: '分类模块',
    goods: '拍品模块',
    auctionhouse: '拍间模块',
    order: '订单模块',
    user: '用户模块',
    label: '标签模块',
    banner: '轮播图模块',
    system: '系统模块',
    extrctcash: '提现模块',
    rbac: '角色访问控制',
  },
  actionObj: {
    create: '新增/添加',
    update: '编辑/修改',
    delete: '删除/删减',
    updateadmin: '修改账户',
    createrole: '创建角色',
    addpermission: '添加权限',
    deleterole: '删除角色',
  },
  infosObj: {
    id: '编号',
    status: '状态',
    remark: '备注',
    refuseReason: '拒绝原因',
    image: '图片',
    username: '账户名称',
    name: '名称',
    val: '数值',
    nums: '数量',
    iconUrl: '图片路径',
    autoCancelTime: '自动取消时间',
    parentId: '上级编号',
    password: '密码',
    roles: '角色',
    level: '等级',
    operateType: '操作类型',
    roleName: '角色名称',
    openRobot: '开启机器人',
    shopLevel: '店铺等级',
  },
};

export const st_auction = {
  statusObj: {
    '-20': '已下架',
    '-10': '审核失败',
    0: '审核中',
    10: '审核成功',
    20: '等待开始',
    100: '竞拍中',
    110: '竞拍结束',
  },
};

export const st_profit = {
  statusObj: {
    0: '收益中',
    10: '待打款',
    20: '已打款',
  },
  typeObj: {
    1: '市场运营服务机构',
    2: '孵化基地',
  },
};
