import React from 'react';
import { Button, Modal } from 'antd';
import { useCallback, useMemo, useReducer, useRef, useEffect, useState } from 'react';
import { defaultFooter, defaultTable, formatData, matchKeep, mergeData } from './relyon';
import { useUpdateEffect } from '@/hooks';
import { IconFont } from '@/components';
import styles from './index.module.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateState':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

export function useTable({
  API,
  footer,
  columns,
  params = {},
  isInit = true, // 初始化加载
  isPage = true, // 初始化分页
  isKeep = false, // 数据缓存
  coverKey = {}, // 覆盖默认对象
  choiceKey = 'id', // 选择KEY
  choiceType = '', // 单/多选
}) {
  const count = useRef(0);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const initState = useMemo(() => defaultTable, []);
  const [state, dispatch] = useReducer(reducer, initState);

  // 刷新表格数据
  const refresh = useCallback(
    type => {
      const init = type === 'init' ? { current: 1 } : {};
      dispatch({
        type: 'updateState',
        payload: {
          ...init,
          count: state.count + 1,
        },
      });
    },
    [state.count]
  );

  // 表格变化刷新数据
  const changeTable = useCallback(
    ({ current = 1, pageSize = 10 }) => {
      dispatch({
        type: 'updateState',
        payload: {
          current,
          pageSize,
          count: state.count + 1,
        },
      });
    },
    [state.count]
  );

  /* 初始化执行 */
  useEffect(() => {
    if (isInit && matchKeep(isKeep)) {
      changeTable(page);
    } else if (isInit) {
      refresh();
    } else {
      setLoading(false);
    }
  }, []);

  // 重新加载
  const flagFn = useCallback(
    status => {
      if (status && matchKeep(isKeep) && !state.count) {
        changeTable(page);
      } else if (status) {
        refresh('init');
      } else {
        refresh();
      }
    },
    [state.count]
  );

  const makeCancelable = apiFn => {
    const runCount = count.current;
    const wrappedPromise = new Promise((resolve, reject) =>
      apiFn
        .then(val => (runCount !== count.current ? null : resolve(val)))
        .catch(error => (runCount !== count.current ? null : reject(error)))
        .finally(() => setLoading(false))
    );
    return wrappedPromise;
  };

  const callApi = () => {
    if (typeof API !== 'function') return;
    setLoading(true);
    const resultParam = isPage ? mergeData(params, coverKey, state) : params;
    const promise = makeCancelable(API(resultParam));
    return promise.then(data => {
      const payload = formatData(data, coverKey);
      if (isKeep) saveKeep(payload.total, state);
      dispatch({
        type: 'updateState',
        payload: {
          ...payload,
          apiData: data,
        },
      });
    });
  };

  useUpdateEffect(() => callApi(), [state.current, state.pageSize, state.count]);

  const renderConfirm = key => {
    Modal.confirm({
      title: `确认${defaultFooter[key]}选中的数据？`,
      icon: <IconFont type="exclamation-circle" />,
      content: '（请仔细核对数据，确认无误后再操作）',
      okType: key === 'delete' ? 'danger' : 'primary',
      okText: defaultFooter[key],
      onOk: () => {
        if (typeof footer[key] === 'function') {
          setSelectedRowKeys([]);
          footer[key](selectedRowKeys, key);
        }
      },
    });
  };

  const renderFooter = () => (
    <div className={styles.footerBox}>
      {Object.keys(footer)
        .filter(i => !!defaultFooter[i])
        .map(i => (
          <Button
            key={i}
            type="primary"
            danger={i === 'delete'}
            disabled={defaultFooter[i].rely ? !selectedRowKeys.length : false}
            onClick={() => {
              if (defaultFooter[i].modal) {
                renderConfirm(i);
              } else {
                footer[i](state.apiData || {});
              }
            }}
          >
            {defaultFooter[i].title}
          </Button>
        ))}
    </div>
  );

  const handleColumns = () => {
    if (!columns) return [];
    const width = 100;
    const align = 'center';
    const render = event => (typeof event === 'number' ? event : event || '-');
    let resultColumns = [];
    if (Array.isArray(columns)) {
      resultColumns = columns.map(i => ({
        width,
        align,
        ...i,
        render: i.render || render,
      }));
    } else {
      resultColumns = Object.keys(columns).map(i => {
        const indexObj = i.indexOf('NOINDEX') === -1 ? { dataIndex: i } : {};
        const columnsObj =
          columns[i] && typeof columns[i] === 'object' ? columns[i] : { title: columns[i] };
        return {
          width,
          align,
          ...indexObj,
          ...columnsObj,
          render: columns[i].render || render,
        };
      });
    }
    return resultColumns;
  };

  const rowSelection = {
    type: choiceType,
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const pagination = {
    current: state.current,
    pageSize: state.pageSize,
    total: state.total,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: () => `共${state.total || 0}条数据`,
  };

  const tableProps = {
    loading,
    size: 'small',
    bordered: true,
    className: styles.table_bodys,
    dataSource: state.data,
    columns: handleColumns(),
    onChange: changeTable,
    scroll: state.data.length ? { x: 800 } : {},
    rowKey: row => row[choiceKey],
    rowSelection: choiceType ? rowSelection : null,
    pagination: isPage ? pagination : false,
    footer: footer ? renderFooter : null,
  };

  return {
    tableProps,
    apiData: state.apiData,
    selectedRowKeys,
    flagFn,
  };
}

export default useTable;
