import { statisticsCash } from '@/api';
import { Card, Row, Space } from 'antd';
import { HeaderTitle, Buffer } from '@/components';
import React, { useState, useEffect } from 'react';
import { calcPrice } from '@/utils/utils';

const h2Sty = {
  fontSize: '16px',
  padding: '8px 0 10px',
};
function FinanceTranData() {
  const [load, setLoad] = useState(true);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    statisticsCash().then(res => {
      const resData = [
        [
          ['平台总收益', calcPrice(res.allIncome), '#c18b3b'],
          ['平台今日总收益', calcPrice(res.todayIncome), '#67b36a'],
          ['平台总支出', calcPrice(res.allExpenditure), '#89C3F8'],
          ['平台今日总支出', calcPrice(res.todayExpenditure), '#F97186'],
          ['充值总金额', calcPrice(res.allRechanrge), '#8693F3'],
          ['今日充值总金额', calcPrice(res.todayRechanrge), '#FFA897'],
          ['授权服务费总金额', calcPrice(res.earnestMoney), '#42acea'],
          ['违约总金额', calcPrice(res.allCancelAmount), '#42cbea'],
          ['今日违约总金额', calcPrice(res.todayCancelAmount), '#f39a5b'],
        ],
        [
          ['商家总余额', calcPrice(res.merchantAllBalance), '#89C3F8'],
          ['商家提现总金额', calcPrice(res.allMerchantExtractCash), '#8693F3'],
          ['商家今日提现总金额', calcPrice(res.todayMerchantExtractCash), '#FFA897'],
        ],
        [
          ['用户总余额', calcPrice(res.userAllBalance), '#89C3F8'],
          ['机器人用户总余额', calcPrice(res.botUsersTotal), '#89C3F8'],
          ['用户保证金总金额', calcPrice(res.userAllFrozen), '#F97186'],
          ['用户总认购额', calcPrice(res.userAllsubscriptionAmount), '#42acea'],
          ['用户总提现金额', calcPrice(res.allUserExtractCash), '#8693F3'],
          ['用户今日提现金额', calcPrice(res.todayUserExtractCash), '#FFA897'],
        ],
      ];
      setDatas(resData);
      setLoad(false);
    });
  }, []);

  const renderCardItem = (lists = []) => {
    const listDom = lists.map((i, k) => {
      const [title, value, color] = i || [];
      return (
        <Card
          key={k}
          style={{
            width: '220px',
            height: '86px',
            background: color,
            color: '#fff',
            borderRadius: '6px',
            marginBottom: '16px',
          }}
          bodyStyle={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Row style={{ fontSize: '12px', marginBottom: '12px' }}>{title}</Row>
          <Row style={{ fontSize: '20px', fontWeight: 'bold' }}>¥ {value}</Row>
        </Card>
      );
    });
    return <Space style={{ flexWrap: 'wrap' }}>{listDom}</Space>;
  };

  if (load) return <Buffer />;

  return (
    <>
      <HeaderTitle />
      <h2 style={h2Sty}>平台交易数据</h2>
      {renderCardItem(datas[0])}
      <h2 style={h2Sty}>商家交易数据</h2>
      {renderCardItem(datas[1])}
      <h2 style={h2Sty}>用户交易数据</h2>
      {renderCardItem(datas[2])}
    </>
  );
}

export default FinanceTranData;
