import { FormList, HeaderTitle, Operation, Thumbnails } from '@/components';
import { getBannerList, postBannerDelete } from '@/api';
import { st_banner } from '@/utils/statetype';
import { toDateTime } from '@/utils/utils';
import { Button, message, Table } from 'antd';
import React, { useState } from 'react';
import { useTable } from '@/hooks';
import moment from 'moment';
import BannderEdit from './components/BannerEdit';

const { positionObj, typeObj } = st_banner;
function ConfigBanner() {
  const [query, setQuery] = useState({});
  const [datas, setDatas] = useState({});
  const [visible, setVisible] = useState(false);

  const columns = {
    id: '展示编号',
    image: {
      title: '展示图片',
      render: e => <Thumbnails images={[e]} center />,
    },
    position: {
      title: '展示位置',
      render: e => positionObj[e] || '-',
    },
    type: {
      title: '展示类型',
      render: e => typeObj[e] || '-',
    },
    sort: '展示排序',
    goto: '跳转路径',
    NOINDEX_times: {
      title: '展示时间',
      render: e => `${toDateTime(e.startTime)} ~ ${toDateTime(e.endTime)}`,
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          listData={{
            update: () => {
              const { startTime = new Date(), endTime = new Date(), ...prop } = e;
              setDatas({
                times: [moment(startTime), moment(endTime)],
                ...prop,
              });
              setVisible(true);
            },
            delete: () => {
              postBannerDelete({
                body: {
                  id: e.id,
                },
              }).then(() => {
                message.success('删除成功');
                flagFn(true);
              });
            },
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getBannerList,
    isInit: false,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'position',
      label: '展示位置',
      type: 'select',
      selects: positionObj,
    },
    {
      id: 'type',
      label: '展示类型',
      type: 'select',
      selects: typeObj,
    },
  ];

  const changeQuery = (value = {}) => {
    setQuery(value);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList
        formData={formData}
        changeQuery={changeQuery}
        extraRender={
          <Button type="primary" disabled={!global.isOperable} onClick={() => setVisible(true)}>
            添加
          </Button>
        }
      />
      <Table {...tableProps} />
      <BannderEdit
        data={datas}
        visible={visible}
        onChange={res => {
          if (res) flagFn();
          setVisible(false);
          setDatas({});
        }}
      />
    </>
  );
}

export default ConfigBanner;
