import React from "react";

function Home() {
  return (
    <div
      style={{
        textAlign: 'center',
        paddingTop: '35vh',
        fontSize: '32px',
        userSelect: 'none'
      }}
    >
      欢迎进入管理后台
    </div>
  );
}

export default Home;
