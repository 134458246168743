import React, { useEffect, useState, useMemo } from 'react';
import { Table, message, Tree, Col, Modal, Spin, Input } from 'antd';
import {
  addPermissionForUser,
  deletePermissionForUser,
  createRole,
  deleteRole,
  getAllRoles,
} from '@/api';
import { Operation, HeaderTitle } from '@/components';
import { WHITE_ROLES } from '@/utils/constants';
import allRoutes from '@/router/routes';

const deepGenerateTree = (routes, flag, index = 1) => {
  const routeArr = routes.filter(i => {
    const provFlag = !WHITE_ROLES.includes(i.path) && (i.layout || flag);
    return provFlag;
  });
  const resultArr = routeArr.map(i => {
    const Obj = {
      key: i.path,
      title: i.title,
    };
    // 仅限一二级菜单
    if (i.routes && index < 2) {
      Obj.children = deepGenerateTree(i.routes, true, index + 1);
    }
    return Obj;
  });
  return resultArr;
};

function SystemPower() {
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalLoad, setModalLoad] = useState(false);

  const [roleKey, setRoleKey] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const routesList = JSON.parse(JSON.stringify(allRoutes));
  const formatRoutes = useMemo(() => deepGenerateTree(routesList), [routesList]);

  const fetchGetRoles = () => {
    setLoading(true);
    getAllRoles()
      .then(res => {
        const resArr = res.map((i, k) => {
          const [title, value] = Object.entries(i)[0] || [];
          const roles = value.map(j => j[2]);
          const remarks = value.map(j => `【${j[3]}】`);
          return {
            key: k,
            title,
            roles,
            remarks: remarks.join('') || '-',
          };
        });
        setListData(resArr);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchGetRoles();
  }, []);

  const handleUpdate = async () => {
    try {
      const { roles = [] } = data;
      const addRole = roleKey
        .filter(i => !roles.includes(i))
        .map(i => ({ v2: i, v3: global.allRole[i] }));
      const delRole = roles
        .filter(i => !roleKey.includes(i))
        .map(i => ({ v2: i, v3: global.allRole[i] }));

      if (!data.title && !name) {
        message.warning('请输入新角色名称');
        return;
      }
      if (addRole.length && data.title) {
        await addPermissionForUser({
          body: {
            roleName: data.title,
            permissions: addRole,
          },
        });
      }
      if (delRole.length) {
        await deletePermissionForUser({
          body: {
            roleName: data.title,
            permissions: delRole,
          },
        });
      }
      if (!data.title) {
        await createRole({
          body: {
            roleName: name,
            permissions: addRole,
          },
        });
      }
      message.success('操作成功');
      setRoleKey([]);
      setData({});
      setVisible(false);
      setModalLoad(false);
      await fetchGetRoles();
    } catch (error) {
      message.error(error.message);
      setModalLoad(false);
    }
  };

  const columns = [
    {
      title: '角色名称',
      width: 100,
      align: 'center',
      dataIndex: 'title',
      render: e => e || '-',
    },
    // {
    //   title: '角色权限',
    //   width: 500,
    //   align: 'center',
    //   dataIndex: 'remarks',
    //   ellipsis: true,
    //   render: e => e || '-',
    // },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: record => {
        return (
          <Operation
            disabled={{
              power: record.title === '超级管理员',
              delete: record.title === '超级管理员',
            }}
            listData={{
              power: () => {
                setData(record);
                setRoleKey(record.roles);
                setVisible(true);
              },
              delete: () => {
                deleteRole({
                  body: {
                    roleName: record.title,
                  },
                }).then(() => {
                  message.success('删除成功');
                  fetchGetRoles();
                });
              },
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <HeaderTitle title={'创建'} disabled={!global.isOperable} onClick={() => setVisible(true)} />
      <Table
        bordered
        size="small"
        loading={loading}
        pagination={false}
        columns={columns}
        dataSource={listData}
      />
      <Modal
        title={data.title || '创建新角色'}
        visible={visible}
        loading={modalLoad}
        onCancel={() => {
          setData({});
          setRoleKey([]);
          setVisible(false);
        }}
        onOk={() => handleUpdate()}
      >
        <Spin spinning={modalLoad} style={{ padding: '20px' }}>
          {!data.title && (
            <Col style={{ display: 'flex', marginBottom: '16px' }}>
              <div style={{ margin: '0 10px 0 75px', lineHeight: '32px' }}>角色名称:</div>
              <Input
                style={{ width: '230px' }}
                placeholder="请输入新角色名称"
                onChange={e => setName(e.target.value.trim())}
              />
            </Col>
          )}
          <Col style={{ display: 'flex' }}>
            <div style={{ margin: '0 10px 0 75px', lineHeight: '32px' }}>权限列表:</div>
            <Tree
              checkable
              checkedKeys={roleKey}
              treeData={formatRoutes}
              expandedKeys={expanded}
              onCheck={val => setRoleKey(val)}
              onExpand={(_, event) => {
                const { node = {} } = event;
                const { key = '' } = node;
                const result = expanded.filter(i => i !== key);
                if (result.length === expanded.length) {
                  result.push(key);
                }
                setExpanded(result);
              }}
              onSelect={(_, event) => {
                const { node = {} } = event;
                const { key = '', children } = node;
                if (children) {
                  const result = expanded.filter(i => i !== key);
                  if (result.length === expanded.length) {
                    result.push(key);
                  }
                  setExpanded(result);
                } else {
                  const result = roleKey.filter(i => i !== key);
                  if (result.length === roleKey.length) {
                    result.push(key);
                  }
                  setRoleKey(result);
                }
              }}
            />
          </Col>
        </Spin>
      </Modal>
    </>
  );
}

export default SystemPower;
