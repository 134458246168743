import { HeaderTitle, Operation, Thumbnails } from '@/components';
import { st_shop, st_user } from '@/utils/statetype';
import { useHistory } from 'react-router-dom';
import { relationUserList } from '@/api';
import { calcPrice } from '@/utils/utils';
import { useTable } from '@/hooks';
import { Table } from 'antd';
import React from 'react';

const { levelObj } = st_user;
const { levelObj: levelObjs } = st_shop;
function FinanceProfit() {
  const history = useHistory();

  const columns = {
    id: '编号',
    nickname: '用户名称',
    phone: '联系方式',
    avatarUrl: {
      title: '用户头像',
      render: e => <Thumbnails images={[e]} center />,
    },
    level: {
      title: '用户身份',
      render: e => levelObj[e],
    },
    NOINDEX_id: {
      title: '店铺编号',
      render: e => {
        const Merchant = e.Merchant || {};
        return Merchant.id || '-';
      },
    },
    NOINDEX_name: {
      title: '店铺名称',
      render: e => {
        const Merchant = e.Merchant || {};
        return Merchant.title || '-';
      },
    },
    NOINDEX_logo: {
      title: '店铺LOGO',
      render: e => {
        const Merchant = e.Merchant || {};
        return <Thumbnails images={[Merchant.shopIcon]} center />;
      },
    },
    NOINDEX_level: {
      title: '店铺身份',
      render: e => {
        const Merchant = e.Merchant || {};
        return levelObjs[Merchant.shopLevel] || '-';
      },
    },
    allIncome: {
      title: '总收益',
      render: e => calcPrice(e),
    },
    balance: {
      title: '账户余额',
      render: e => calcPrice(e),
    },
    frozenAmount: {
      title: '冻结金额',
      render: e => calcPrice(e),
    },
    subscriptionAmount: {
      title: '总认购额',
      render: e => calcPrice(e),
    },
    relationUserAmount: {
      title: '竞拍差价统计',
      render: e => calcPrice(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          listData={{
            record: () => history.push(`/finance/profit/detail?i=${e.id}`),
            member: () => history.push(`/finance/profit/member?i=${e.id}`),
          }}
        />
      ),
    },
  };

  const { tableProps } = useTable({
    columns,
    API: relationUserList,
  });

  return (
    <>
      <HeaderTitle />
      <Table {...tableProps} />
    </>
  );
}

export default FinanceProfit;
