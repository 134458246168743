import { FormList, HeaderTitle, Thumbnails } from '@/components';
import { calcPrice, toDateTime } from '@/utils/utils';
import { st_order } from '@/utils/statetype';
import { getOrderStore } from '@/api';
import React, { useState } from 'react';
import { useTable } from '@/hooks';
import { Table } from 'antd';

const statusObj = {
  10: '待付款',
  20: '已支付',
  '-1': '已取消',
};
const { payType } = st_order;
function ShopServiceFee() {
  const [query, setQuery] = useState({});

  const columns = {
    id: '编号',
    number: '订单号',
    userId: '用户编号',
    NOINDEX_User: {
      title: '用户名称',
      render: e => {
        const { User = {} } = e;
        return User.nickname || '-';
      },
    },
    User: {
      title: '用户头像',
      render: e => <Thumbnails images={[e.avatarUrl]} center />,
    },
    status: {
      title: '支付状态',
      render: e => statusObj[e],
    },
    shouldPayPrice: {
      title: '应付金额',
      render: e => calcPrice(e),
    },
    payPrice: {
      title: '实付金额',
      render: e => calcPrice(e),
    },
    payType: {
      title: '支付方式',
      render: e => payType[e] || '-',
    },
    payTime: {
      title: '支付时间',
      render: e => toDateTime(e),
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getOrderStore,
    isInit: false,
    params: {
      query: {
        ...query,
        type: 50,
      },
    },
  });

  const formData = [
    {
      id: 'status',
      label: '支付状态',
      type: 'select',
      selects: statusObj,
    },
    {
      id: 'createdAt',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { createdAt, ...props } = value;
    const [start, end] = createdAt || [];
    const q = {
      ...props,
      ...(createdAt && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} />
    </>
  );
}

export default ShopServiceFee;
