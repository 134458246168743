import { parseJSON } from './utils';
import { encode, decode } from 'js-base64';
import { PREFIX, TOKENINFO } from './constants';

export const setItem = (item, value) => {
  const data = encode(JSON.stringify(value));
  const keys = (PREFIX + item).toLocaleUpperCase();
  localStorage.setItem(keys, data);
};

export const getItem = item => {
  const keys = (PREFIX + item).toLocaleUpperCase();
  const data = localStorage.getItem(keys);
  if (data) {
    return parseJSON(decode(data));
  }
  return parseJSON(data) || {};
};

export const delItem = item => {
  const keys = (PREFIX + item).toLocaleUpperCase();
  localStorage.removeItem(keys);
};

export const clearItem = () => {
  localStorage.clear();
};

export const setItems = (item, value) => {
  const data = encode(JSON.stringify(value));
  const keys = (PREFIX + item).toLocaleUpperCase();
  sessionStorage.setItem(keys, data);
};

export const getItems = item => {
  const keys = (PREFIX + item).toLocaleUpperCase();
  const data = sessionStorage.getItem(keys);
  if (data) {
    return parseJSON(decode(data));
  }
  return parseJSON(data) || {};
};

export const delItems = item => {
  const keys = (PREFIX + item).toLocaleUpperCase();
  sessionStorage.removeItem(keys);
};

export const clearItems = () => {
  sessionStorage.clear();
};

export const setToken = token => {
  setItem(TOKENINFO, token);
};

export const getToken = () => {
  return getItem(TOKENINFO);
};

export const delToken = () => {
  delItem(TOKENINFO);
};
