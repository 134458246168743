import { postLabelCreate, postLabelUpdate } from '@/api';
import { FormModal } from '@/components';
import { st_label } from '@/utils/statetype';
import { ergodicObj } from '@/utils/utils';
import { Input, message, Select } from 'antd';
import React from 'react';

const { typeObj } = st_label;
function LabelEdit({ visible, data, onChange }) {
  const handleSubmit = async params => {
    if (!params) return onChange();
    data.id
      ? await postLabelUpdate({
          body: {
            id: data.id,
            ...params,
          },
        })
      : await postLabelCreate({
          body: params,
        });
    message.success('操作成功');
    onChange(true);
  };

  const formData = [
    {
      name: 'name',
      label: '标签名称',
      rules: [{ required: true, message: '请输入标签名称' }],
      render: <Input placeholder="请输入标签名称" />,
    },
    {
      name: 'type',
      label: '标签类型',
      rules: [{ required: true, message: '请选择标签类型' }],
      render: <Select options={ergodicObj(typeObj)} placeholder="请选择标签类型" />,
    },
    {
      name: 'describe',
      label: '标签描述',
      render: <Input placeholder="请输入标签描述" />,
    },
  ];

  return (
    <FormModal
      title="标签"
      visible={visible}
      formData={formData}
      initialValues={data}
      onChange={handleSubmit}
    />
  );
}

export default LabelEdit;
