import { Card, Col, Divider, Empty, Row, Table } from 'antd';
import { random } from 'lodash';
import React from 'react';
import styles from './index.module.scss';

function CardItem({ title, subtitle, datas }) {
  return (
    <>
      {!!title && (
        <Card.Grid className={styles.cardTitle} hoverable={false}>
          <Divider type="vertical" className={styles.cardDivider} />
          {title}
          {!!subtitle && <span className={styles.subSty}>{subtitle}</span>}
        </Card.Grid>
      )}
      {Array.isArray(datas) ? (
        <Card.Grid className={styles.cardInfo} hoverable={false}>
          {datas.length > 0 ? (
            datas.map((i, k) =>
              i.label || i.render ? (
                <Row key={k} className={styles.cardRow} style={i.style || {}}>
                  <Col className={styles.cardColLabel}>{i.label && `${i.label}：`}</Col>
                  <Col className={styles.cardColValue}>
                    {typeof i.render === 'number' ? i.render : i.render || '-'}
                  </Col>
                </Row>
              ) : (
                ''
              )
            )
          ) : (
            <div className={styles.cardEmpty}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Card.Grid>
      ) : !!datas ? (
        <Card.Grid className={styles.cardTable} hoverable={false}>
          <Table
            bordered
            size="small"
            pagination={false}
            {...datas}
            scroll={{ y: 400 }}
            rowKey={() => random(100000, 999999)}
            columns={(datas.columns || []).map(i => ({
              width: 100,
              align: 'center',
              render: e => (e || typeof e === 'number' ? e : '-'),
              ...i,
            }))}
          />
        </Card.Grid>
      ) : (
        <Card.Grid className={styles.cardInfos} hoverable={false}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card.Grid>
      )}
      <Divider style={{ border: 'none' }} />
    </>
  );
}

export default CardItem;
