import React from 'react';
import { Layout } from 'antd';
import PageRoute from '@/router';
import SiderMenu from './SiderMenu';
import styles from './style.module.scss';

const { Sider, Content } = Layout;

function ContentView({ coll }) {
  return (
    <Layout className={styles.layoues}>
      <Sider collapsible theme="dark" trigger={null} collapsed={coll} className={styles.sidersBox}>
        <div className={styles.siders}>
          <SiderMenu />
        </div>
      </Sider>
      <Content className={styles.content}>
        <div className={styles.body}>
          <PageRoute />
        </div>
      </Content>
    </Layout>
  );
}

export default ContentView;
