import { getOrderDetail, postOrderUpdate } from '@/api';
import { calcPrice, getRegionsName, parseJSON, toDateTime } from '@/utils/utils';
import { CardItem, HeaderTitle, Thumbnails } from '@/components';
import React, { useEffect, useRef, useState } from 'react';
import { st_goods, st_order } from '@/utils/statetype';
import { expressList } from '@/utils/brand';
import { Card, Input, message, Modal } from 'antd';

const { statusObj, typeObj } = st_order;
const { typeObj: goodsTypeObj } = st_goods;
function OrderListDetail({ querys }) {
  const { i: ID } = querys;
  let wlnum = useRef(null);
  const [datas, setDatas] = useState({});
  const user = datas.User || {};
  const merchat = datas.Merchant || {};
  const priceDetails = parseJSON(datas.priceDetails) || [];
  const delivery = datas.orderDelivery || {};
  const goods = (datas.OrderGoods || []).map(i => ({ ...i, ...(parseJSON(i.photo) || {}) }));
  const { com, logisticsNumber, result } = delivery;
  const wlName = `【${expressList[com] || com || '---'}】${logisticsNumber}`;
  const { param } = parseJSON(result) || {};
  const { lastResult } = parseJSON(param) || {};
  const { data: wlDetail } = lastResult || {};

  const handleGetData = () => {
    getOrderDetail({
      query: {
        id: Number(ID),
      },
    }).then(res => {
      setDatas(res);
    });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const renderOrder = [
    {
      label: '订单编号',
      render: datas.number,
    },
    {
      label: '订单状态',
      render:
        datas.status === -1
          ? [20, 30].includes(datas.type)
            ? '已违约'
            : '已取消'
          : statusObj[datas.status],
    },
    {
      label: '订单类型',
      render: typeObj[datas.type],
    },
    {
      label: '应付金额',
      render: calcPrice(datas.shouldPayPrice),
    },
    {
      label: '实付金额',
      render: calcPrice(datas.payPrice),
    },
    {
      label: '支付方式',
      render: datas.payType,
    },
    {
      label: '自动取消时间',
      render: toDateTime(datas.autoCancelTime),
    },
    {
      label: '支付时间',
      render: toDateTime(datas.payTime),
    },
    {
      label: '收据时间',
      render: toDateTime(datas.receiptTime),
    },
    {
      label: '发货时间',
      render: toDateTime(datas.sendTime),
    },
    {
      label: '创建时间',
      render: toDateTime(datas.createdAt),
    },
    {
      label: '订单备注',
      render: datas.remark,
    },
  ];

  const renderGoods = {
    columns: [
      {
        title: '拍品编号',
        dataIndex: 'goodsId',
      },
      {
        title: '拍品名称',
        dataIndex: 'goods',
        render: e => e.name,
      },
      {
        title: '拍品图片',
        dataIndex: 'goods',
        render: e => <Thumbnails images={[e.thumb]} center />,
      },
      {
        title: '拍品类型',
        dataIndex: 'goods',
        render: e => goodsTypeObj[e.type],
      },
      {
        title: '购买数量',
        dataIndex: 'nums',
      },
      {
        title: '起拍价格',
        dataIndex: 'goodsSchedule',
        render: e => calcPrice(e?.startPrice),
      },
      {
        title: '最终价格',
        dataIndex: 'goodsSchedule',
        render: e => calcPrice(e?.finalPrice),
      },
      {
        title: '起拍时间',
        dataIndex: 'goodsSchedule',
        render: e => toDateTime(e?.startTime),
      },
      {
        title: '结束时间',
        dataIndex: 'goodsSchedule',
        render: e => toDateTime(e?.endTime),
      },
    ],
    dataSource: goods,
  };

  const renderShop = [
    {
      label: '店铺编号',
      render: merchat.id,
    },
    {
      label: '店铺名称',
      render: merchat.title,
    },
    {
      label: '店铺LOGO',
      render: <Thumbnails images={[merchat.shopIcon]} />,
    },
    {
      label: '店铺法人',
      render: merchat.name,
    },
    {
      label: '联系方式',
      render: merchat.contact,
    },
    {
      label: '联系地址',
      render: merchat.address,
    },
  ];

  const renderUser = [
    {
      label: '用户编号',
      render: user.id,
    },
    {
      label: '用户名称',
      render: user.nickname,
    },
    {
      label: '用户头像',
      render: <Thumbnails images={[user.avatarUrl]} />,
    },
    {
      label: '联系方式',
      render: user.phone,
    },
  ];

  const renderPrice = {
    columns: [
      {
        title: '名称',
        dataIndex: 'name',
      },
      {
        title: '类型',
        dataIndex: 'type',
      },
      {
        title: '金额',
        dataIndex: 'price',
        render: e => calcPrice(e),
      },
    ],
    dataSource: priceDetails,
  };

  const renderDelivery = [
    {
      label: '收货人',
      render: delivery.consignee,
    },
    {
      label: '联系方式',
      render: delivery.phone,
    },
    {
      label: '省市区',
      render: `${getRegionsName([delivery.provinceId, delivery.cityId, delivery.areaId]).join(
        '-'
      )}`,
    },
    {
      label: '详细地址',
      render: delivery.address,
    },
  ];

  const renderLogs = {
    dataSource: datas.OrderLogs,
    columns: [
      {
        title: '编号',
        dataIndex: 'id',
      },
      {
        title: '备注信息',
        dataIndex: 'remark',
      },
      {
        title: '最近修改',
        dataIndex: 'updatedAt',
        render: e => toDateTime(e),
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  const handleUpdatewl = id => {
    if (!wlnum || !wlnum.current) {
      return message.warning('请输入物流单号');
    }
    postOrderUpdate({
      body: {
        id,
        operateType: 3,
        logisticsNumber: wlnum.current,
      },
    }).then(() => {
      message.success('修改成功');
      handleGetData();
    });
  };

  return (
    <>
      <HeaderTitle />
      <Card>
        <CardItem title={'订单信息'} datas={renderOrder} />
        <CardItem title={'店铺信息'} datas={renderShop} />
        <CardItem title={'买家信息'} datas={renderUser} />
        <CardItem title={'拍品信息'} datas={renderGoods} />
        <CardItem title={'收货信息'} datas={renderDelivery} />
        {delivery.logisticsNumber && (
          <CardItem
            title={'物流信息'}
            subtitle={
              <>
                <span>{wlName}</span>
                {global.isOperable && (
                  <span
                    style={{ cursor: 'pointer', marginLeft: '36px' }}
                    onClick={() => {
                      Modal.confirm({
                        icon: null,
                        title: '修改物流单号',
                        content: (
                          <Input
                            placeholder="请输入要修改的物流单号"
                            onChange={e => (wlnum.current = e.target.value)}
                          />
                        ),
                        onOk: () => handleUpdatewl(ID),
                      });
                    }}
                  >
                    修改单号
                  </span>
                )}
              </>
            }
            datas={(wlDetail || []).map(i => ({
              label: i.time,
              style: {
                width: '100%',
              },
              render: i.context,
            }))}
          />
        )}
        <CardItem title={'消费明细'} datas={renderPrice} />
        <CardItem title={'操作日志'} datas={renderLogs} />
      </Card>
    </>
  );
}

export default OrderListDetail;
