import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken } from '@/utils/storage';
import { useUpdateEffect } from '@/hooks';
import { IconFont } from '@/components';
import routes from '@/router/routes';
import { Menu } from 'antd';

const { SubMenu } = Menu;

function SiderMenu() {
  const { roles = [] } = getToken();
  const history = useHistory();
  const routerPath = useLocation();
  const [menu, setMenu] = useState([]);
  const [coll, setColl] = useState(null);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const initMenu = (routeList, flag) => {
    const routeArr = routeList.filter(i => {
      const itselfAdopt = (i.layout && !flag) || flag;
      return itselfAdopt;
    });
    const resultArr = routeArr.map(i => ({
      key: i.path,
      title: i.title,
      icon: i.icon,
      visitShow: i.visitShow || roles.some(j => j.indexOf(i.path) > -1),
      open: window.location.pathname.indexOf(i.path) > -1 && !flag,
      child: i.routes ? initMenu(i.routes, true) : [],
    }));
    return resultArr;
  };

  const handleSK = () => {
    const resPath = routerPath.pathname
      .split('/')
      .filter(i => i)
      .slice(0, 2)
      .join('/');
    return [`/${resPath}`];
  };

  useEffect(() => {
    if (!menu.length) {
      const resMenu = initMenu(routes);
      setMenu(resMenu);
      global.menuList = resMenu;
      return;
    }
    const open = menu.filter(i => i.open).map(i => i.key);
    if (open.length) {
      setOpenKeys(open);
    } else {
      setOpenKeys([]);
    }
    const sk = handleSK();
    setSelectedKeys(sk);
  }, [menu]);

  const onMenuSelect = e => {
    setSelectedKeys([e.key]);
    history.push(e.key);
  };

  const onMenuOpenChange = keys => {
    const currentKey = keys.reverse()[0];
    if (global.coll && global.coll !== coll) {
      setColl(global.coll);
      return;
    }
    if (currentKey) {
      setOpenKeys([currentKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useUpdateEffect(() => {
    const rPath = routerPath.pathname;
    if (rPath !== selectedKeys[0]) {
      const sk = handleSK();
      setSelectedKeys(sk);
      const openKeys = rPath.split('/').filter(i => i)[0];
      openKeys && setOpenKeys([`/${openKeys}`]);
    }
  }, [routerPath]);

  const renderMenu = (menuItems = [], childFlag) => {
    return menuItems.map(i => {
      if (i.child && i.child.length && !childFlag) {
        return (
          <SubMenu
            key={i.key}
            title={i.title}
            style={i.visitShow ? {} : { display: 'none' }}
            icon={<IconFont type={i.icon} />}
          >
            {renderMenu(i.child, true)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item
          key={i.key}
          icon={<IconFont type={i.icon} />}
          style={i.visitShow ? {} : { display: 'none' }}
        >
          {i.title}
        </Menu.Item>
      );
    });
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      onSelect={onMenuSelect}
      onOpenChange={onMenuOpenChange}
    >
      {renderMenu(menu)}
    </Menu>
  );
}

export default SiderMenu;
