import { Input, message, Radio } from 'antd';
import React, { useRef } from 'react';
import FormModal from '../FormModal';

function ExamineModal({
  visible,
  title = '审核',
  data = {},
  status = 'status',
  remark = 'remark',
  API,
  onChange,
}) {
  const fmRef = useRef(null);
  const { s_obj = { 1: '同意', 2: '拒绝' }, id } = data;
  const formData = [
    {
      name: status,
      label: '审核状态',
      rules: [{ required: true, message: '请选择审核状态' }],
      render: (
        <Radio.Group>
          {Object.keys(s_obj).map(i => (
            <Radio key={i} value={Number(i)}>
              {s_obj[i]}
            </Radio>
          ))}
        </Radio.Group>
      ),
    },
    {
      name: remark,
      label: '审核备注',
      render: (
        <Input.TextArea
          rows={5}
          showCount
          allowClear
          maxLength={160}
          autoSize={{ minRows: 4, maxRows: 8 }}
          placeholder="请输入审核备注"
        />
      ),
    },
  ];

  const handleSubmit = param => {
    if (!param || !API) return onChange();
    API({
      body: {
        id,
        [status]: param[status] || '',
        [remark]: param[remark] || '',
      },
    })
      .then(() => {
        message.success('操作成功');
        onChange(true);
      })
      .catch(err => onTransmit(err));
  };

  const onTransmit = msg => {
    if (fmRef) fmRef.current.onErrorFn(msg);
  };

  return (
    <FormModal
      fmRef={fmRef}
      visible={visible}
      coverTitle={title}
      formData={formData}
      onChange={handleSubmit}
    />
  );
}

export default ExamineModal;
