import React, { useState, useEffect } from 'react';
import { Upload, message, Avatar } from 'antd';
import Previews from '../Previews';
import IconFont from '../IconFont';
import Thumbnails from '../Thumbnails';
import Cropper from './components/Cropper';
import { createFileKey, UploadOSS } from './components/AliOss';
import styles from './index.module.scss';

const accepts = {
  image: 'image/jpeg,image/jpg,image/png',
  video: 'video/mp4,video/3gp,video/m3u8',
};

function UploadImage({
  model = 'wilfully', // single 单张上传 avatar 头像上传 wilfully 任意数量
  cropper, // 裁剪
  children, // 嵌入子集DOM
  exhibition, // 文本介绍展示
  max = 10, // 允许最大数量
  value = [], // 默认文件集
  multiple = false, // 多选
  listType = 'picture-card', // 展示类型
  fileType = ['image'], // 文件类型
  preview = true, // 预览
  remove = true, // 移除
  onChange, // 回调函数
  callBackString, // 回调数据类型
}) {
  const [init, setInit] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [previewImg, setPreviewImg] = useState('');
  const [cropperFile, setCropperFile] = useState(null);
  const [cropperVisible, setCropperVisible] = useState(false);

  // 节流获取初始值
  useEffect(() => {
    const handler = setTimeout(() => {
      if (init) return;
      if (Array.isArray(value) && value.length) {
        const list = value.map((i, k) => ({
          uid: `${Date.now()}${k}`,
          name: i ? '加载失败' : '暂无图片',
          status: 'done',
          url: i || 'demo',
        }));
        setFileList(list);
      } else if (typeof value === 'string' && value) {
        const list = value
          .split(',')
          .filter(i => i)
          .map((i, k) => ({
            uid: `${Date.now()}${k}`,
            name: '加载失败',
            status: 'done',
            url: i,
          }));
        setFileList(list);
      } else {
        setFileList([]);
      }
      setInit(init + 1);
    }, 300);
    return () => clearTimeout(handler);
  }, [value]);

  // 文件回调节流
  useEffect(() => {
    const handler = setTimeout(() => {
      const finish = fileList.every(i => i.status !== 'uploading');
      if (onChange && init && finish) {
        const resUrl = fileList.filter(i => i.status === 'done').map(i => i.url);
        if (callBackString) {
          onChange(resUrl.join(','));
        } else {
          onChange(resUrl);
        }
      }
    }, 300);
    return () => clearTimeout(handler);
  }, [fileList]);

  const handleUpload = file => {
    const files = file.originFileObj;
    const name = createFileKey(files);
    const newFile = [
      {
        uid: file.uid,
        name,
        status: 'uploading',
        percent: 0,
        url: '',
      },
    ];
    if (model === 'wilfully') {
      setFileList(his => his.concat(newFile));
    } else {
      setFileList(newFile);
    }
    UploadOSS({
      file: files,
      fileKey: name,
      onProgress: ({ key, percent }) => {
        setFileList(his => his.map(i => (i.name === key ? { ...i, percent } : i)));
      },
      onFinish: (status, { key, url }) => {
        setFileList(his => his.map(i => (i.name === key ? { ...i, url, status } : i)));
      },
    });
  };

  const handleChange = ({ file: files }) => {
    if (files.status === 'removed') return '';
    const accept = fileType.map(i => accepts[i]).join(',');
    if (typeof accept !== 'string') return message.error('类型设置错误，无法选择文件');
    const acceptList = accept.split(',').filter(i => i);
    let flag = false;
    acceptList.forEach(i => {
      if (i.indexOf('/*') > -1 && i.split('/')[0] === files.type.split('/')[0]) {
        flag = true;
      }
      if (i === files.type) flag = true;
    });
    if (!flag) return message.error('文件类型不支持');
    if (fileList.length < max || model === 'single') {
      if (cropper && !multiple) {
        setCropperFile(files);
        setCropperVisible(true);
      } else handleUpload(files);
    }
  };

  const renderIcon = () => {
    const [fileOBJ] = fileList || [];
    const { percent } = fileOBJ || {};
    const iconType = model === 'single' && fileOBJ ? 'loading' : 'plus';
    return (
      <div className={styles.loadingBox}>
        <IconFont
          type={iconType}
          style={{
            fontSize: 28,
            color: '#888',
            margin: '8px',
          }}
        />
        {iconType === 'loading' && <div className={styles.speedText}>{percent}%</div>}
        {iconType === 'plus' && exhibition && <div>{exhibition}</div>}
      </div>
    );
  };

  const handleIcon = url => {
    const flag = /^(.+\.)(mp4|3gp|m3u8)$/i.test(url);
    return flag ? 'play-square' : 'area-chart';
  };

  const renderUpload = () => {
    const [fileOBJ = {}] = fileList || [];
    switch (model) {
      case 'avatar':
        return (
          <Upload
            showUploadList={false}
            customRequest={() => {}}
            accept={fileType.map(i => accepts[i]).join(',')}
            onChange={e => handleChange(e)}
          >
            <Avatar
              src={fileOBJ.url}
              className={styles.avatars}
              icon={<IconFont type={fileOBJ.status === 'uploading' ? 'loading' : 'user'} />}
            />
          </Upload>
        );
      case 'single':
        return (
          <Upload
            listType={listType}
            showUploadList={false}
            customRequest={() => {}}
            accept={fileType.map(i => accepts[i]).join(',')}
            onChange={e => handleChange(e)}
          >
            {children ? (
              children
            ) : fileOBJ.url ? (
              <Thumbnails
                upload={{
                  fileType,
                  accepts,
                  handleChange,
                }}
                size={86}
                preview={preview}
                images={[fileOBJ.url]}
                icon={handleIcon(fileOBJ.url)}
              />
            ) : (
              renderIcon()
            )}
          </Upload>
        );
      case 'wilfully':
        return (
          <Upload
            fileList={fileList}
            multiple={multiple}
            listType={listType}
            customRequest={() => {}}
            accept={fileType.map(i => accepts[i]).join(',')}
            showUploadList={{
              showPreviewIcon: preview,
              showRemoveIcon: remove,
            }}
            progress={{
              strokeWidth: 3,
              format: percent => <div className={styles.speedText}>{percent}%</div>,
            }}
            onChange={e => handleChange(e)}
            onPreview={({ url }) => {
              if (url) setPreviewImg(url);
              else message.error('预览失败');
            }}
            onRemove={({ uid: tId }) => {
              setFileList(his => his.filter(({ uid }) => uid !== tId));
            }}
          >
            {fileList.length < max && renderIcon()}
          </Upload>
        );
      default:
        return '组件参数错误';
    }
  };

  return (
    <div className={styles.clearfix}>
      {renderUpload()}
      {preview && (
        <Previews
          field="url"
          fileList={fileList}
          current={previewImg}
          onChange={() => setPreviewImg('')}
        />
      )}
      {!!cropper && (
        <Cropper
          fixed={cropper}
          files={cropperFile}
          proposal={exhibition}
          visible={cropperVisible}
          onChange={res => {
            if (res) handleUpload(res);
            setCropperFile(null);
            setCropperVisible(false);
          }}
        />
      )}
    </div>
  );
}

export default UploadImage;
