import { FormList, HeaderTitle, Operation, OverItem } from '@/components';
import { calcPrice, parseJSON, toDateTime } from '@/utils/utils';
import { getOrderOevr, getOrderStore, postOrderUpdate } from '@/api';
import { Col, DatePicker, message, Modal, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { st_order } from '@/utils/statetype';
import { useTable } from '@/hooks';
import moment from 'moment';

const { statusObj, typeObj } = st_order;
function OrderList({ history, querys }) {
  const { s: p_s } = querys;
  const [query, setQuery] = useState({});
  const [count, setCount] = useState([]);
  const [visible, setVisible] = useState(null);
  const [autoCancelTime, setAutoCancelTime] = useState(null);

  const columns = {
    id: '订单编号',
    number: '订单号',
    NOINDEX_OrderGoods: {
      title: '拍品编号',
      render: e => {
        const [info = {}] = e.OrderGoods || [];
        const { goodsId } = info || {};
        return goodsId || '-';
      },
    },
    NOINDEX_OrderGoodsId: {
      title: '拍品排期编号',
      render: e => {
        const [info = {}] = e.OrderGoods || [];
        const { goodsScheduleId } = info || {};
        return goodsScheduleId || '-';
      },
    },
    userId: '用户编号',
    OrderGoods: {
      title: '拍品名称',
      render: (e = []) => {
        const [info = {}] = e;
        const { photo } = info || {};
        const { goods } = parseJSON(photo) || {};
        const { name } = goods || {};
        return name || '-';
      },
    },
    merchantId: '店铺编号',
    Merchant: {
      title: '店铺名称',
      render: e => (e || {}).title || '-',
    },
    type: {
      title: '订单类型',
      render: e => typeObj[e] || '-',
    },
    status: {
      title: '订单状态',
      render: (e, { type }) => {
        if (e === -1) {
          return [20, 30].includes(type) ? '已违约' : '已取消';
        }
        return statusObj[e] || '-';
      },
    },
    resellUserId: '转拍人编号',
    shouldPayPrice: {
      title: '应付金额',
      render: e => calcPrice(e),
    },
    payPrice: {
      title: '实付金额',
      render: e => calcPrice(e),
    },
    payTime: {
      title: '支付时间',
      render: e => toDateTime(e),
    },
    autoCancelTime: {
      title: '自动取消时间',
      render: e => toDateTime(e),
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            delayed: ![10, -1].includes(e.status),
          }}
          listData={{
            select: () => history.push(`/order/list/detail?i=${e.id}`),
            delayed: () => setVisible(e),
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getOrderStore,
    isInit: false,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'userId',
      label: '用户编号',
    },
    {
      id: 'id',
      label: '订单编号',
    },
    {
      id: 'merchantId',
      label: '店铺编号',
    },
    {
      id: 'resellUserId',
      label: '转拍人编号',
    },
    {
      id: 'number',
      label: '订单号',
    },
    {
      id: 'goodsId',
      label: '拍品编号',
    },
    {
      id: 'merchantName',
      label: '店铺名称',
    },
    {
      id: 'status',
      label: '订单状态',
      type: 'select',
      selects: statusObj,
      ...(p_s ? { initialValue: String(p_s) } : {}),
      options: {
        mode: 'multiple',
        maxTagCount: 1,
      },
    },
    {
      id: 'type',
      label: '订单类型',
      type: 'select',
      selects: typeObj,
      options: {
        mode: 'multiple',
        maxTagCount: 1,
      },
    },
    {
      id: 'createdAt',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  useEffect(() => {
    getOrderOevr().then(res => {
      const countData = [
        ['待发货订单量', res.waitSendCount],
        ['待支付订单量', res.waitCount],
        ['认购订单量', res.subscriptionCount],
        ['拍卖订单量', res.auctionCount],
        ['违约单量', res.cancelCount],
      ];
      setCount(countData);
    });
  }, []);

  const changeQuery = (value = {}) => {
    const { createdAt, status = [p_s], type = [20, 25, 30, 40], ...props } = value;
    const [start, end] = createdAt || [];
    const q = {
      ...props,
      status: status.join(','),
      type: type.join(','),
      ...(createdAt && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  const disabledDate = current => {
    return current < moment().subtract(1, 'days');
  };

  const handleClickItem = index => {
    let q = {};
    switch (index) {
      case 0:
        q = { status: '30', type: '20,25,30,40' };
        break;
      case 1:
        q = { status: '10', type: '20,25,30,40' };
        break;
      case 2:
        q = { type: '40' };
        break;
      case 3:
        q = { type: '20,25,30' };
        break;
      case 4:
        q = { status: '-1', type: '20,25,30,40' };
        break;
    }
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle
        addLabel={
          p_s
            ? {
                label: statusObj[p_s],
                tips: '点击查看全部订单',
                onClick: () => location.replace('/order/list'),
              }
            : null
        }
      />
      <OverItem datas={count} width={'20%'} onClick={handleClickItem} />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} />
      <Modal
        visible={!!visible}
        title="延时订单过期时间"
        onCancel={() => setVisible(null)}
        onOk={() => {
          if (!autoCancelTime) {
            message.warning('请选择时间');
            return;
          }
          postOrderUpdate({
            body: {
              id: visible.id,
              operateType: 1,
              status: visible.status,
              autoCancelTime: toDateTime(autoCancelTime),
            },
          }).then(() => {
            setVisible(null);
            message.success('调整成功');
            flagFn();
          });
        }}
      >
        <Col>
          自动取消时间：
          <DatePicker showTime disabledDate={disabledDate} onChange={e => setAutoCancelTime(e)} />
        </Col>
      </Modal>
    </>
  );
}

export default OrderList;
