import { postCategoryCreate, postCategoryUpdate } from '@/api';
import { FormModal, UploadImage } from '@/components';
import { notEmptyObj } from '@/utils/utils';
import { Input, message, Radio } from 'antd';
import React from 'react';

function ClassifyModal({ visible, data, onChange }) {
  const { id, level, item, ...param } = data || {};
  const editFlag = notEmptyObj(param);
  const options1 = [
    {
      label: '显示',
      value: level === 3 ? 10 : 30,
    },
    {
      label: '隐藏',
      value: level === 3 ? 20 : 40,
    },
  ];
  const options2 = [
    {
      label: '显示',
      value: level === 3 ? 10 : 30,
    },
    {
      label: '隐藏',
      value: level === 3 ? 20 : 40,
    },
  ];

  const handleSubmit = async params => {
    if (!params) return onChange();
    const { sign1, iconUrl = '', ...prop } = params;
    editFlag
      ? await postCategoryUpdate({
          body: {
            id,
            iconUrl,
            ...prop,
            sign1,
          },
        })
      : await postCategoryCreate({
          body: {
            iconUrl,
            ...(id ? { parentId: id } : {}),
            ...prop,
            sign1: level === 3 ? 20 : sign1,
          },
        });
    message.success('操作成功');
    onChange(editFlag ? 'update' : 'create');
  };

  let name = '分类名称';
  if (level === 3) {
    name = '属性名';
  }
  if (level === 4) {
    name = '属性值';
  }

  const formData = [
    {
      name: 'name',
      label: name,
      rules: [{ required: true, message: `请输入${name}` }],
      render: <Input placeholder={`请输入${name}`} />,
    },
  ];
  if (level === 2) {
    formData.push({
      name: 'iconUrl',
      label: '分类图片',
      rules: [{ required: true, message: '请选择分类图片' }],
      render: <UploadImage model="single" callBackString />,
    });
  }
  if (level === 4) {
    formData.push({
      name: 'sign1',
      label: '分类筛选',
      rules: [{ required: true, message: '请选择分类筛选' }],
      render: <Radio.Group options={options1} />,
    });
  }
  if (level > 2) {
    formData.push({
      name: 'sign2',
      label: '条件筛选',
      rules: [{ required: true, message: '请选择条件筛选' }],
      render: <Radio.Group options={options2} />,
    });
  }

  let title = `${level}级分类`;
  if (level === 3) {
    title = '属性名';
  }
  if (level === 4) {
    title = '属性值';
  }

  return (
    <FormModal
      title={title}
      visible={visible}
      formData={formData}
      initialValues={param}
      onChange={handleSubmit}
    />
  );
}

export default ClassifyModal;
