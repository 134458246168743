import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import router from '@/router/routes';
import LabelArrow from '../LabelArrow';
import styles from './index.module.scss';

function HeaderTitle({ title, disabled, addLabel, showBtn = true, onClick }) {
  const [titleRoute, setTitleRoute] = useState([]);

  const handleRouter = () => {
    const { pathname = '' } = window.location;
    const routeArr = pathname.split('/').filter(i => i);
    let middleStr = '';
    let middleArr = Object.assign([], router);
    const resultArr = [];
    routeArr.forEach(i => {
      const path = `${middleStr}/${i}`;
      const { routes = [], title } = middleArr.find(j => j.path === path) || {};
      if (routes.length) {
        middleStr = path;
        middleArr = Object.assign([], routes);
      }
      if (title)
        resultArr.push({
          title,
          path,
        });
    });
    return resultArr;
  };

  useEffect(() => {
    const arr = handleRouter();
    setTitleRoute(arr);
  }, []);

  return (
    <>
      <div className={styles.headerTitle}>
        <LabelArrow datas={titleRoute} addLabel={addLabel} />
        {title && showBtn && (
          <Button size="small" type="primary" disabled={disabled} onClick={onClick}>
            {title}
          </Button>
        )}
      </div>
      <div className={styles.headerPart}></div>
    </>
  );
}

export default HeaderTitle;
