import { noticeList, noticeUpdate } from '@/api';
import { FormModal, HeaderTitle, Operation, Thumbnails, UploadImage } from '@/components';
import { notEmptyObj, toDateTime } from '@/utils/utils';
import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';

function InfosTemplate() {
  const [flag, setFlag] = useState(false);
  const [lists, setLists] = useState([]);
  const [datas, setDatas] = useState({});

  const columns = [
    {
      width: 100,
      title: '模板编号',
      dataIndex: 'id',
      align: 'center',
    },
    {
      width: 100,
      title: '模板图片',
      dataIndex: 'iconUrl',
      align: 'center',
      render: e => <Thumbnails images={[e]} center />,
    },
    {
      width: 200,
      title: '模板内容',
      dataIndex: 'content',
      align: 'center',
      ellipsis: true,
    },
    {
      width: 100,
      title: '微信推送',
      dataIndex: 'wechatPush',
      align: 'center',
    },
    {
      width: 100,
      title: '短信推送',
      dataIndex: 'smsPush',
      align: 'center',
    },
    {
      width: 100,
      title: '站内推送',
      dataIndex: 'dataPush',
      align: 'center',
    },
    {
      width: 100,
      title: '分类数值',
      dataIndex: 'classify',
      align: 'center',
    },
    {
      width: 100,
      title: '分类名称',
      dataIndex: 'classifyName',
      align: 'center',
    },
    {
      width: 100,
      title: '种类数值',
      dataIndex: 'kind',
      align: 'center',
    },
    {
      width: 100,
      title: '种类名称',
      dataIndex: 'kindName',
      align: 'center',
    },
    {
      width: 100,
      title: '模板排序',
      dataIndex: 'sort',
      align: 'center',
    },
    {
      width: 100,
      title: '备注信息',
      dataIndex: 'remark',
      align: 'center',
      ellipsis: true,
    },
    {
      width: 100,
      title: '最近修改',
      dataIndex: 'updatedAt',
      align: 'center',
      render: e => toDateTime(e),
    },
    {
      width: 100,
      title: '创建时间',
      dataIndex: 'createdAt',
      align: 'center',
      render: e => toDateTime(e),
    },
    {
      width: 100,
      title: '操作',
      align: 'center',
      fixed: 'right',
      render: e => (
        <Operation
          listData={{
            update: () => setDatas(e),
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    noticeList().then(res => {
      setLists(res);
    });
  }, [flag]);

  const formDataModal = [
    {
      name: 'iconUrl',
      label: '模板图片',
      rules: [{ required: true, message: '请选择模板图片' }],
      render: <UploadImage model="single" callBackString />,
    },
  ];

  const handleSubmit = param => {
    if (!param) {
      setDatas({});
      return;
    }
    noticeUpdate({
      body: {
        id: datas.id,
        iconUrl: param.iconUrl,
      },
    }).then(() => {
      message.success('修改成功');
      setDatas({});
      setFlag(e => !e);
    });
  };

  return (
    <>
      <HeaderTitle />
      <Table
        bordered
        size="small"
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={lists}
        pagination={false}
        rowKey={row => row.id}
      />
      <FormModal
        title="修改模板"
        visible={!!notEmptyObj(datas)}
        formData={formDataModal}
        initialValues={datas}
        onChange={handleSubmit}
      />
    </>
  );
}

export default InfosTemplate;
