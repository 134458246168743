import { ExportData, FormList, HeaderTitle, Thumbnails } from '@/components';
import { calcPrice, toDateTime } from '@/utils/utils';
import { getOrderStore } from '@/api';
import React, { useState } from 'react';
import { useTable } from '@/hooks';
import { Button, Table } from 'antd';

const statusObj = {
  10: '待付款',
  20: '已支付',
  '-1': '已取消',
};
function FinanceRecharge() {
  const [query, setQuery] = useState({});

  const columns = {
    id: '编号',
    number: '订单号',
    userId: '用户编号',
    NOINDEX_User: {
      title: '用户名称',
      render: e => {
        const { User = {} } = e;
        return User?.nickname || '-';
      },
    },
    User: {
      title: '用户头像',
      render: e => <Thumbnails images={[e?.avatarUrl]} center />,
    },
    status: {
      title: '支付状态',
      render: e => statusObj[e],
    },
    shouldPayPrice: {
      title: '应付金额',
      render: e => calcPrice(e),
    },
    payPrice: {
      title: '实付金额',
      render: e => calcPrice(e),
    },
    payType: '支付方式',
    payTime: {
      title: '支付时间',
      render: e => toDateTime(e),
    },
    autoCancelTime: {
      title: '自动取消',
      render: e => toDateTime(e),
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
  };

  const { tableProps, flagFn, apiData } = useTable({
    columns,
    API: getOrderStore,
    isInit: false,
    params: {
      query: {
        ...query,
        type: 10,
        status: 20,
      },
    },
  });
  const { amount } = apiData || {};

  const formData = [
    {
      id: 'userId',
      label: '用户编号',
    },
    {
      id: 'createdAt',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { createdAt, ...props } = value;
    const [start, end] = createdAt || [];
    const q = {
      ...props,
      ...(createdAt && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList
        formData={formData}
        changeQuery={changeQuery}
        extraRender={
          <ExportData
            API={getOrderStore}
            title="充值列表"
            query={{ ...query, type: 10, status: 20 }}
            extend={{
              status: statusObj,
              shouldPayPrice: 'amount',
              payPrice: 'amount',
              payTime: 'time',
              autoCancelTime: 'time',
              createdAt: 'time',
            }}
            columns={{
              id: '编号',
              number: '订单号',
              userId: '用户编号',
              'User.nickname': '用户昵称',
              status: '支付状态',
              shouldPayPrice: '应付金额',
              payPrice: '实付金额',
              payType: '支付方式',
              payTime: '支付时间',
              autoCancelTime: '自动取消',
              createdAt: '创建时间',
            }}
          />
        }
      />
      <Table
        {...tableProps}
        footer={() => (
          <Button style={{ border: 'none', boxShadow: 'none', marginTop: '12px' }}>
            实付总金额：{calcPrice(amount)}
          </Button>
        )}
      />
    </>
  );
}

export default FinanceRecharge;
