import { goodsScheduleList } from '@/api';
import { FormList, HeaderTitle, Operation, Thumbnails } from '@/components';
import { st_auction, st_goods } from '@/utils/statetype';
import { calcPrice, toDateTime } from '@/utils/utils';
import React, { useState } from 'react';
import { useTable } from '@/hooks';
import { Table } from 'antd';

const { typeObj } = st_goods;
const { statusObj: aucStatusObj } = st_auction;
function FinanceSettlement({ history }) {
  const [query, setQuery] = useState({});

  const columns = {
    id: '排期编号',
    NOINDEX_id: {
      title: '拍品编号',
      render: ({ Goods }) => {
        const { id } = Goods || {};
        return id || '-';
      },
    },
    NOINDEX_name: {
      title: '拍品名称',
      render: ({ Goods }) => {
        const { name } = Goods || {};
        return name || '-';
      },
    },
    NOINDEX_thumb: {
      title: '封面图',
      render: ({ Goods }) => {
        const { thumb } = Goods || {};
        return <Thumbnails images={[thumb]} center />;
      },
    },
    type: {
      title: '排期拍品类型',
      render: e => typeObj[e] || '-',
    },
    NOINDEX_Merchant: {
      title: '所属店铺',
      render: ({ Goods }) => {
        const { Merchant = {} } = Goods || {};
        return Merchant.title || '-';
      },
    },
    num: '拍品数量',
    status: {
      title: '竞拍状态',
      render: e => aucStatusObj[e],
    },
    offerNums: '出价次数',
    startPrice: {
      title: '起拍价',
      render: e => calcPrice(e),
    },
    finalPrice: {
      title: '最终价',
      render: e => calcPrice(e),
    },
    NOINDEX_times: {
      title: '竞拍时间',
      render: e => {
        const { startTime, endTime } = e;
        return toDateTime(startTime) + ' ~' + toDateTime(endTime);
      },
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          listData={{
            select: () => history.push(`/finance/settlement/detail?i=${e.id}`),
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: goodsScheduleList,
    isInit: false,
    params: {
      query: {
        ...query,
        status: 110,
      },
    },
  });

  const formData = [
    {
      id: 'name',
      label: '拍品名称',
    },
    {
      id: 'merchantId',
      label: '店铺编号',
    },
    {
      id: 'title',
      label: '店铺名称',
    },
    {
      id: 'times',
      label: '竞拍时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { times, ...props } = value;
    const [start, end] = times || [];
    const q = {
      ...props,
      ...(times && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} />
    </>
  );
}

export default FinanceSettlement;
