import { Button, Modal } from 'antd';
import React from 'react';
import defBtn from './configBtn';
import IconFont from '../IconFont';
import styles from './index.module.scss';

const { confirm } = Modal;

function Operation({ params, type = 'link', disabled = {}, hideMenu = {}, listData = [] }) {
  const showConfirm = ({ danger, label, title, onSubmit }) => {
    confirm({
      title: title || `确认${label}？`,
      icon: <IconFont type="exclamation-circle" />,
      content: '（请仔细核对数据，确认无误后再操作）',
      okType: danger ? 'danger' : 'primary',
      okText: label,
      onOk: () => onSubmit(params),
    });
  };

  const renderButton = () => {
    const resultBtn = {};
    Object.keys(defBtn).forEach(i => {
      if (global.isOperable) {
        resultBtn[i.replace('_', '')] = defBtn[i];
      } else if (i.indexOf('_') === -1) {
        resultBtn[i] = defBtn[i];
      }
    });
    const list = Object.keys(listData).filter(i => resultBtn[i] && !hideMenu[i]);
    const render = list.map((i, k) => {
      const item =
        typeof resultBtn[i] === 'object'
          ? {
              ...resultBtn[i],
              disabled: disabled[i],
              onSubmit: listData[i],
            }
          : {
              label: resultBtn[i],
              disabled: disabled[i],
              onSubmit: listData[i],
            };
      return (
        <Button
          key={k}
          type={type}
          size="small"
          danger={item.danger}
          disabled={item.disabled}
          className={styles.buttons}
          onClick={() => (item.modal ? showConfirm(item) : item.onSubmit(params))}
        >
          {item.label}
        </Button>
      );
    });
    return !!render.length ? render : '-';
  };

  return <div className={styles.operationBody}>{renderButton()}</div>;
}

export default Operation;
