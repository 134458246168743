import { ExportData, FormList, HeaderTitle } from '@/components';
import { getMerchantBill, goodsScheduleDetail } from '@/api';
import { calcPrice, toDateTime } from '@/utils/utils';
import React, { useState } from 'react';
import { useTable } from '@/hooks';
import { Button, Table } from 'antd';

const statusObj = {
  listingFee: '拍品挂牌费',
  manageFee: '中心管理费',
  merchantApplyAward: '授权服务费',
  systemPremiumIncome: '平台溢价收入',
  subscriptionFee: '认购手续费',
  defaultOrderIncome: '订单违约收益',
};
const typeObj = {
  1: '平台收入',
  '-1': '平台支出',
};
function FinancePlatform({ history }) {
  const [query, setQuery] = useState({});

  const sourceFn = {
    goodsSchedule: async ({ sourceId }) => {
      const res = await goodsScheduleDetail({ query: { goodsScheduleId: sourceId } });
      history.push(`/goods/list/detail?i=${res.goodsId}`);
    },
    userInvite: ({ sourceId }) => {
      history.push(`/user/list/detail?i=${sourceId}`);
    },
    auctionHouse: ({ sourceId }) => {
      history.push(`/goods/beat/detail?i=${sourceId}`);
    },
    userBill: () => {
      history.push(`/user/list/detail?i=${datas.userId}`);
    },
    userOrderDetail: ({ sourceId }) => {
      history.push(`/order/list/detail?i=${sourceId}`);
    },
    userDeliveryDetail: () => {
      history.push(`/user/list/detail?i=${datas.userId}`);
    },
    merchantGoods: ({ sourceId }) => {
      history.push(`/goods/list/detail?i=${sourceId}`);
    },
  };

  const columns = {
    id: '编号',
    number: '账单单号',
    type: {
      title: '账单类型',
      render: e => typeObj[e],
    },
    kind: {
      title: '账单类别',
      render: e => statusObj[e] || e,
    },
    amount: {
      title: '账单金额',
      render: e => calcPrice(e),
    },
    sourceId: '来源编号',
    source: {
      title: '账单来源',
      render: (e, env) =>
        !!sourceFn[e] ? (
          <Button type="link" style={{ padding: '0' }} onClick={() => sourceFn[e](env)}>
            {e}
          </Button>
        ) : (
          e
        ),
    },
    describe: '备注信息',
    createdAt: {
      title: '生成时间',
      render: e => toDateTime(e),
    },
  };

  const { tableProps, flagFn, apiData } = useTable({
    columns,
    API: getMerchantBill,
    isInit: false,
    params: {
      query: {
        ...query,
        merchantId: -1,
      },
    },
  });
  const { amount } = apiData || {};

  const formData = [
    {
      id: 'kind',
      label: '收入类别',
      type: 'select',
      selects: statusObj,
      hideItem: query.type != 1,
      options: {
        mode: 'multiple',
        maxTagCount: 1,
      },
    },
    {
      id: 'createTime',
      label: '生成时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}, param) => {
    const { kind = Object.keys(statusObj), createTime } = value;
    const { type = 1 } = param;
    const [starts, ends] = createTime || [];
    const q = {
      type,
      ...(createTime && {
        createdStartTime: toDateTime(starts),
        createdEndTime: toDateTime(ends),
      }),
    };
    if (type == 1) {
      q.kind = kind.join(',');
    }
    setQuery(q);
    flagFn(true);
  };

  const listItem = [
    {
      id: 'type',
      type: 'radio',
      align: 'left',
      initValue: '1',
      selects: typeObj,
    },
    {
      id: 'types',
      align: 'right',
      render: (
        <ExportData
          API={getMerchantBill}
          title="平台账单"
          query={{ ...query, merchantId: -1 }}
          extend={{
            type: typeObj,
            kind: statusObj,
            amount: 'amount',
            createdAt: 'time',
          }}
          columns={{
            id: '编号',
            number: '账单单号',
            type: '账单类型',
            kind: '账单类别',
            amount: '账单金额',
            sourceId: '来源编号',
            source: '账单来源',
            describe: '备注信息',
            createdAt: '生成时间',
          }}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderTitle />
      <FormList
        formData={formData}
        searchList={{
          listItem,
        }}
        changeQuery={changeQuery}
      />
      <Table
        {...tableProps}
        footer={() => (
          <Button style={{ border: 'none', boxShadow: 'none', marginTop: '12px' }}>
            {typeObj[query.type]}总金额：{calcPrice(amount)}
          </Button>
        )}
      />
    </>
  );
}

export default FinancePlatform;
