import OSS from 'ali-oss';
import { message } from 'antd';
import { random } from 'lodash';
import { OSSPREFIX } from '@/utils/constants';
import { getUploadToken } from '@/utils/helper';

const UploadToOSS = async (self, file, name, onProgress) => {
  const url = `${OSSPREFIX}/${name}`;
  return new Promise((resolve, reject) => {
    new OSS(self)
      .multipartUpload(url, file, {
        progress: p => {
          const result = {
            key: name,
            percent: Math.round(p * 100),
          };
          onProgress(result);
        },
      })
      .then(data => {
        const { res = {} } = data || {};
        if (res.status === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const UploadOSS = async ({ file, fileKey, onProgress, onFinish }) => {
  await getUploadToken();
  const { uploadToken } = global;
  if (!uploadToken) {
    message.error('上传失败！');
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    UploadToOSS(uploadToken, file, fileKey, onProgress)
      .then(data => {
        const { name } = data || {};
        const { endpoint } = uploadToken || {};
        onFinish(name ? 'done' : 'error', {
          key: fileKey,
          url: `${endpoint}/${name}`,
        });
      })
      .catch(err => {
        message.error('上传失败！');
        onFinish('error', {
          key: fileKey,
          url: '',
        });
      });
  };
};

export const checkImageSize = ({ src, maxWidth = 2000, maxHeight = 2000 }) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      if (maxWidth < image.width) {
        message.error(`图片宽度不能超过${maxWidth}`);
        reject();
      } else if (maxHeight < image.height) {
        message.error(`图片高度不能超过${maxHeight}`);
        reject();
      } else {
        resolve(src);
      }
    };
    image.onerror = reject;
    image.src = src;
  });
};

export const checkUploadToken = info => {
  if (!info) return false;
  const { expiration } = info || {};
  const current = Date.now() + 5 * 60 * 1000;
  const times = new Date(expiration).getTime();
  return current < times;
};

export const createFileKey = file => {
  if (file) {
    const { type = '', name = '' } = file || {};
    const folder = (type && type.split('/')[0]) || 'image';
    const nameSuffix = name
      .toString()
      .split('.')
      .reverse()[0];
    const prefix = String(Date.now()) + random(1000000, 9999999);
    return `${folder}s/${prefix}.${nameSuffix}`;
  }
  return '';
};
