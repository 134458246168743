import router from '@/router/routes';
import bigNumber from 'bignumber.js';
import { message } from 'antd';
import moment from 'moment';
import { delToken, getToken } from './storage';

/**
 * 解析JSON数据
 * @param {*} value -- 解析值
 * @param {*} format -- 返回格式 Array Object String
 */
export const parseJSON = (value, format) => {
  let result = null,
    defValue;
  try {
    result = JSON.parse(value);
  } catch (error) {
    if (value) result = value;
  }
  switch (format) {
    case 'object':
      defValue = {};
      return;
    case 'array':
      defValue = [];
      break;
    case 'string':
      defValue = '';
      break;
    default:
      return result;
  }
  return typeof result === format ? result : defValue;
};

/**
 * 匹配路由白名单
 * @param {*} path
 */
export const provWhiteList = path => {
  const { pathname } = window.location;
  const resPath = path || pathname;
  let whiteList = router.filter(i => !i.layout && !i.redirect);
  const flag = whiteList.findIndex(i => i.path === resPath) > -1;
  return flag;
};

/**
 * 时间戳转换
 * @param {string} date
 */
export const toTimeStamp = date => {
  if (!date) return null;
  const newTime = new Date(date).getTime();
  return Math.floor(newTime / 1000);
};

/**
 * 转moment时间格式
 * @param {*} date
 */
export const toMoment = date => {
  if (new Date(date) === 'Invalid Date' || !date) return null;
  if (typeof date === 'number') {
    const leng = date.toString().length;
    if (leng >= 10) {
      const newTime = leng === 10 ? date * 1000 : date;
      return moment(newTime);
    }
    date = date.toString();
  }
  return moment(date);
};

/**
 * 转时间字符串格式
 * @param {*} date
 */
export const toDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss', extra = '-') => {
  if (!date || new Date(date) === 'Invalid Date') return extra;
  if (typeof date === 'number') {
    const leng = date.toString().length;
    if (leng >= 10) {
      const newTime = leng === 10 ? date * 1000 : date;
      return moment(newTime).format(format);
    }
  }
  return moment(new Date(date)).format(format);
};

/**
 * 处理URL尾部参数
 * @param {*} url
 */
export const handleFileUrl = url => {
  let resultUrl = '';
  if (Array.isArray(url)) {
    resultUrl = url.map(i => i.split('?')[0]).join(',');
  } else if (typeof url === 'string' && url) {
    resultUrl = url.split('?')[0];
  }
  return resultUrl;
};

/**
 * byte转换
 * @param {*} byte
 */
export const transByte = byte => {
  let resutl = byte;
  if (byte / 1024 < 1) return `${resutl}B`;
  resutl = resutl / 1024;
  if (resutl / 1024 < 1) return `${resutl.toFixed(2)}KB`;
  resutl = resutl / 1024;
  if (resutl / 1024 < 1) return `${resutl.toFixed(2)}MB`;
  resutl = resutl / 1024;
  return `${resutl.toFixed(2)}GB`;
};

/**
 * 价格分->元
 * @param {*} price
 * @param {*} fixed
 * @param {*} isFixed
 */
export const calcPrice = (price, fixed = 2, isFixed = true) => {
  if (!price) {
    return bigNumber(0).toFixed(fixed);
  }
  const temp = bigNumber(price).div(bigNumber(100));
  const res = isFixed ? temp.toFixed(fixed, 3) : temp.dp(fixed, 3).toString();
  return res;
};

/**
 * 判断是否登录
 */
export const isLogin = () => {
  const nowTime = Date.now() + 60 * 60 * 1000;
  const { Token, ExpiredAt } = getToken();
  if (Token && ExpiredAt > nowTime) {
    return true;
  }
  return false;
};

/**
 * 路径跳转
 * @param {*} path
 */
export const skipUrl = path => {
  const { origin } = window.location;
  window.location.replace(`${origin}${path}`);
};

/**
 * 校验OSSTOKEN信息是否过期
 * @param {*} key
 * @returns
 */
export const overOSSToken = () => {
  const { uploadToken } = global || {};
  const { expiration } = uploadToken || {};
  const nowTime = Date.now() + 5 * 60 * 1000;
  const expira = new Date(expiration).getTime();
  if (expira <= nowTime || !uploadToken) return true;
  return false;
};

/**
 * 合并Param到URL
 * @param {string} url
 * @param {object} params
 */
export const mergeUrlParams = (url, params) => {
  const paramArr = Object.keys(params);
  const paramStr = paramArr.map(i => `${i}=${params[i]}`).join('&');
  return `${url}?${paramStr}`;
};

/**
 * 判断空对象
 * @param {*} param
 */
export const notEmptyObj = param => {
  if (param && typeof param === 'object') {
    return !!Object.keys(param).length;
  }
  return false;
};

/**
 * 扁平化处理
 * @returns
 */
export const recurTurnFlat = (arr, key) => {
  let resArr = [];
  arr.forEach(item => {
    const copyItem = Object.assign({}, item) || {};
    delete copyItem[key];
    resArr.push(copyItem);
    if (item[key] && item[key].length) {
      resArr = resArr.concat(recurTurnFlat(item[key], key));
    }
  });
  return resArr;
};

/**
 * 获取省市区
 */
export const getRegionsName = ids => {
  if (!Array.isArray(ids)) return '';
  const regions = global.regionsFlat || [];
  const resName = ids.map(i => {
    if (!i) return '';
    const { areaName = '' } = regions.find(j => j.areaCode == i) || {};
    return areaName;
  });
  return resName;
};

/**
 * 获取param参数
 */
export const getUrlParams = key => {
  let local = window.location.search;
  local = local.replace('?', '');
  const arr = local.split('&').filter(i => i);
  const res = arr.find(i => i.indexOf(`${key}=`) === 0) || '';
  return res.replace(`${key}=`, '');
};

/**
 * 文本复制
 * @param {string} val
 */
export const copyText = val => {
  var input = document.createElement('textarea');
  input.value = val;
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
  document.body.removeChild(input);
  message.success('复制成功');
};

/**
 * 数组/对象转字符串
 * @param {*} data
 * @returns
 */
export const toSpliceStr = (data, str = ',') => {
  if (!data || typeof data !== 'object') return data ?? '';
  if (Array.isArray(data)) {
    const arr = data.flat(Infinity).join(str);
    return arr;
  }
  const obj = Object.entries(data)
    .map(i => i.join(':'))
    .join(str);
  return obj;
};

/**
 * 遍历对象为数组
 * @returns
 */
export const ergodicObj = obj => {
  const resArr = Object.keys(obj).map(i => ({
    value: i,
    label: obj[i],
  }));
  return resArr;
};

/**
 * 清除登录态
 */
export const clearToken = () => {
  delToken();
  if (window.location.pathname.toLocaleLowerCase().indexOf('/login') !== 0) {
    const { origin } = window.location;
    window.location.replace(`${origin}/login`);
  }
};

/**
 * 判断是否超管
 */
export const isSupertube = () => {
  const { roleName } = getToken() || {};
  return roleName === '超级管理员';
};
