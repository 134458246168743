import { ExportData, FormList, HeaderTitle } from '@/components';
import { addUesrSubscriptionBill, getUserList, listUserSubscriptBill } from '@/api';
import { Col, InputNumber, message, Modal, Row, Select, Spin, Table } from 'antd';
import { calcPrice, toDateTime } from '@/utils/utils';
import React, { useState } from 'react';
import { useTable } from '@/hooks';

const kindObj = {
  productGetSubscription: '标品认购额',
  auctionGetSubscription: '个品认购额',
};
function FinanceBill() {
  const [body, setBody] = useState({});
  const [query, setQuery] = useState({});
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = {
    id: '编号',
    number: '单号',
    userId: '用户编号',
    User: {
      title: '用户昵称',
      render: e => e?.nickname,
    },
    amount: {
      title: '额度数量',
      render: e => calcPrice(e),
    },
    kind: {
      title: '认购类型',
      render: e => kindObj[e] || e,
    },
    describe: '备注信息',
    sourceId: '来源编号',
    source: '来源名称',
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    isInit: false,
    API: listUserSubscriptBill,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'userId',
      label: '用户编号',
    },
  ];

  const changeQuery = (value = {}) => {
    setQuery(value);
    flagFn(true);
  };

  const handleSubmit = async () => {
    if (Object.values(body).filter(i => i).length < 3) {
      message.warning('请将数据填写完整');
      return;
    }
    try {
      setLoading(true);
      const res = await getUserList({
        query: {
          id: body.userId,
        },
      });
      if (res.data.length === 1) {
        await addUesrSubscriptionBill({
          body,
        });
        message.success('添加成功');
        setVisible(false);
        setLoading(false);
        setBody({});
        flagFn();
      } else {
        message.warning('输入的用户不存在！');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderTitle title="添加认购额" onClick={() => setVisible(true)} />
      <FormList
        formData={formData}
        changeQuery={changeQuery}
        extraRender={
          <ExportData
            API={listUserSubscriptBill}
            title="认购账单列表"
            query={query}
            extend={{
              amount: 'amount',
              createdAt: 'time',
              kind: kindObj,
            }}
            columns={{
              id: '编号',
              number: '单号',
              userId: '用户编号',
              'User.nickname': '用户昵称',
              amount: '额度数量',
              kind: '认购类型',
              describe: '备注信息',
              sourceId: '来源编号',
              source: '来源名称',
              createdAt: '创建时间',
            }}
          />
        }
      />
      <Table {...tableProps} />
      <Modal
        destroyOnClose
        title="添加认购额"
        visible={visible}
        confirmLoading={loading}
        onCancel={() => setVisible(false)}
        onOk={() => handleSubmit()}
      >
        <Spin spinning={loading}>
          <Row style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
            <Col span={6} style={{ textAlign: 'right', paddingRight: '22px' }}>
              用户编号:
            </Col>
            <InputNumber
              min={1}
              precision={0}
              max={999999999}
              controls={false}
              placeholder="请输入用户编号"
              style={{ width: '280px' }}
              onChange={e => {
                setBody(his => {
                  const newHis = Object.assign({}, his);
                  newHis.userId = e;
                  return newHis;
                });
              }}
            />
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
            <Col span={6} style={{ textAlign: 'right', paddingRight: '22px' }}>
              认购类型:
            </Col>
            <Select
              placeholder="请选择认购类型"
              style={{ width: '280px' }}
              onChange={e => {
                setBody(his => {
                  const newHis = Object.assign({}, his);
                  newHis.kind = e;
                  return newHis;
                });
              }}
            >
              {Object.keys(kindObj).map(item => (
                <Select.Option key={item} value={item}>
                  {kindObj[item]}
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
            <Col span={6} style={{ textAlign: 'right', paddingRight: '22px' }}>
              额度数量:
            </Col>
            <InputNumber
              min={0}
              precision={2}
              placeholder="请输入额度数量"
              style={{ width: '280px' }}
              onChange={e => {
                setBody(his => {
                  const newHis = Object.assign({}, his);
                  newHis.amount = Math.floor(e * 100);
                  return newHis;
                });
              }}
            />
          </Row>
        </Spin>
      </Modal>
    </>
  );
}

export default FinanceBill;
