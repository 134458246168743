import React, { useState } from 'react';
import { Avatar, Upload } from 'antd';
import PropTypes from 'prop-types';
import { copyText } from '@/utils/utils';
import IconFont from '../IconFont';
import Previews from '../Previews';
import ImageLoad from './components/ImageLoad';
import styles from './index.module.scss';

function Thumbnails({
  border,
  center,
  size = 64,
  Thumbnail,
  fix = true,
  images = [],
  upload = null,
  preview = true,
  copy = false,
  buffer = false,
  shape = 'square',
  icon = 'area-chart',
}) {
  const [current, setCurrent] = useState(null);

  return (
    <>
      <div className={styles.previewList} style={center ? { justifyContent: 'center' } : {}}>
        {images.map((value, index) => (
          <div key={index} className={styles.previewItem}>
            {value && (preview || copy) && (
              <div
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                }}
                className={styles.toBeCenter}
                onClick={e => e.stopPropagation()}
              >
                {preview && (
                  <span title="预览文件" onClick={() => setCurrent(index)}>
                    <IconFont type="eye" className={styles.iconFonts} />
                  </span>
                )}
                {copy && (
                  <span title="复制链接" onClick={() => copyText(value)}>
                    <IconFont type="copy" className={styles.iconFonts} />
                  </span>
                )}
                {upload && (
                  <Upload
                    showUploadList={false}
                    customRequest={() => {}}
                    accept={upload.fileType.map(i => upload.accepts[i]).join(',')}
                    onChange={e => upload.handleChange(e)}
                  >
                    <span title="上传文件">
                      <IconFont type="upload" className={styles.iconFonts} />
                    </span>
                  </Upload>
                )}
              </div>
            )}
            {Thumbnail({ value, size, shape, icon, border, fix, buffer })}
          </div>
        ))}
      </div>
      {typeof current === 'number' && (
        <Previews fileList={images} current={current} onChange={() => setCurrent(null)} />
      )}
    </>
  );
}

Thumbnails.defaultProps = {
  images: [],
  Thumbnail: data => (
    <ImageLoad
      src={data.value}
      size={data.size}
      icon={data.icon}
      shape={data.shape}
      buffer={data.buffer}
    >
      <Avatar
        src={data.value}
        size={data.size}
        shape={data.shape}
        className={data.fix && styles.fit}
        style={data.border && { border: '1px dashed #d9d9d9' }}
      />
    </ImageLoad>
  ),
};

Thumbnails.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Thumbnails;
