export const expressList = {
  annengwuliu: '安能快运',
  debangkuaidi: '德邦快递',
  debangwuliu: '德邦物流',
  ems: 'EMS',
  huitongkuaidi: '百世快递',
  baishiwuliu: '百世快运',
  jd: '京东快递',
  kuayue: '跨越速运',
  pjbest: '品骏快递',
  shentong: '申通快递',
  shunfeng: '顺丰速运',
  shunfeng: '顺丰（丰密面单）',
  shunfengkuaiyun: '顺丰快运',
  fengwang: '丰网速运',
  suer: '速尔快递',
  xinfengwuliu: '信丰物流',
  youshuwuliu: '优速快递',
  youzhengguonei: '邮政快递包裹',
  yuantong: '圆通速递',
  yuantongguoji: '圆通国际',
  yunda: '韵达快递',
  zhaijisong: '宅急送',
  zhongtong: '中通快递',
  zhongtongkuaiyun: '中通快运',
  ewe: 'EWE全球快递',
  ytchengnuoda: '圆通承诺达',
  weitepai: '微特派',
  lbex: '龙邦物流',
  dsukuaidi: 'D速快递',
  cfss: '银雁专送',
  jtexpress: '极兔速递',
  huisenky: '汇森速运',
  zhongyouex: '众邮快递',
  jiayunmeiwuliu: '加运美速递',
  idamalu: '大马鹿',
  sxjdfreight: '顺心捷达',
  yimidida: '壹米滴答',
  jingdongkuaiyun: '京东快运',
  yundadiantongda: '韵达点通达',
  suteng: '速腾快递',
  lianhaowuliu: '联昊通速递',
  jiayiwuliu: '佳怡物流',
  jinguangsudikuaijian: '京广速递',
  yundakuaiyun: '韵达快运',
  tiandihuayu: '天地华宇',
  cccc58: '中集冷云',
  nezhasuyun: '哪吒速运',
  sunjex: '新杰物流',
};
