import axios from 'axios';
import { message } from 'antd';
import * as pathToRegexp from 'path-to-regexp';
import { getToken } from './storage';
import config from '@/config';
import { clearToken } from './utils';

const { API_URL } = config;

const beforeFetch = options => {
  const { Token } = getToken();
  if (Token) {
    if (!options.headers) options.headers = {};
    options.headers.Authorization = Token;
  }
  // 中断请求
  options.cancelToken = new axios.CancelToken(c => {
    global.cancelRequest = c;
  });
  return options;
};

const errorFetch = res => {
  if (axios.isCancel(res)) return {};
  const { status, data } = res.response || {};
  if (status === 200) {
    return data;
  } else if (status === 401 || status === 404) {
    clearToken();
  }
  const { message: msg } = data || {};
  message.error(msg || '请求数据错误');
  return Promise.reject(data);
};

const afterFetch = res => {
  return res.data;
};

const instance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 60 * 5,
});

// 添加请求拦截器
instance.interceptors.request.use(beforeFetch, errorFetch);
instance.interceptors.response.use(afterFetch, errorFetch);

const methods = ['get', 'post', 'put', 'patch', 'delete'];
const request = {};

methods.forEach(method => {
  request[method] = (path, { params, body, query } = {}, config = {}) => {
    const mergeUrlParams = pathToRegexp.compile(path);
    const urlMergedParams = mergeUrlParams(params);
    return instance({ method, url: urlMergedParams, data: body, params: query, ...config });
  };
});

export default (method, url, config) => parmas => request[method](url, parmas, config);
