import React, { useState, Fragment } from 'react'
import { Row, Col, Radio, Select } from 'antd';
import { useUpdateEffect } from '@/hooks';

const { Option } = Select

function SearchItem({ initValue, listItem, changeQuery }) {
  const [param, setParam] = useState({})
	
	useUpdateEffect(() => {
		setParam(initValue)
	}, [initValue])

  useUpdateEffect(() => {
    if (changeQuery) {
      changeQuery(param)
    }
  }, [param])

  const assignments = (field, value) => {
    setParam(his => {
      const newHis = Object.assign({}, his)
      newHis[field] = value
      return newHis
    })
  }

  const renderDom = (data = {}) => {
    const { id, type, selects, disabled, render } = data
    switch (type) {
      case 'radio':
        return (
          <Radio.Group
            value={param[id]}
            buttonStyle="solid"
            onChange={(e) => assignments(id, e.target.value)}
          >
            {Object.keys(selects).map(i => {
              const dis = Array.isArray(disabled) && disabled.includes(i)
              return (
                <Radio.Button
                  key={i}
                  value={i}
                  disabled={dis}
                >
                  {selects[i]}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        );
      case 'select':
        return (
          <Select
            value={param[id]}
            style={{ width: 140 }}
            placeholder="请选择"
            onChange={(e) => assignments(id, e)}
          >
            {Object.keys(selects).map(i => {
              const dis = Array.isArray(disabled) && disabled.includes(i)
              return (
                <Option
                  key={i}
                  value={i}
                  disabled={dis}
                >
                  {selects[i]}
                </Option>
              )
            })}
          </Select>
        )
      default:
        return render || ''
    }
  }
  const renderLeft = () => {
    const result = listItem.filter(i => (!i.align || i.align === 'left'))
    return result.map(i => (
      <Fragment key={i.id}>
        {renderDom(i)}
      </Fragment>
    ))
  }

  const renderRight = () => {
    const result = listItem.filter(i => i.align === 'right')
    return result.map(i => (
      <Fragment key={i.id}>
        {renderDom(i)}
      </Fragment>
    ))
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ paddingTop: '12px' }}
    >
      <Col style={{ display: 'flex' }}>{renderLeft()}</Col>
      <Col style={{ display: 'flex' }}>{renderRight()}</Col>
    </Row>
  )
}

export default SearchItem;
