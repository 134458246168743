import { Input, message } from 'antd';
import React from 'react';
import { FormModal } from '@/components';
import { updateSystem } from '@/api';
import { parseJSON } from '@/utils/utils';

function EditionEdit({ visible, data, onChange }) {
  const initVal = parseJSON(data.val) || {};
  const formData = [
    {
      name: 'AndroidCurrentVersion',
      label: 'Android当前版本号',
      rules: [{ required: true, message: '请输入Android当前版本号' }],
      render: <Input placeholder="请输入Android当前版本号" />,
    },
    {
      name: 'AndroidMinVersion',
      label: 'Android最小版本号',
      rules: [{ required: true, message: '请输入Android最小版本号' }],
      render: <Input placeholder="请输入Android最小版本号" />,
    },
    {
      name: 'AndroidDes',
      label: 'Android版本描述',
      render: <Input placeholder="请输入Android版本描述" />,
    },
    {
      name: 'IosCurrentVersion',
      label: 'Ios当前版本号',
      rules: [{ required: true, message: '请输入Ios当前版本号' }],
      render: <Input placeholder="请输入Ios当前版本号" />,
    },
    {
      name: 'IosMinVersion',
      label: 'Ios最小版本号',
      rules: [{ required: true, message: '请输入Ios最小版本号' }],
      render: <Input placeholder="请输入Ios最小版本号" />,
    },
    {
      name: 'IosDes',
      label: 'Ios版本描述',
      rules: [{ required: true, message: '请输入Ios版本描述' }],
      render: <Input placeholder="请输入Ios版本描述" />,
    },
    {
      name: 'DownloadPage',
      label: '下载页地址',
      rules: [{ required: true, message: '请输入下载页地址' }],
      render: <Input placeholder="请输入下载页地址" />,
    },
    {
      name: 'AndroidDownloadURL',
      label: 'APK下载地址',
      rules: [{ required: true, message: '请输入APK下载地址' }],
      render: <Input placeholder="请输入APK下载地址" />,
    },
  ];

  const handleSubmit = param => {
    if (!param) return onChange();
    updateSystem({
      body: {
        name: data.name,
        val: JSON.stringify(param),
      },
    }).then(() => {
      message.success('修改成功');
      onChange(true);
    });
  };

  return (
    <FormModal
      coverTitle="版本编辑"
      visible={visible}
      formData={formData}
      formCol={{ label: 7 }}
      initialValues={initVal}
      onChange={handleSubmit}
    />
  );
}

export default EditionEdit;
