import React from 'react';
import { Layout, Modal, Button } from 'antd';
import { delToken, getToken } from '@/utils/storage';
import { IconFont } from '@/components';
import headerLogo from '@/assets/header_logo.png';
import headerLogoText from '@/assets/header_logo_text.png';

import styles from './style.module.scss';

const { Header } = Layout;

function HeaderView({ onChange }) {
  const { username, roleName } = getToken();

  const handleLoginOut = flag => {
    if (!flag) onChange(true);
    delToken();
    const { origin } = window.location;
    window.location.replace(`${origin}/login`);
  };

  const handleLogout = () => {
    Modal.confirm({
      title: '退出操作',
      content: '退出需重新登录，确认退出吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        setTimeout(() => handleLoginOut(), 30);
      },
    });
  };

  return (
    <Header className={styles.header}>
      <div className={styles.logoBox}>
        <img className={styles.img1} src={headerLogo} />
        <img className={styles.img2} src={headerLogoText} />
      </div>
      <div className={styles.dropdowna}>
        <IconFont type="user" />
        <div className={styles.name}>{username || '---'}</div>
        {roleName && <div className={styles.name}>【{roleName}】</div>}
        <Button type="link" danger onClick={handleLogout}>
          退出
        </Button>
      </div>
    </Header>
  );
}

export default HeaderView;
