import React from 'react'
import { Spin } from 'antd'

function Buffer() {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '120px 0'
      }}
    >
      <Spin />
    </div>
  )
}

export default Buffer;
