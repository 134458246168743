import React, { useEffect, useState } from 'react';
import { isLogin, skipUrl, provWhiteList, clearToken } from '@/utils/utils';
import { getSystemConfig, getAllRoleList, getUploadToken, getRegionsData } from '@/utils/helper';
import zhCN from 'antd/es/locale-provider/zh_CN';
import { ConfigProvider, message } from 'antd';
import { useCancelRequest } from '@/hooks';
import { withRouter } from 'react-router';
import { getToken } from '@/utils/storage';
import { Buffer } from '@/components';
import Layouts from '@/layouts/Layouts';
import PageRoute from '@/router';
import 'moment/locale/zh-cn';

message.config({ maxCount: 1 });

function App() {
  const [type, setType] = useState('LOADING');

  const handleGetUserInfo = async () => {
    const { roles = [] } = getToken();
    if (!roles.length) {
      return clearToken();
    }
    await getUploadToken();
    await getRegionsData();
    await getAllRoleList();
    await getSystemConfig();
    setType('LAYOUT');
  };

  useCancelRequest(); // 阻断非当前页的数据请求

  useEffect(() => {
    if (isLogin()) {
      if (provWhiteList()) {
        skipUrl('/home');
      } else {
        handleGetUserInfo();
      }
    } else if (!isLogin() && !provWhiteList()) {
      skipUrl('/login');
    } else {
      setType('WHITE');
    }
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        {type === 'LOADING' && <Buffer />}
        {type === 'LAYOUT' && <Layouts />}
        {type === 'WHITE' && <PageRoute />}
      </div>
    </ConfigProvider>
  );
}

export default withRouter(App);
