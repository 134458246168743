import { ExportData, FormList, HeaderTitle, Operation, OverItem, Thumbnails } from '@/components';
import { getUserList, getUserOverview, postUserUpdate } from '@/api';
import { calcPrice, isSupertube, toDateTime } from '@/utils/utils';
import React, { useState, useEffect } from 'react';
import { st_user } from '@/utils/statetype';
import { message, Table } from 'antd';
import { useTable } from '@/hooks';
import UserEdit from './components/UserEdit';

const { levelObj } = st_user;
const statusObj = {
  1: '正常',
  2: '已冻结',
  3: '已注销',
};
function UserList({ history }) {
  const [datas, setDatas] = useState({});
  const [query, setQuery] = useState({});
  const [count, setCount] = useState([]);

  const handleUpdate = body => {
    postUserUpdate({
      body,
    }).then(() => {
      message.success('操作成功');
      flagFn();
    });
  };

  const columns = {
    id: '用户编号',
    nickname: '用户昵称',
    avatarUrl: {
      title: '用户头像',
      render: e => <Thumbnails images={[e]} center />,
    },
    phone: '手机号码',
    level: {
      title: '用户身份',
      render: e => levelObj[e],
    },
    recommendUserId: '推荐人编号',
    uniqueCode: '邀请码',
    balance: {
      title: '用户余额',
      render: e => calcPrice(e),
    },
    frozenAmount: {
      title: '冻结数额',
      render: e => calcPrice(e),
    },
    oneSubscriptionAmount: {
      title: '个品认购额',
      render: e => calcPrice(e),
    },
    productSubscriptionAmount: {
      title: '标品认购额',
      render: e => calcPrice(e),
    },
    accumulatedConsumptionSum: {
      title: '累计消费金额',
      render: e => calcPrice(e),
    },
    participateOfferCount: '参拍次数',
    obtainOfferCount: '获拍次数',
    subscriptionCategoryNameStr: '认购拍品品类',
    auctionCategoryNameStr: '获拍拍品品类',
    status: {
      title: '用户状态',
      render: e => statusObj[e],
    },
    createdAt: {
      title: '注册时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            upgrade: e.level === 21 || e.status !== 1,
            update: e.status !== 1,
          }}
          hideMenu={{
            frozen: e.status !== 1,
            thaw: e.status !== 2,
            upgrade: e.status === 3 || !isSupertube(),
            update: e.status === 3,
          }}
          listData={{
            select: () => history.push(`/user/list/detail?i=${e.id}`),
            update: () => setDatas(e),
            upgrade: () => {
              handleUpdate({
                id: e.id,
                level: 21,
              });
            },
            frozen: () => {
              handleUpdate({
                id: e.id,
                status: 2,
              });
            },
            thaw: () => {
              handleUpdate({
                id: e.id,
                status: 1,
              });
            },
          }}
        />
      ),
    },
  };

  useEffect(() => {
    getUserOverview({}).then(res => {
      const countData = [
        ['今日注册量', res.todayUser],
        ['本月注册量', res.tomonthUser],
        ['总注册量', res.allUser],
      ];
      setCount(countData);
    });
  }, []);

  const { tableProps, flagFn } = useTable({
    columns,
    API: getUserList,
    isInit: false,
    params: {
      query: {
        ...query,
        isRobot: 0,
      },
    },
  });

  const formData = [
    {
      id: 'id',
      label: '用户编号',
    },
    {
      id: 'nickname',
      label: '用户昵称',
    },
    {
      id: 'phone',
      label: '手机号码',
    },
    {
      id: 'recommendUserId',
      label: '推荐人编号',
    },
    {
      id: 'level',
      label: '用户身份',
      type: 'select',
      selects: levelObj,
    },
    {
      id: 'createdAt',
      label: '注册时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}) => {
    const { createdAt, ...props } = value;
    const [start, end] = createdAt || [];
    const q = {
      ...props,
      ...(createdAt && {
        createStartTime: toDateTime(start),
        createEndTime: toDateTime(end),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <OverItem datas={count} />
      <FormList
        formData={formData}
        changeQuery={changeQuery}
        extraRender={
          <ExportData
            title="用户列表"
            API={getUserList}
            query={query}
            extend={{
              status: statusObj,
              level: levelObj,
              balance: 'amount',
              frozenAmount: 'amount',
              accumulatedConsumptionSum: 'amount',
              oneSubscriptionAmount: 'amount',
              productSubscriptionAmount: 'amount',
              createdAt: 'time',
            }}
            columns={{
              id: '用户编号',
              nickname: '用户昵称',
              avatarUrl: '用户头像',
              phone: '手机号码',
              level: '用户身份',
              recommendUserId: '推荐人编号',
              uniqueCode: '邀请码',
              balance: '用户余额',
              frozenAmount: '冻结数额',
              accumulatedConsumptionSum: '累计消费金额',
              oneSubscriptionAmount: '个品认购额',
              productSubscriptionAmount: '标品认购额',
              participateOfferCount: '参拍次数',
              obtainOfferCount: '获拍次数',
              subscriptionCategoryNameStr: '认购拍品品类',
              auctionCategoryNameStr: '获拍拍品品类',
              status: '用户状态',
              createdAt: '注册时间',
            }}
          />
        }
      />
      <Table {...tableProps} />
      <UserEdit
        datas={datas}
        onChange={res => {
          setDatas({});
          if (res) flagFn();
        }}
      />
    </>
  );
}

export default UserList;
