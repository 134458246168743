import React, { useEffect, useState, useRef } from 'react';
import { Modal, Avatar, Carousel } from 'antd';
import { useUpdateEffect } from '@/hooks';
import IconFont from '../IconFont';
import styles from './index.module.scss';

function Previews({ field, current, fileList, onChange }) {
  const tabKeyUp = useRef();
  const carouselRef = useRef();
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState('');
  const [preview, setPreview] = useState('');
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    if (current || typeof current === 'number') {
      const list = fileList.map(i => (field ? i[field] : i));
      const url = typeof current === 'number' ? list[current] : current;
      const index = typeof current === 'number' ? current : list.findIndex(i => i === current);
      setImageList(list);
      setPreview(url);
      setChecked(index);
    }
  }, [current]);

  useUpdateEffect(() => {
    if (preview && !load) {
      setLoad(true);
      setError(false);
    }
  }, [preview]);

  useUpdateEffect(() => {
    if (imageList.length > 1 && tabKeyUp.current) {
      tabKeyUp.current.focus();
    }
  }, [tabKeyUp.current]);

  const handleKeyUp = event => {
    if (checked > -1) {
      switch (event.keyCode) {
        case 37:
        case 38: {
          const result = (checked || imageList.length) - 1;
          setPreview(imageList[result]);
          setChecked(result);
          carouselRef.current && carouselRef.current.goTo(result);
          break;
        }
        case 39:
        case 40: {
          const result = checked < imageList.length - 1 ? checked + 1 : 0;
          setPreview(imageList[result]);
          setChecked(result);
          carouselRef.current && carouselRef.current.goTo(result);
          break;
        }
        default:
          break;
      }
    }
  };

  const renderImage = () => {
    return (
      <>
        {(load || error) && (
          <div className={styles.tipBox}>
            <IconFont
              type={load ? 'loading' : 'area-chart'}
              style={{
                fontSize: '40px',
                color: '#eee',
              }}
            />
            <p className={styles.tipText}>{load ? '加载中···' : '加载失败'}</p>
          </div>
        )}
        <img
          alt=""
          src={preview}
          onLoad={() => setLoad(false)}
          style={{ display: load || error ? 'none' : '' }}
          onError={() => {
            setError(true);
            setLoad(false);
          }}
        />
      </>
    );
  };

  return (
    <Modal
      width={0}
      footer={null}
      destroyOnClose
      closable={false}
      visible={!!preview}
      bodyStyle={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
      }}
    >
      <div
        className={styles.previews}
        onClick={e => {
          e.stopPropagation();
          setPreview('');
          setTimeout(() => onChange(), 300);
        }}
      >
        {/^(.+\.)(mp4|3gp|m3u8)$/i.test(preview) ? (
          <video controls src={preview} style={{ maxWidth: '100%', maxHeight: '100%' }} />
        ) : (
          renderImage()
        )}
      </div>
      {imageList.length > 1 && (
        <div tabIndex={0} ref={tabKeyUp} className={styles.thumbnail} onKeyUp={handleKeyUp}>
          <div
            className={styles.icon_box}
            onClick={() => carouselRef.current && carouselRef.current.prev()}
          >
            <IconFont type="left" className={styles.icon_text} />
          </div>
          <Carousel
            dots={false}
            infinite={false}
            ref={carouselRef}
            slidesToShow={8}
            slidesToScroll={6}
            className={styles.carousel_body}
            afterChange={e => {
              if (checked < e) {
                setChecked(e);
                setPreview(imageList[e]);
              } else if (checked > e + 7) {
                setChecked(e + 7);
                setPreview(imageList[e + 7]);
              }
            }}
          >
            {imageList.map((i, k) => (
              <Avatar
                key={k}
                src={i}
                size={50}
                shape="square"
                icon={<IconFont type="picture" />}
                className={k === checked ? styles.checked : ''}
                onClick={() => {
                  setChecked(k);
                  setPreview(i);
                }}
              />
            ))}
          </Carousel>
          <div
            className={styles.icon_box}
            onClick={() => carouselRef.current && carouselRef.current.next()}
          >
            <IconFont type="right" className={styles.icon_text} />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default Previews;
