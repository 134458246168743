import { get } from 'lodash';
import { getItems, setItems } from '@/utils/storage';

const SEARCH = 'form-search';

const defaultKeys = {
  current: 'pageNo',
  pageSize: 'pageSize',
  data: 'data',
  total: 'count',
};

export const defaultFooter = {
  onShelf: {
    title: '上架',
    modal: true,
    rely: true,
  },
  offShelf: {
    title: '下架',
    modal: true,
    rely: true,
  },
  delete: {
    title: '删除',
    modal: true,
    rely: true,
  },
  export: {
    title: '导出',
  },
  import: {
    title: '导入',
  },
};

export const defaultTable = {
  current: 1, // 当前页码
  pageSize: 10, // 分页大小
  total: 0, // 总页数
  count: 0, // 计数器
  data: [], // 列表数据
  allData: null, // 回调数据
};

export const formatData = (datas, keys = {}) => {
  const { data, total } = Object.assign(JSON.parse(JSON.stringify(defaultKeys)), keys);
  if (Array.isArray(datas)) {
    return {
      total: datas.length,
      data: datas,
    };
  }
  return {
    total: get(datas, total, 0),
    data: get(datas, data, []).map((i, k) => ({ id: `${Date.now()}${k}`, ...i })),
  };
};

export const formatPage = (keys = {}, param = defaultTable) => {
  const { current, pageSize } = defaultKeys;
  const key = keys.body ? 'body' : 'query';
  const resKey = {
    current,
    pageSize,
    ...(keys[key] || {}),
  };
  return {
    [key]: {
      [resKey.current]: param.current,
      [resKey.pageSize]: param.pageSize,
    },
  };
};

export const mergeData = (data, keys, param) => {
  let result = {};
  const page = formatPage(keys, param);
  const { query: pQuery, body: pBody } = page;
  const { query: dQuery, body: dBody } = data;
  const query = { ...pQuery, ...dQuery };
  const body = { ...pBody, ...dBody };
  if (Object.keys(query).length) result.query = query;
  if (Object.keys(body).length) result.body = body;
  return result;
};

export const matchKeep = isKeep => {
  const { pathname = '' } = window.location;
  const { uri, page } = getItems(SEARCH);
  const pageFlag = page && isKeep;
  const pathFlag = pathname.toLocaleLowerCase() === uri;
  return pageFlag && pathFlag;
};

export const saveKeep = (total, state) => {
  const { pathname } = window.location;
  const local = getItems(SEARCH);
  const page = {
    current: state.current,
    pageSize: state.pageSize,
    total: total || state.total,
  };
  const uriCase = pathname.toLocaleLowerCase();
  let datas = {
    uri: uriCase,
    page,
  };
  if (uriCase === local.uri) {
    datas = { ...local, ...datas };
  }
  setItems(SEARCH, datas);
};
