import { ExportData, FormList, HeaderTitle, Operation, OverItem, Thumbnails } from '@/components';
import { getGoodsList, getGoodsOver, postGoodsUpdate } from '@/api';
import { calcPrice, parseJSON, toDateTime } from '@/utils/utils';
import React, { useState, useEffect } from 'react';
import { st_goods } from '@/utils/statetype';
import { message, Table } from 'antd';
import { useTable } from '@/hooks';

const { statusObj, typeObj, kindObj } = st_goods;
function GoodsList({ history }) {
  const [query, setQuery] = useState({});
  const [count, setCount] = useState([]);

  const handleUpdate = (id, status) => {
    postGoodsUpdate({
      body: {
        id,
        status,
      },
    }).then(() => {
      message.success('操作成功');
      flagFn();
    });
  };

  const columns = {
    id: '拍品编号',
    name: '拍品名称',
    thumb: {
      title: '封面图',
      render: e => <Thumbnails images={[e]} center />,
    },
    NOINDEX_status: {
      title: '拍品状态',
      render: e => {
        const { status, type } = e || {};
        if (status === 100) {
          return type === 20 ? '认购中' : type === 30 ? '转拍中' : '拍卖中';
        } else if (status === 110) {
          return type === 20 ? '认购成功' : type === 30 ? '转拍成功' : '已拍得';
        }
        return statusObj[status] || '-';
      },
    },
    type: {
      title: '拍品类型',
      render: e => typeObj[e] || '-',
    },
    kind: {
      title: '拍品类别',
      render: e => kindObj[e] || '-',
    },
    Merchant: {
      title: '所属店铺',
      render: e => e.title || '-',
    },
    property: {
      title: '所属分类',
      render: e => {
        let result = parseJSON(e) || {};
        result = Object.entries(result)
          .map(i => i.join(':'))
          .join(';');
        return result;
      },
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            offShelf: e.status !== 20,
          }}
          listData={{
            select: () => history.push(`/goods/list/detail?i=${e.id}`),
            offShelf: () => handleUpdate(e.id, -20),
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getGoodsList,
    isInit: false,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'id',
      label: '拍品编号',
    },
    {
      id: 'name',
      label: '拍品名称',
    },
    {
      id: 'merchantId',
      label: '店铺编号',
    },
    {
      id: 'title',
      label: '店铺名称',
    },
    {
      id: 'status',
      label: '拍品状态',
      type: 'select',
      selects: statusObj,
    },
    {
      id: 'type',
      label: '拍品类型',
      type: 'select',
      selects: typeObj,
    },
    {
      id: 'kind',
      label: '拍品类别',
      type: 'select',
      selects: kindObj,
    },
    {
      id: 'times',
      label: '拍卖时间',
      type: 'rangeTime',
    },
    {
      id: 'createTime',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  useEffect(() => {
    getGoodsOver().then(res => {
      const countData = [
        ['认购数量', res.subscriptionCount],
        ['正在拍卖量', res.auctionCount],
        ['已流拍量', res.auctionTimeoutCount],
        ['待审核量', res.auditCount],
      ];
      setCount(countData);
    });
  }, []);

  const changeQuery = (value = {}) => {
    const { times, createTime, ...props } = value;
    const [start, end] = times || [];
    const [starts, ends] = createTime || [];
    const q = {
      ...props,
      ...(times && {
        startTime: toDateTime(start),
        endTime: toDateTime(end),
      }),
      ...(createTime && {
        createdStartTime: toDateTime(starts),
        createdEndTime: toDateTime(ends),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <OverItem datas={count} width={'25%'} />
      <FormList
        formData={formData}
        changeQuery={changeQuery}
        extraRender={
          <ExportData
            title="拍品列表"
            API={getGoodsList}
            query={query}
            extend={{
              status: statusObj,
              type: typeObj,
              kind: kindObj,
              GoodsSchedules: info => {
                const arr = info.map(
                  i =>
                    `[起始价:${calcPrice(i.startPrice)},最终价:${calcPrice(
                      i.finalPrice
                    )},认购价:${calcPrice(i.subscriptionPrice)},结束时间:${toDateTime(
                      i.endTime
                    )},竞拍次数:${i.offerNums},竞拍人数:${i.userOfferNums}]`
                );
                return arr.join('');
              },
            }}
            columns={{
              id: '拍品编号',
              name: '拍品名称',
              thumb: '封面图',
              status: '拍品状态',
              type: '拍品类型',
              kind: '拍品类别',
              GoodsSchedules: '排期信息',
              'Merchant.id': '店铺编号',
              'Merchant.title': '所属店铺',
              property: '所属分类',
              'Goods.resellCount': '转拍次数',
              createdAt: '创建时间',
            }}
          />
        }
      />
      <Table {...tableProps} />
    </>
  );
}

export default GoodsList;
