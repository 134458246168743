import { calcPrice, toDateTime } from '@/utils/utils';
import { Button, message } from 'antd';
import React, { useState } from 'react';
import { write } from 'xlsx';

function ExportData({
  API,
  title = '导出数据',
  columns = {},
  extend = {},
  type = 'xlsx',
  query = {},
  btnProp = {},
}) {
  const [loading, setLoading] = useState(false);

  function getCharCol(n) {
    let s = '';
    let m = 0;
    while (n > 0) {
      m = (n % 26) + 1;
      s = String.fromCharCode(m + 64) + s;
      n = (n - m) / 26;
    }
    return s;
  }

  function formatToBuffer(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  async function formatToExcel(json, downName, type, cols) {
    const keyMap = Object.keys(json[0]).map(value => value);
    const tmpdata = [];
    json
      .map((v, i) =>
        keyMap.map((k, j) => ({
          v: v[k],
          position: (j > 25 ? getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1),
        }))
      )
      .reduce((prev, next) => prev.concat(next))
      .forEach(v => {
        tmpdata[v.position] = {
          v: v.v,
        };
      });

    const outputPos = Object.keys(tmpdata);
    const tmpWB = {
      SheetNames: ['mySheet'],
      Sheets: {
        mySheet: Object.assign({}, tmpdata, {
          '!ref': `${outputPos[0]}:${outputPos[outputPos.length - 1]}`,
          '!cols': cols || Object.keys(json[0]).map(() => ({ wpx: 120 })),
        }),
      },
    };
    const tmpDown = new Blob(
      [
        formatToBuffer(
          write(tmpWB, {
            bookType: type === undefined ? 'xlsx' : type,
            bookSST: false,
            type: 'binary',
          })
        ),
      ],
      {
        type: '',
      }
    );
    const outFile = document.createElement('a');
    document.body.insertBefore(outFile, document.body.lastChild);
    const href = URL.createObjectURL(tmpDown);
    outFile.download = `${downName}.${type}`;
    outFile.href = href;
    outFile.click();
    message.success('Excel导出成功', 1).then(() => {
      URL.revokeObjectURL(tmpDown);
      return Promise.resolve();
    });
  }

  const handleGetData = async () => {
    if (!API) return message.error('数据获取失败！');
    try {
      setLoading(true);
      const res = await API({
        query: {
          ...query,
          command: 'excel',
        },
      });
      const colArr = Object.entries(columns);
      const result = (res.data || []).map(item => {
        let vals = {};
        colArr.forEach(([key]) => {
          const keyArr = [item].concat(key.split('.'));
          const value = keyArr.reduce((r, c) => (r && typeof r === 'object' ? r[c] : r));
          if (extend[key] && typeof extend[key] === 'function') {
            vals[key] = extend[key](value);
          } else if (extend[key] && typeof extend[key] === 'object') {
            vals[key] = extend[key][value] || value;
          } else if (extend[key] === 'time') {
            vals[key] = toDateTime(value);
          } else if (extend[key] === 'amount') {
            vals[key] = calcPrice(value);
          } else {
            vals[key] = value ?? '';
          }
        });
        return vals;
      });
      const jsonData = [columns, ...result];
      await formatToExcel(jsonData, title, type);
      setLoading(false);
    } catch (error) {
      message.error('导出失败！');
      setLoading(false);
    }
  };

  return (
    <Button type="primary" {...btnProp} loading={loading} onClick={() => handleGetData()}>
      {loading ? '导出中···' : '导出Excel'}
    </Button>
  );
}

export default ExportData;
