import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Layout, Spin } from 'antd';
import HeaderView from './HeaderView';
import ContentView from './ContentView';

function Layouts() {
  const [load, setLoad] = useState(false);
  const [coll, setColl] = useState(false);

  return (
    <Spin spinning={load}>
      <Layout style={{ height: '100vh' }}>
        <HeaderView coll={coll} onColl={setColl} onChange={val => setLoad(!!val)} />
        <ContentView coll={coll} />
      </Layout>
    </Spin>
  );
}

export default withRouter(Layouts);
