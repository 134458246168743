import { goodsScheduleSettleDetail } from '@/api';
import { CardItem, HeaderTitle, Thumbnails } from '@/components';
import { calcPrice, parseJSON, toDateTime, toSpliceStr } from '@/utils/utils';
import { st_auction, st_bill, st_goods, st_order } from '@/utils/statetype';
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';

const { payType } = st_order;
const { typeObj } = st_goods;
const { typeObj: billTypeObj, kindObj } = st_bill;
const { statusObj: aucStatusObj } = st_auction;
function SettlementDetail({ querys }) {
  const { i: ID } = querys;
  const [datas, setDatas] = useState({});
  const { Order = {}, Bills = [], Goods = {} } = datas;
  const property = parseJSON(Goods.property);
  const { Merchant = {} } = Order;
  const ResellUser = Order.ResellUser || {};
  const User = Order.User || {};

  const handleGetData = () => {
    goodsScheduleSettleDetail({
      query: {
        goodsScheduleId: Number(ID),
      },
    }).then(res => {
      setDatas(res);
    });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const renderInfo = [
    {
      label: '排期编号',
      render: datas.id,
    },
    {
      label: '排期状态',
      render: aucStatusObj[datas.status],
    },
    {
      label: '排期类型',
      render: <span style={{ color: '#f11c1c' }}>{typeObj[datas.type]}</span>,
    },
    {
      label: '出价次数',
      render: datas.offerNums,
    },
    {
      label: '起拍价',
      render: calcPrice(datas.startPrice),
    },
    ...(datas.type === 2
      ? [
          {
            label: '认购价',
            render: calcPrice(datas.subscriptionPrice),
          },
        ]
      : [
          {
            label: '最终价',
            render: calcPrice(datas.finalPrice),
          },
          {
            label: '加价幅度',
            render: calcPrice(datas.addPriceRange),
          },
          {
            label: '保证金比例',
            render: datas.bondPro,
          },
        ]),
    {
      label: '拍品数量',
      render: datas.num,
    },
    {
      label: '开拍时间',
      render: toDateTime(datas.startTime),
    },
    {
      label: '结束时间',
      render: toDateTime(datas.endTime),
    },
    {
      label: '创建时间',
      render: toDateTime(datas.createdAt),
    },
  ];

  const renderOrder = [
    {
      label: '订单编号',
      render: Order.id,
    },
    {
      label: '订单单号',
      render: Order.number,
    },
    {
      label: '应付金额',
      render: calcPrice(Order.shouldPayPrice),
    },
    {
      label: '实付金额',
      render: calcPrice(Order.payPrice),
    },
    {
      label: '支付方式',
      render: payType[Order.payType],
    },
    {
      label: '支付时间',
      render: toDateTime(Order.payTime),
    },
  ];

  const renderGoods = [
    {
      label: '拍品编号',
      render: Goods.id,
    },
    {
      label: '拍品名称',
      render: Goods.name,
    },
    {
      label: '拍品类型',
      render: typeObj[Goods.type],
    },
    {
      label: '封面图',
      render: <Thumbnails images={[Goods.thumb]} />,
    },
    {
      label: '拍品分类',
      render: toSpliceStr(property, '>'),
    },
  ];

  const renderShop = [
    {
      label: '店铺编号',
      render: Merchant.id,
    },
    {
      label: '店铺名称',
      render: Merchant.title,
    },
    {
      label: '店铺LOGO',
      render: <Thumbnails images={[Merchant.shopIcon]} />,
    },
    {
      label: '联系方式',
      render: Merchant.contact,
    },
    {
      label: '店铺地址',
      render: Merchant.address,
    },
  ];

  const renderBill = {
    dataSource: Bills,
    columns: [
      {
        title: '明细编号',
        dataIndex: 'id',
      },
      {
        title: '收益单号',
        dataIndex: 'number',
      },
      {
        title: '收益类型',
        dataIndex: 'type',
        render: e => billTypeObj[e],
      },
      {
        title: '总收益',
        dataIndex: 'amount',
        render: e => calcPrice(e),
      },
      {
        title: '收益分类',
        dataIndex: 'kind',
        render: e => kindObj[e],
      },
      {
        title: '用户编号',
        dataIndex: 'User',
        render: e => {
          const { id } = e || {};
          return id || '-';
        },
      },
      {
        title: '用户名称',
        dataIndex: 'User',
        render: e => {
          const { nickname } = e || {};
          return nickname || '-';
        },
      },
      {
        title: '商家编号',
        dataIndex: 'Merchant',
        render: e => {
          const { id } = e || {};
          return id || '-';
        },
      },
      {
        title: '商家名称',
        dataIndex: 'Merchant',
        render: e => {
          const { title } = e || {};
          return title || '-';
        },
      },
      {
        title: '来源编号',
        dataIndex: 'sourceId',
      },
      {
        title: '收益来源',
        dataIndex: 'source',
      },
      {
        title: '备注信息',
        dataIndex: 'describe',
      },
      {
        title: '创建时间',
        dataIndex: 'createdAt',
        render: e => toDateTime(e),
      },
    ],
  };

  const renderUser = [
    {
      label: '用户编号',
      render: User?.id,
    },
    {
      label: '用户名称',
      render: User?.nickname,
    },
    {
      label: '用户头像',
      render: <Thumbnails images={[User?.avatarUrl]} />,
    },
    {
      label: '联系方式',
      render: User?.phone,
    },
  ];

  const renderResellUser = [
    {
      label: '用户编号',
      render: ResellUser?.id,
    },
    {
      label: '用户名称',
      render: ResellUser?.nickname,
    },
    {
      label: '用户头像',
      render: <Thumbnails images={[ResellUser?.avatarUrl]} />,
    },
    {
      label: '联系方式',
      render: ResellUser?.phone,
    },
  ];

  return (
    <>
      <HeaderTitle />
      <Card>
        <CardItem title={'拍品排期'} datas={renderInfo} />
        <CardItem title={'拍品信息'} datas={renderGoods} />
        <CardItem title={'所属店铺'} datas={renderShop} />
        <CardItem title={'订单信息'} datas={renderOrder} />
        {Order.ResellUser && <CardItem title={'转拍用户'} datas={renderResellUser} />}
        {Order.User && <CardItem title={'买家信息'} datas={renderUser} />}
        <CardItem title={'结算收益'} datas={renderBill} />
      </Card>
    </>
  );
}

export default SettlementDetail;
