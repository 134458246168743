import {
  ExamineModal,
  ExportData,
  FormList,
  HeaderTitle,
  Operation,
  Thumbnails,
} from '@/components';
import { calcPrice, toDateTime } from '@/utils/utils';
import { getCashList, postCashUpdate } from '@/api';
import { Button, message, Table } from 'antd';
import { st_cash } from '@/utils/statetype';
import React, { useState } from 'react';
import { useTable } from '@/hooks';

const { statusObj, typeObj, wayObj } = st_cash;
function FinanceWithdraw() {
  const [id, setId] = useState(null);
  const [query, setQuery] = useState({});
  const [visible, setVisible] = useState(false);

  const columns = {
    id: '提现编号',
    NOINDEX_id: {
      title: '用户编号',
      render: e => {
        const { User = {} } = e;
        return User?.id || '-';
      },
    },
    NOINDEX_name: {
      title: '用户名称',
      render: e => {
        const { User = {} } = e;
        return User?.nickname || '-';
      },
    },
    User: {
      title: '用户头像',
      render: e => <Thumbnails images={[e?.avatarUrl]} center />,
    },
    number: '提现单号',
    type: {
      title: '提现类型',
      render: e => typeObj[e],
    },
    remitType: {
      title: '提现方式',
      render: e => wayObj[e] || '-',
    },
    NOINDEX_amouut: {
      title: '提现金额',
      render: e => {
        const cash = Number(e.cash) || 0;
        const fee = Number(e.fee) || 0;
        return calcPrice(cash + fee);
      },
    },
    fee: {
      title: '手续费',
      render: e => calcPrice(e),
    },
    cash: {
      title: '到账金额',
      render: e => calcPrice(e),
    },
    certificate: '证件类型',
    certificateCode: '证件号码',
    bankName: '提现银行',
    name: '银行卡账户',
    openBank: '支行名称',
    bankCard: '银行卡号',
    phone: '手机号码',
    status: {
      title: '提现状态',
      render: e => statusObj[e],
    },
    createdAt: {
      title: '创建时间',
      render: e => toDateTime(e),
    },
    NOINDEX_operation: {
      title: '操作',
      fixed: 'right',
      render: e => (
        <Operation
          disabled={{
            examine: e.status !== 0,
            payment: e.status !== 1,
          }}
          listData={{
            examine: () => {
              setId(e.id);
              setVisible(true);
            },
            payment: () => {
              postCashUpdate({
                body: {
                  id: e.id,
                  status: 3,
                },
              }).then(() => {
                message.success('打款成功');
                flagFn();
              });
            },
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn, apiData } = useTable({
    columns,
    API: getCashList,
    isInit: false,
    params: {
      query,
    },
  });
  const { cashAmouut, feeAmouut } = apiData || {};

  const formData = [
    {
      id: 'phone',
      label: '手机号码',
    },
    {
      id: 'userId',
      label: '用户编号',
    },
    {
      id: 'name',
      label: '银行卡账户',
    },
    {
      id: 'status',
      label: '提现状态',
      type: 'select',
      selects: statusObj,
    },
    {
      id: 'createTime',
      label: '创建时间',
      type: 'rangeTime',
    },
  ];

  const changeQuery = (value = {}, param) => {
    const { createTime, ...prop } = value;
    const [starts, ends] = createTime || [];
    const { type = 1 } = param;
    setQuery({
      ...prop,
      ...(createTime && {
        createdStartTime: toDateTime(starts),
        createdEndTime: toDateTime(ends),
      }),
      type: Number(type),
    });
    flagFn(true);
  };

  const listItem = [
    {
      id: 'type',
      type: 'radio',
      align: 'left',
      initValue: '1',
      selects: typeObj,
    },
    {
      id: 'types',
      align: 'right',
      render: (
        <ExportData
          API={getCashList}
          title="提现列表"
          query={query}
          extend={{
            type: typeObj,
            cash: 'amount',
            fee: 'amount',
            status: statusObj,
            createdAt: 'time',
          }}
          columns={{
            id: '提现编号',
            'User.id': '用户编号',
            'User.nickname': '用户名称',
            number: '提现单号',
            type: '提现类型',
            cash: '到账金额',
            fee: '提现手续费',
            certificate: '证件类型',
            certificateCode: '证件号码',
            bankName: '提现银行',
            name: '银行卡账户',
            bankCard: '银行卡号',
            phone: '手机号码',
            status: '提现状态',
            createdAt: '创建时间',
          }}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderTitle />
      <FormList formData={formData} searchList={{ listItem }} changeQuery={changeQuery} />
      <Table
        {...tableProps}
        footer={() => (
          <>
            <Button style={{ border: 'none', boxShadow: 'none', marginTop: '12px' }}>
              提现总金额：{calcPrice(cashAmouut + feeAmouut)}
            </Button>
            <Button style={{ border: 'none', boxShadow: 'none', marginTop: '12px' }}>
              手续费总金额：{calcPrice(feeAmouut)}
            </Button>
            <Button style={{ border: 'none', boxShadow: 'none', marginTop: '12px' }}>
              到账总金额：{calcPrice(cashAmouut)}
            </Button>
          </>
        )}
      />
      <ExamineModal
        visible={visible}
        title="提现审核"
        data={{ id }}
        API={postCashUpdate}
        onChange={res => {
          if (res) flagFn();
          setId(null);
          setVisible(false);
        }}
      />
    </>
  );
}

export default FinanceWithdraw;
