export default { // 加上下划线“_”标识表示此为可操作权限
  // 默认
  created: '新增',
  delete_: {
    label: '删除',
    danger: true,
    modal: true,
  },
  update_: '编辑',
  select: '查看',
  examine_: '审核',
  preview: '预览',
  detailed: '明细',
  setup_: '设置',
  onShelf_: {
    label: '上架',
    modal: true,
  },
  offShelf_: {
    label: '下架',
    danger: true,
    modal: true,
  },
  // 需求扩展
  upgrade_: {
    label: '升级',
    modal: true,
  },
  subset_: '添加子分类',
  subsetKey_: '添加属性名',
  subsetValue_: '添加属性值',
  payment_: {
    label: '打款',
    modal: true,
  },
  auction_: '参拍',
  address_: '地址',
  offer_: '出价',
  identity_: '身份',
  frozen_: {
    label: '冻结',
    danger: true,
    modal: true,
  },
  thaw_: {
    label: '解冻',
    modal: true,
  },
  logout_: '注销',
  auctionInfo: '竞拍排期',
  merchantInfo: '商家信息',
  offerInfo: '出价信息',
  noOffer: '暂未出价',
  power_: '权限',
  delayed_: '延时',
  record: '记录',
  member: '会员',
};
