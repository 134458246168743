import React, { useState } from 'react';
import { setToken } from '@/utils/storage';
import { Inputbox } from '@/components';
import { Button, Form } from 'antd';
import { skipUrl } from '@/utils/utils';
import { postLogin } from '@/api';
import styles from './index.module.scss';
import userIcon from '@/assets/username.png';
import passIcon from '@/assets/password.png';
import LoginLeft from '@/assets/login_left.png';
import loginRight from '@/assets/login_right.png';

function Login() {
  const [load, setLoad] = useState(false);

  const handleLogin = param => {
    setLoad(true);
    postLogin({
      body: param,
    })
      .then(res => {
        const { accessToken, expiresIn, tokenType, username, roles: r = [] } = res || {};
        const [roleName] = Object.keys(r[0] || {}) || [];
        const [resRole = []] = Object.values(r[0] || {}) || [];
        const roles = resRole.map(i => i[2]);
        roles.push('/home');
        setToken({
          Token: `${tokenType} ${accessToken}`,
          ExpiredAt: Date.now() + expiresIn * 1000,
          username,
          roleName,
          roles,
        });
        skipUrl('/home');
      })
      .catch(() => {
        setLoad(false);
      });
  };

  return (
    <div className={styles.loginBox}>
      <div className={styles.loginBack}>
        <div className={styles.loginImage}>
          <img src={LoginLeft} alt="" />
        </div>
        <div className={styles.loginInfo}>
          <img src={loginRight} className={styles.loginIcon} />
          <Form className={styles.formSty} onFinish={handleLogin}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: <span className={styles.tips}>账号未输入</span>,
                },
              ]}
            >
              <Inputbox maxLength={26} icon={userIcon} placeholder="请输入账号" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: <span className={styles.tips}>密码未输入</span>,
                },
              ]}
            >
              <Inputbox maxLength={26} type="password" icon={passIcon} placeholder="请输入密码" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={load} htmlType="submit" className={styles.loginBtn}>
                {!load && '登录'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
