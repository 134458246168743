import { getManageRegions, getOSSToken, getPermissions, getSystemGetall } from '@/api';
import { overOSSToken, recurTurnFlat } from './utils';
import { getToken } from './storage';

/**
 * 获取系统配置
 */
export const getSystemConfig = async () => {
  await getSystemGetall().then(res => {
    global.systemConfig = res;
    const { roleName } = getToken() || {};
    const { val } = res.find(i => i.name === 'pageOperatePrivilege') || {};
    const flag = roleName && (val ?? '')?.split(',').includes(roleName);
    global.isOperable = flag;
  });
};

/**
 * 获取所有权限
 */
export const getAllRoleList = async () => {
  const res = await getPermissions();
  const resObj = {};
  res.forEach(i => {
    resObj[i[2]] = i[3];
  });
  global.allRole = resObj;
  global.allRoleList = res;
};

/**
 *regionsFlat--扁平化数据 regionsRecur--递归化数据
 * @param {*} id
 */
export const getRegionsData = async () => {
  let resArr = global.regionsFlat || [];
  if (!resArr.length) {
    resArr = await getManageRegions();
    global.regionsRecur = resArr;
    global.regionsFlat = recurTurnFlat(resArr, 'Item');
  }
  return resArr;
};

/**
 * oss获取验证信息
 */
export const getUploadToken = async () => {
  if (!overOSSToken()) return;
  await getOSSToken().then(res => {
    global.uploadToken = res;
  });
};
