import { Col, InputNumber, Modal } from 'antd';
import Button from 'antd/es/button';
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import styles from './style.module.scss';

const defCrop = {
	aspect: 1,
	width: 200,
}

function Cropper({ visible, files, fixed, proposal, onChange }) {
	const cropRef = useRef();
	const [WHprop, setWHprop] = useState({});
	const [crop, setCorp] = useState(defCrop);
	const [cropSrc, setCropSrc] = useState('');
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (Array.isArray(fixed) && fixed.length === 2) {
			const [W, H] = fixed || [];
			const aspect = (W / H).toFixed(8) * 1;
			setCorp({ ...defCrop, aspect });
			setWHprop({ W, H })
			setDisabled(true);
		}
	}, [fixed])

  useEffect(() => {
		if (!visible || !files) return;
    const fileReader = new FileReader();
    fileReader.onload = e => {
      const dataURL = e.target.result;
      setCropSrc(dataURL);
		};
    fileReader.readAsDataURL(files.originFileObj);
	}, [visible]);
	
	const getCroppedImg = image => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject();
        }
        resolve(blob);
      }, 'image/png');
    });
	};

	const upload = async () => {
    if (cropRef.current && crop.width && crop.height) {
			const blob = await getCroppedImg(cropRef.current);
      const originFileObj = new File([blob], files.name, {
        type: files.type,
			});
			onChange({ ...files, originFileObj })
    }
  };
	
	return (
		<Modal
			width={800}
			title='图片裁剪'
			visible={visible}
			maskClosable={false}
			style={{ top: '10vh' }}
			onCancel={() => onChange()}
			onOk={upload}
		>
			<div className={styles.cropBox}>
				<ReactCrop
					onImageLoaded={(e) => cropRef.current = e}
					crop={crop}
					src={cropSrc}
					onChange={e => setCorp(e)}
				/>
			</div>
			<div className={styles.cropOper}>
				<Col className={styles.cols}>
					<div>{disabled ? '固定比例：' : '裁剪比例：'}</div>
					<span className={styles.label}>W</span>
					<InputNumber
						min={1}
						max={10000}
						precision={0}
						value={WHprop.W}
						disabled={disabled}
						onChange={(W) => setWHprop(his => ({ ...his, W }))}
					/>
					<span className={styles.label}>H</span>
					<InputNumber
						min={1}
						max={10000}
						precision={0}
						value={WHprop.H}
						disabled={disabled}
						onChange={(H) => setWHprop(his => ({ ...his, H }))}
					/>
					{!disabled && (
						<Button
							type='primary'
							className={styles.btn}
							disabled={!WHprop.W || !WHprop.H}
							onClick={() => {
								setCorp({})
								const { W, H } = WHprop
								const aspect = (W / H).toFixed(8) * 1
								setCorp({ ...defCrop, aspect })
								setWHprop({})
							}}
						>
							设置
						</Button>
					)}
				</Col>
				{proposal && (
					<Col className={styles.cols}>
						<div>建议尺寸：{proposal}</div>
					</Col>
				)}
			</div>
		</Modal>
	)
}

export default Cropper;
