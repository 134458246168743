import { HeaderTitle, Operation } from '@/components';
import { getLabelList, postLabelDelete } from '@/api';
import { st_label } from '@/utils/statetype';
import { message, Table } from 'antd';
import React, { useState } from 'react';
import { useTable } from '@/hooks';
import LabelEdit from './components/LabelEdit';

const { typeObj } = st_label;
function ConfigLabel() {
  const [datas, setDatas] = useState({});
  const [visible, setVisible] = useState(false);

  const columns = {
    id: '标签编号',
    name: '标签名称',
    type: {
      title: '标签类型',
      render: e => typeObj[e] || '-',
    },
    describe: '标签描述',
    NOINDEX_operation: {
      title: '操作',
      render: e => (
        <Operation
          listData={{
            update: () => {
              setDatas(e);
              setVisible(true);
            },
            delete: () => {
              postLabelDelete({
                body: {
                  id: e.id,
                },
              }).then(() => {
                message.success('删除成功');
                flagFn(true);
              });
            },
          }}
        />
      ),
    },
  };

  const { tableProps, flagFn } = useTable({
    API: getLabelList,
    columns,
    coverKey: {
      data: 'data',
    },
    isPage: false,
  });

  return (
    <>
      <HeaderTitle title={'添加'} disabled={!global.isOperable} onClick={() => setVisible(true)} />
      <Table {...tableProps} />
      <LabelEdit
        data={datas}
        visible={visible}
        onChange={res => {
          if (res) flagFn();
          setVisible(false);
          setDatas({});
        }}
      />
    </>
  );
}

export default ConfigLabel;
