import { message, Table } from 'antd';
import { HeaderTitle, FormList } from '@/components';
import React, { useState } from 'react';
import { toDateTime } from '@/utils/utils';
import { getStatData } from '@/api';
import { useTable } from '@/hooks';
import moment from 'moment';

function FinanceStatData() {
  const [query, setQuery] = useState({});

  const columns = {
    day: {
      title: '日期',
      fixed: 'left',
    },
    downloadCount: '下载次数',
    registerCount: '注册人数',
    loginCount: '登录次数',
    loginUserCount: '登录人数',
    offerCount: '参拍次数',
    offerUserCount: '参拍人数',
    shareCount: '分享次数',
    shareUserCount: '分享人数',
    validShareCount: '有效分享次数',
    bindUserCount: '绑定人数',
    offerFansCount: '参拍粉丝数',
    subscriptionCount: '认购次数',
    subscriptionUserCount: '认购人数',
    subscription2UserCount: '认购1次以上的人数',
    subscription10UserCount: '认购9次以上的人数',
    resellCount: '转拍次数',
    resellUserCount: '转拍人数',
    orderSendCount: '提货次数',
    orderSendUserCount: '提货人数',
    validResellCount: '有效转拍次数',
    validResellUserCount: '有效转拍人数',
  };

  const { tableProps, flagFn } = useTable({
    columns,
    API: getStatData,
    isInit: false,
    isPage: false,
    params: {
      query,
    },
  });

  const formData = [
    {
      id: 'times',
      label: '时间统计范围',
      type: 'rangeTime',
      options: {
        showTime: false,
        format: 'YYYY-MM-DD',
        disabledDate: current => {
          return current && current > moment().endOf('day');
        },
      },
    },
  ];

  const changeQuery = (value = {}) => {
    const { times } = value;
    const [starts, ends] = times || [];
    const diff = new Date(ends).getTime() - new Date(starts).getTime();
    if (diff / 1000 / 60 / 60 / 24 > 29) {
      message.warning('仅允许查询三十天跨度的统计数据');
      return;
    }
    const q = {
      ...(times && {
        startTime: toDateTime(starts),
        endTime: toDateTime(ends),
      }),
    };
    setQuery(q);
    flagFn(true);
  };

  return (
    <>
      <HeaderTitle />
      <FormList formData={formData} changeQuery={changeQuery} />
      <Table {...tableProps} scroll={{ x: 1000, y: 500 }} />
    </>
  );
}

export default FinanceStatData;
